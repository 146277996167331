import React, { FC } from "react";
import { Link} from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import blockImage1 from "images/For-Mould-Accessories-1.png";
import blockImage2 from "images/For-Press-Tool-Accessories.jpg";


export interface PressToolProps {
  className?: string;
  price?: number;
  contentClass?: string;
}

const PressTool: FC<PressToolProps> = ({
}) => {
  return (
    <div className="xl:px-24 pt-10 pb-14 lg:pb-24 xl:pt-24 relative space-y-24 lg:space-y-32">
    <div className="container-fluid">
      <div className="grid grid-cols-1 gap-6">
        <div className="text-center">
            <h3 className={`text-base md:text-2xl text-slate-900 font-semibold w-[46%] md:w-full`}>Under Development</h3>
        </div>
      </div>
    </div>


  </div>
  );
};

export default PressTool;
