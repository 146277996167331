import Label from "components/Label/Label";
import * as Yup from "yup";
import { useFormik } from "formik";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import { avatarImgs } from "contains/fakeData";
import React, { FC, Fragment, useEffect, useState, useMemo } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { RootState } from 'store/reducers';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData
} from "store/AppMaster/actions";
import api from "constants/api";

export interface AccountPageProps {
  className?: string;
  authData?: any;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const dispatch = useDispatch();
  const [signUpData, setSignUpData] = useState(false)
  const [response_email, setEmail] = useState("")
  const [response_name, setName] = useState("")
  const [isSent, setIsSent] = useState(false)
  const [isVerify, setIsVerify] = useState(false)
  const [authData, setCustomerData] = useState({
    title: "",
    first_name:"",
    last_name: "",
    department:"",
    designation: "",
    landLine_no: "",
    email:"",
    mobile:"",
    company_name:"",
    company_address:"",
    additional_address: "",
    gstin:"",
    city:"",
    state: "",
    country: "",
    pincode:"",
    billing_company_name: "",
    billing_company_address: "",
    billing_additional_address: "",
    billing_city: "",
    billing_state: "",
    billing_country: "",
    billing_pin_code: "",
    delivery_company_name: "",
    delivery_company_address: "",
    delivery_additional_address: "",
    delivery_city: "",
    delivery_state: "",
    delivery_country: "",
    delivery_pin_code: "",
    billing_same_as: "",
    delivery_same_as: "",
    industry_type: [],
    tool_room_type: [],
  })
  const [customerData, setIsCustomer] = useState(false);
  const params = useParams();

  useEffect(() => {
    getCustomerDetails();
  }, [params]);

  const { data } = useSelector((state: RootState) => state.AppMaster);
  const { loading } = useSelector((state: RootState) => state.AppMaster);

  const getCustomerDetails = () => {
    setIsCustomer(true);
    let filterData = {
      slug: params.slug
    }
    dispatch(onGetData(filterData, '', api.frontCustomerDetails));
  }

  if (data && customerData == true) {
    if (data.customerData) {
      setCustomerData(data.customerData)
      setIsCustomer(false);
    }
  }


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      title: authData.title || "",
      first_name: authData.first_name || "",
      last_name: authData.last_name || "",
      department:authData.department || "",
      designation: authData.designation || "",
      landLine_no: authData.landLine_no || "",
      email: authData.email || "",
      mobile: authData.mobile || "",
      company_name: authData.company_name || "",
      company_address: authData.company_address || "",
      additional_address: authData.additional_address || "",
      gstin: authData.gstin || "",
      city: authData.city || "",
      state: authData.state || "",
      country: authData.country || "",
      pincode: authData.pincode || "",
      billing_company_name: authData.billing_company_name || "",
      billing_company_address: authData.billing_company_address || "",
      billing_additional_address: authData.billing_additional_address || "",
      billing_city: authData.billing_city || "",
      billing_state: authData.billing_state || "",
      billing_country: authData.billing_country || "",
      billing_pin_code: authData.billing_pin_code || "",
      delivery_company_name: authData.delivery_company_name || "",
      delivery_company_address: authData.delivery_company_address || "",
      delivery_additional_address: authData.delivery_additional_address || "",
      delivery_city: authData.delivery_city || "",
      delivery_state: authData.delivery_state || "",
      delivery_country: authData.delivery_country || "",
      delivery_pin_code: authData.delivery_pin_code || "",
      billing_same_as: "",
      delivery_same_as: "",
      industry_type: authData.industry_type as string[],
      tool_room_type:authData.tool_room_type as string[]
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Select Title"),
      first_name: Yup.string().required("Please Enter First Name").matches(
        /^[A-Za-z]+$/i,
        "Must be a valid Name"
      ).max(50, "Must be less than  50 characters"),
      last_name: Yup.string().required("Please Enter Last Name").matches(
        /^[A-Za-z]+$/i,
        "Must be a valid Name"
      ).max(50, "Must be less than  50 characters"),
      department: Yup.string().required("Please Enter Department").matches(
        /^[A-Za-z ]+$/i,
        "Must be a valid Name"
      ).max(50, "Must be less than  50 characters"),
      designation: Yup.string().required("Please Enter Designation").matches(
        /^[A-Za-z ]+$/i,
        "Must be a valid Name"
      ).max(50, "Must be less than  50 characters"),
      company_name: Yup.string().required("Please Enter Company Name").matches(
        /^[A-Za-z ]+$/i,
        "Must be a valid Name"
      ).max(50, "Must be less than  50 characters"),
      company_address: Yup.string().required("Please Enter Company Address").matches(
        /^[a-zA-Z0-9\s,'-]*$/,
        "Must be a valid Name"
      ).max(50, "Must be less than  50 characters"),
      gstin: Yup.string()
        .required("Please enter GSTIN")
        .matches(
          /^[0-9A-Za-z]{15}$/,
          "Must be a valid GSTIN with 15 alphanumeric characters"
        ),
      city: Yup.string().required("Please Enter City").matches(
        /^[A-Za-z ]+$/i,
        "Must be a valid Name"
      ).max(50, "Must be less than  50 characters"),
      state: Yup.string().required("Please Enter State").matches(
        /^[A-Za-z ]+$/i,
        "Must be a valid Name"
      ).max(50, "Must be less than  50 characters"),
      country: Yup.string().required("Please Enter Country").matches(
        /^[A-Za-z ]+$/i,
        "Must be a valid Name"
      ).max(50, "Must be less than  50 characters"),
      pincode: Yup.string()
      .required("Please Enter Delivery Pin Code")
      .matches(/^\d{6}$/, "Please Enter a Valid 6-digit PIN Code"),
      email: Yup.string().required("Please Enter Email").matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Must be a valid Email"
      ),
      mobile: Yup.string().required("Please Enter Mobile").matches(
        /^[+0-9]+$/i,
        "Please Enter Valid Mobile"
      ).min(9, "Please Enter Valid Mobile").max(12, "Please Enter Valid Mobile"),
      billing_company_name: Yup.string().test('is-billing-required', 'Please Enter Billing Company Name', function(value) {
        // Check if sameAsAbove is false and billing_company_name is empty
        if(this.parent.billing_same_as && !value){
          return true;
        }
        if(this.parent.billing_same_as && value){
          return true;
        }
        if(!this.parent.billing_same_as && value){
          return true;
        }
      }),
      billing_company_address: Yup.string().test('is-billing-required', 'Please Enter Billing Company Address', function(value) {
        // Check if sameAsAbove is false and billing_company_address is empty
        if(this.parent.billing_same_as && !value){
          return true;
        }
        if(this.parent.billing_same_as && value){
          return true;
        }
        if(!this.parent.billing_same_as && value){
          return true;
        }
      }),
      billing_additional_address: Yup.string().test('is-billing-required', 'Please Enter Billing Additional Address', function(value) {
        // Check if sameAsAbove is false and billing_additional_address is empty
        if(this.parent.billing_same_as && !value){
          return true;
        }
        if(this.parent.billing_same_as && value){
          return true;
        }
        if(!this.parent.billing_same_as && value){
          return true;
        }
      }),
      billing_city: Yup.string().test('is-billing-required', 'Please Enter Billing City', function(value) {
        // Check if sameAsAbove is false and billing_city is empty
        if(this.parent.billing_same_as && !value){
          return true;
        }
        if(this.parent.billing_same_as && value){
          return true;
        }
        if(!this.parent.billing_same_as && value){
          return true;
        }
      }),
      billing_state: Yup.string().test('is-billing-required', 'Please Enter Billing State', function(value) {
        // Check if sameAsAbove is false and billing_state is empty
        if(this.parent.billing_same_as && !value){
          return true;
        }
        if(this.parent.billing_same_as && value){
          return true;
        }
        if(!this.parent.billing_same_as && value){
          return true;
        }
      }),
      billing_country: Yup.string().test('is-billing-required', 'Please Enter Billing Country', function(value) {
        // Check if sameAsAbove is false and billing_country is empty
        if(this.parent.billing_same_as && !value){
          return true;
        }
        if(this.parent.billing_same_as && value){
          return true;
        }
        if(!this.parent.billing_same_as && value){
          return true;
        }
      }),
      billing_pin_code: Yup.string().test('is-billing-required', 'Please Enter Billing Pin Code', function(value) {
        // Check if sameAsAbove is false and billing_pin_code is empty
        if(this.parent.billing_same_as && !value){
          return true;
        }
        if(this.parent.billing_same_as && value){
          return true;
        }
        if(!this.parent.billing_same_as && value){
          return true;
        }
      }),

      // Delivery information
      delivery_company_name: Yup.string().test('is-delivery-required', 'Please Enter Delivery Company Name', function(value) {
        // Check if sameAsAbove is false and delivery_company_name is empty
        if(this.parent.delivery_same_as && !value){
          return true;
        }
        if(this.parent.delivery_same_as && value){
          return true;
        }
        if(!this.parent.delivery_same_as && value){
          return true;
        }
      }),
      delivery_company_address: Yup.string().test('is-delivery-required', 'Please Enter Delivery Company Address', function(value) {
        // Check if sameAsAbove is false and delivery_company_address is empty
         if(this.parent.delivery_same_as && !value){
          return true;
        }
        if(this.parent.delivery_same_as && value){
          return true;
        }
        if(!this.parent.delivery_same_as && value){
          return true;
        }
      }),
      delivery_additional_address: Yup.string().test('is-delivery-required', 'Please Enter Delivery Additional Address', function(value) {
        // Check if sameAsAbove is false and delivery_additional_address is empty
         if(this.parent.delivery_same_as && !value){
          return true;
        }
        if(this.parent.delivery_same_as && value){
          return true;
        }
        if(!this.parent.delivery_same_as && value){
          return true;
        }
      }),
      delivery_city: Yup.string().test('is-delivery-required', 'Please Enter Delivery City', function(value) {
        // Check if sameAsAbove is false and delivery_city is empty
         if(this.parent.delivery_same_as && !value){
          return true;
        }
        if(this.parent.delivery_same_as && value){
          return true;
        }
        if(!this.parent.delivery_same_as && value){
          return true;
        }
      }),
      delivery_state: Yup.string().test('is-delivery-required', 'Please Enter Delivery State', function(value) {
        // Check if sameAsAbove is false and delivery_state is empty
         if(this.parent.delivery_same_as && !value){
          return true;
        }
        if(this.parent.delivery_same_as && value){
          return true;
        }
        if(!this.parent.delivery_same_as && value){
          return true;
        }
      }),
      delivery_country: Yup.string().test('is-delivery-required', 'Please Enter Delivery Country', function(value) {
        // Check if sameAsAbove is false and delivery_country is empty
         if(this.parent.delivery_same_as && !value){
          return true;
        }
        if(this.parent.delivery_same_as && value){
          return true;
        }
        if(!this.parent.delivery_same_as && value){
          return true;
        }
      }),
      delivery_pin_code: Yup.string().test('is-delivery-required', 'Please Enter Delivery Pin Code', function(value) {
        // Check if sameAsAbove is false and delivery_pin_code is empty
         if(this.parent.delivery_same_as && !value){
          return true;
        }
        if(this.parent.delivery_same_as && value){
          return true;
        }
        if(!this.parent.delivery_same_as && value){
          return true;
        }
      }),

    }),
    onSubmit: values => {
      const newData = {
        title: values["title"],
        first_name: values["first_name"],
        last_name: values["last_name"],
        department: values["department"],
        designation: values["designation"],
        landLine_no: values["landLine_no"],
        email: values["email"],
        mobile: values["mobile"],
        company_name: values["company_name"],
        company_address: values["company_address"],
        additional_address: values["additional_address"],
        gstin: values["gstin"],
        city: values["city"],
        state: values["state"],
        country: values["country"],
        pincode: values["pincode"],

        billing_company_name: (!values["billing_same_as"])? values["billing_company_name"] : values["company_name"],
        billing_company_address: (!values["billing_same_as"])? values["billing_company_address"] : values["company_address"],
        billing_additional_address: (!values["billing_same_as"])? values["billing_additional_address"] : values["additional_address"],
        billing_city: (!values["billing_same_as"])? values["billing_city"] : values["city"],
        billing_state: (!values["billing_same_as"])? values["billing_state"] : values["state"],
        billing_country: (!values["billing_same_as"])? values["billing_country"] : values["country"],
        billing_pin_code: (!values["billing_same_as"])? values["billing_pin_code"] : values["pincode"],

        delivery_company_name: (!values["delivery_same_as"])? values["delivery_company_name"] : values["company_name"],
        delivery_company_address: (!values["delivery_same_as"])? values["delivery_company_address"] : values["company_address"],
        delivery_additional_address: (!values["delivery_same_as"])? values["delivery_additional_address"] : values["additional_address"],
        delivery_city: (!values["delivery_same_as"])? values["delivery_city"] : values["city"],
        delivery_state: (!values["delivery_same_as"])? values["delivery_state"] : values["state"],
        delivery_country: (!values["delivery_same_as"])? values["delivery_country"] : values["country"],
        delivery_pin_code: (!values["delivery_same_as"])? values["delivery_pin_code"] : values["pincode"],

        billing_same_as: values["billing_same_as"],
        delivery_same_as: values["delivery_same_as"],
        industry_type: values["industry_type"] as string[],
        tool_room_type: values["tool_room_type"] as string[]
      };
      dispatch(onUpdateData(newData, "", api.frontUpdateCustomerDetails))
      setSignUpData(true);
    },
  });

  type SectionType = 'company' | 'billing' | 'delivery';
  const [activeSection, setActiveSection] = useState<SectionType | null>(null);

  const onOpenActive = (section: SectionType) => {
    if (activeSection !== section) {
      setActiveSection(section);
    } else {
      setActiveSection(null);
    }
  };

  const onCloseActive = () => {
    setActiveSection(null);
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account || Vijaydeep</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            Account infomation
          </h2>
          <form
            onSubmit={e => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            method="post"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h3 className="uppercase font-semibold text-xl dark:text-neutral-200 tracking-wider mb-6">User Details</h3>

                <div className="mb-3">
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      Title
                    </span>
                    <Select className="mt-2 w-full"
                    name="title"
                    value={validation.values.title || ""}
                    onChange={e => {
                      // Convert the checked state to a boolean value
                      const isChecked = e.target.value;
                      validation.handleChange({
                        target: {
                          name: 'title',
                          value: isChecked,
                        },
                      });
                    }}
                    >
                      <option value=""  >Select Title</option>
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                      <option value="Ms">Ms</option>
                    </Select>
                    {validation.errors.title ? (
                      <small className="text-rose-600">{validation.errors.title}</small>
                    ) : null}
                  </label>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-6">
                  <div className="mb-3">
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        First Name
                      </span>
                      <Input
                        type="text"
                        placeholder=""
                        className="mt-1"
                        name="first_name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.first_name || ""}
                      />
                      {validation.errors.first_name ? (
                        <small className="text-rose-600">{validation.errors.first_name}</small>
                      ) : null}
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Last Name
                      </span>
                      <Input
                        type="text"
                        placeholder=""
                        className="mt-1"
                        name="last_name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.last_name || ""}
                      />
                      {validation.errors.last_name ? (
                        <small className="text-rose-600">{validation.errors.last_name}</small>
                      ) : null}
                    </label>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-6">
                  <div className="mb-3">
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Department
                      </span>
                      <Input
                        type="text"
                        placeholder=""
                        className="mt-1"
                        name="department"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.department || ""}
                      />
                      {validation.errors.department ? (
                        <small className="text-rose-600">{validation.errors.department}</small>
                      ) : null}
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Designation
                      </span>
                      <Input
                        type="text"
                        placeholder=""
                        className="mt-1"
                        name="designation"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.designation || ""}
                      />
                      {validation.errors.designation ? (
                        <small className="text-rose-600">{validation.errors.designation}</small>
                      ) : null}
                    </label>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-6">
                  <div className="mb-3">
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Mobile
                      </span>
                      <Input
                        type="text"
                        placeholder=""
                        className="mt-1"
                        name="mobile"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.mobile || ""}
                      />
                      {validation.errors.mobile ? (
                        <small className="text-rose-600">{validation.errors.mobile}</small>
                      ) : null}
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        LandLine No
                      </span>
                      <Input
                        type="text"
                        placeholder=""
                        className="mt-1"
                        name="landLine_no"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.landLine_no || ""}
                      />
                      {validation.errors.landLine_no ? (
                        <small className="text-rose-600">{validation.errors.landLine_no}</small>
                      ) : null}
                    </label>
                  </div>
                </div>

                <div className="mb-3">
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      Email ID
                    </span>
                    <Input
                      type="email"
                      name="email"
                      placeholder="example@example.com"
                      className="mt-1"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                    />
                    {validation.errors.email ? (
                      <small className="text-rose-600">{validation.errors.email}</small>
                    ) : null}
                  </label>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-1 gap-0 md:gap-6">
                  <div className="mb-3">
                    <label className="block">
                      <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                        Industry Type (Multiple Selection can be done)
                      </span>
                    </label>
                    <div className="flex items-center text-sm sm:text-base mt-5">
                      <input
                        name="industry_type"
                        type="checkbox"
                        onChange={validation.handleChange}
                        value="Moulding Company"
                        checked={(validation.values.industry_type.includes("Moulding Company"))? true : false}
                        className="focus:ring-action-primary text-primary-500 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500 w-5 h-5"
                      />
                      <label className="pl-2.5 sm:pl-3.5 flex flex-col flex-1 justify-center select-none">
                        <span className="text-slate-900 dark:text-slate-100 text-sm font-normal ">Moulding Company</span>
                      </label>
                    </div>
                    <div className="flex items-center text-sm sm:text-base mt-5">
                      <input
                        name="industry_type"
                        type="checkbox"
                        onChange={validation.handleChange}
                        value="Mould Maker"
                        checked={(validation.values.industry_type.includes("Mould Maker"))? true : false}
                        className="focus:ring-action-primary text-primary-500 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500 w-5 h-5"
                      />
                      <label className="pl-2.5 sm:pl-3.5 flex flex-col flex-1 justify-center select-none">
                        <span className="text-slate-900 dark:text-slate-100 text-sm font-normal ">Mould Maker</span>
                      </label>
                    </div>
                    <div className="flex items-center text-sm sm:text-base mt-5">
                      <input
                        name="industry_type"
                        type="checkbox"
                        onChange={validation.handleChange}
                        value="Press Tool Manufacturing Company"
                        checked={(validation.values.industry_type.includes("Press Tool Manufacturing Company"))? true : false}
                        className="focus:ring-action-primary text-primary-500 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500 w-5 h-5"
                      />
                      <label className="pl-2.5 sm:pl-3.5 flex flex-col flex-1 justify-center select-none">
                        <span className="text-slate-900 dark:text-slate-100 text-sm font-normal ">Press Tool Manufacturing Company</span>
                      </label>
                    </div>
                    <div className="flex items-center text-sm sm:text-base mt-5">
                      <input
                        name="industry_type"
                        type="checkbox"
                        onChange={validation.handleChange}
                        value="Stamping Company"
                        checked={(validation.values.industry_type.includes("Stamping Company"))? true : false}
                        className="focus:ring-action-primary text-primary-500 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500 w-5 h-5"
                      />
                      <label className="pl-2.5 sm:pl-3.5 flex flex-col flex-1 justify-center select-none">
                        <span className="text-slate-900 dark:text-slate-100 text-sm font-normal ">Stamping Company</span>
                      </label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="block">
                      <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                        Tool Room Type
                      </span>
                    </label>
                    <div className="flex items-center text-sm sm:text-base mt-5">
                      <input
                        name="tool_room_type"
                        type="checkbox"
                        onChange={validation.handleChange}
                        value="Captive Tool Room"
                        checked={(validation.values.tool_room_type.includes("Captive Tool Room"))? true : false}
                        className="focus:ring-action-primary text-primary-500 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500 w-5 h-5"
                      />
                      <label className="pl-2.5 sm:pl-3.5 flex flex-col flex-1 justify-center select-none">
                        <span className="text-slate-900 dark:text-slate-100 text-sm font-normal ">Captive Tool Room</span>
                      </label>
                    </div>
                    <div className="flex items-center text-sm sm:text-base mt-5">
                      <input
                        name="tool_room_type"
                        type="checkbox"
                        onChange={validation.handleChange}
                        value="Commercial Tool Room"
                        checked={(validation.values.tool_room_type.includes("Commercial Tool Room"))? true : false}
                        className="focus:ring-action-primary text-primary-500 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500 w-5 h-5"
                      />
                      <label className="pl-2.5 sm:pl-3.5 flex flex-col flex-1 justify-center select-none">
                        <span className="text-slate-900 dark:text-slate-100 text-sm font-normal ">Commercial Tool Room</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {/* ------------------------------company-details-start----------------- */}
                <h3 className="uppercase font-semibold text-xl dark:text-neutral-200 tracking-wider mb-6">Company Details</h3>
                <div className="border border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden z-0">
                  <Link to="" onClick={() => onOpenActive('company')}>
                    <div className="flex items-center justify-start p-6">
                      <h3 className=" text-slate-700 dark:text-slate-300 flex uppercase tracking-tight">
                        Company Details
                      </h3>
                    </div>
                  </Link>

                  {/* Edit section */}
                  <div className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 ${activeSection === 'company' ? "block" : "hidden"}`}>
                    <h3 className="text-lg font-semibold mb-5">Company Details</h3>
                    <div className="mb-3">
                      <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                          Company Name
                        </span>
                        <Input
                          type="text"
                          placeholder=""
                          className="mt-2"
                          name="company_name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.company_name || ""}
                        />
                        {validation.errors.company_name ? (
                          <small className="text-rose-600">{validation.errors.company_name}</small>
                        ) : null}
                      </label>
                    </div>
                    <div className="mb-3">
                      <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                          Company Address
                        </span>
                        <Input
                          type="text"
                          placeholder=""
                          className="mt-2"
                          name="company_address"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.company_address || ""}
                        />
                        {validation.errors.company_address ? (
                          <small className="text-rose-600">{validation.errors.company_address}</small>
                        ) : null}
                      </label>
                    </div>
                    <div className="mb-3">
                      <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                          Additional Address
                        </span>
                        <Input
                          type="text"
                          placeholder=""
                          className="mt-2"
                          name="additional_address"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.additional_address || ""}
                        />
                        {validation.errors.additional_address ? (
                          <small className="text-rose-600">{validation.errors.additional_address}</small>
                        ) : null}
                      </label>
                    </div>
                    <div className="mb-3">
                      <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                          GSTIN
                        </span>
                        <Input
                          type="text"
                          placeholder=""
                          className="mt-2"
                          name="gstin"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.gstin || ""}
                        />
                        {validation.errors.gstin ? (
                          <small className="text-rose-600">{validation.errors.gstin}</small>
                        ) : null}
                      </label>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-6">
                      <div className="mb-3">
                        <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                            City
                          </span>
                          <Input
                            type="text"
                            placeholder=""
                            className="mt-2"
                            name="city"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.city || ""}
                          />
                          {validation.errors.city ? (
                            <small className="text-rose-600">{validation.errors.city}</small>
                          ) : null}
                        </label>
                      </div>
                      <div className="mb-3">
                        <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                            State
                          </span>
                          <Input
                            type="text"
                            placeholder=""
                            className="mt-2"
                            name="state"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.state || ""}
                          />
                          {validation.errors.state ? (
                            <small className="text-rose-600">{validation.errors.state}</small>
                          ) : null}
                        </label>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-6">
                      <div className="mb-3">
                        <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                            Country
                          </span>
                          <Input
                            type="text"
                            placeholder=""
                            className="mt-2"
                            name="country"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.country || ""}
                          />
                          {validation.errors.country ? (
                            <small className="text-rose-600">{validation.errors.country}</small>
                          ) : null}
                        </label>
                      </div>
                      <div className="mb-3">
                        <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                            Pin Code
                          </span>
                          <Input
                            type="text"
                            placeholder=""
                            className="mt-2"
                            name="pincode"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.pincode || ""}
                          />
                          {validation.errors.pincode ? (
                            <small className="text-rose-600">{validation.errors.pincode}</small>
                          ) : null}
                        </label>
                      </div>
                    </div>
                    {/* Buttons to save or cancel */}
                    <div className="flex flex-col sm:flex-row">
                      <ButtonPrimary
                        className="sm:!px-7 shadow-none"
                        onClick={() => onOpenActive('billing')}
                      >
                        Next
                      </ButtonPrimary>
                      <ButtonSecondary
                        className="mt-3 sm:mt-0 sm:ml-3"
                        onClick={onCloseActive}
                      >
                        Cancel
                      </ButtonSecondary>
                    </div>
                  </div>
                </div>
                {/* ------------------------------company-details-end----------------- */}


                {/* ------------------------------BILLING ADDRESS-start----------------- */}
                <div className="border border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden z-0 mt-8">
                  <Link to="" onClick={() => onOpenActive('billing')}>
                    <div className="flex items-center justify-start p-6">
                      <h3 className=" text-slate-700 dark:text-slate-300 flex uppercase tracking-tight">
                        BILLING ADDRESS
                      </h3>
                    </div>
                  </Link>

                  {/* Edit section */}
                  <div className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 ${activeSection === 'billing' ? "block" : "hidden"}`}>
                    <div className="flex justify-between flex-wrap items-baseline mb-5">
                      <h3 className="text-lg font-semibold">Billing Address</h3>
                      <div className="flex items-center text-sm sm:text-base ">
                        <input name="billing_same_as" type="checkbox"
                         onChange={e => {
                          // Convert the checked state to a boolean value
                          const isChecked = e.target.checked;
                          validation.handleChange({
                            target: {
                              name: 'billing_same_as',
                              value: isChecked,
                            },
                          });
                        }}
                        className="focus:ring-action-primary text-primary-500 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500 w-5 h-5" />
                        <label className="pl-2.5 sm:pl-3.5 flex flex-col flex-1 justify-center select-none">
                          <span className="text-slate-900 dark:text-slate-100 text-sm font-normal ">Same as above</span>
                        </label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                          Company Name
                        </span>
                        <Input
                          type="text"
                          placeholder=""
                          className="mt-2"
                          name="billing_company_name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.billing_company_name || ""}
                        />
                        {validation.errors.billing_company_name ? (
                          <small className="text-rose-600">{validation.errors.billing_company_name}</small>
                        ) : null}
                      </label>
                    </div>
                    <div className="mb-3">
                      <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                          Address
                        </span>
                        <Input
                          type="text"
                          placeholder=""
                          className="mt-2"
                          name="billing_company_address"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.billing_company_address || ""}
                        />
                        {validation.errors.billing_company_address ? (
                          <small className="text-rose-600">{validation.errors.billing_company_address}</small>
                        ) : null}
                      </label>
                    </div>
                    <div className="mb-3">
                      <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                          Additional Address
                        </span>
                        <Input
                          type="text"
                          placeholder=""
                          className="mt-2"
                          name="billing_additional_address"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.billing_additional_address || ""}
                        />
                        {validation.errors.billing_additional_address ? (
                          <small className="text-rose-600">{validation.errors.billing_additional_address}</small>
                        ) : null}
                      </label>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-6">
                      <div className="mb-3">
                        <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                            City
                          </span>
                          <Input
                            type="text"
                            placeholder=""
                            className="mt-2"
                            name="billing_city"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.billing_city || ""}
                          />
                          {validation.errors.billing_city ? (
                            <small className="text-rose-600">{validation.errors.billing_city}</small>
                          ) : null}
                        </label>
                      </div>
                      <div className="mb-3">
                        <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                            State
                          </span>
                          <Input
                            type="text"
                            placeholder=""
                            className="mt-2"
                            name="billing_state"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.billing_state || ""}
                          />
                          {validation.errors.billing_state ? (
                            <small className="text-rose-600">{validation.errors.billing_state}</small>
                          ) : null}
                        </label>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-6">
                      <div className="mb-3">
                        <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                            Country
                          </span>
                          <Input
                            type="text"
                            placeholder=""
                            className="mt-2"
                            name="billing_country"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.billing_country || ""}
                          />
                          {validation.errors.billing_country ? (
                            <small className="text-rose-600">{validation.errors.billing_country}</small>
                          ) : null}
                        </label>
                      </div>
                      <div className="mb-3">
                        <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                            Pin Code
                          </span>
                          <Input
                            type="text"
                            placeholder=""
                            className="mt-2"
                            name="billing_pin_code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.billing_pin_code || ""}
                          />
                          {validation.errors.billing_pin_code ? (
                            <small className="text-rose-600">{validation.errors.billing_pin_code}</small>
                          ) : null}
                        </label>
                      </div>
                    </div>
                    {/* Buttons to save or cancel */}
                    <div className="flex flex-col sm:flex-row">
                      <ButtonPrimary
                        className="sm:!px-7 shadow-none"
                        onClick={() => onOpenActive('delivery')}
                      >
                        Next
                      </ButtonPrimary>
                      <ButtonSecondary
                        className="mt-3 sm:mt-0 sm:ml-3"
                        onClick={onCloseActive}
                      >
                        Cancel
                      </ButtonSecondary>
                    </div>
                  </div>
                </div>

                {/* ------------------------------BILLING ADDRESS-end----------------- */}

                {/* ------------------------------Delivery Address-start----------------- */}
                <div className="border border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden z-0 mt-8">
                  <Link to="" onClick={() => onOpenActive('delivery')}>
                    <div className="flex items-center justify-start p-6">
                      <h3 className=" text-slate-700 dark:text-slate-300 flex uppercase tracking-tight">
                        Delivery Address
                      </h3>
                    </div>
                  </Link>

                  {/* Edit section */}
                  <div className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 ${activeSection === 'delivery' ? "block" : "hidden"}`}>
                    <div className="flex justify-between flex-wrap items-baseline mb-5">
                      <h3 className="text-lg font-semibold">Delivery Address</h3>
                      <div className="flex items-center text-sm sm:text-base ">
                      <input name="delivery_same_as" type="checkbox"
                         onChange={e => {
                          // Convert the checked state to a boolean value
                          const isChecked = e.target.checked;
                          validation.handleChange({
                            target: {
                              name: 'delivery_same_as',
                              value: isChecked,
                            },
                          });
                        }}
                        className="focus:ring-action-primary text-primary-500 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500 w-5 h-5" />
                        <label className="pl-2.5 sm:pl-3.5 flex flex-col flex-1 justify-center select-none">
                          <span className="text-slate-900 dark:text-slate-100 text-sm font-normal ">Same as above</span>
                        </label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                          Company Name
                        </span>
                        <Input
                          type="text"
                          placeholder=""
                          className="mt-2"
                          name="delivery_company_name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.delivery_company_name || ""}
                        />
                        {validation.errors.delivery_company_name ? (
                          <small className="text-rose-600">{validation.errors.delivery_company_name}</small>
                        ) : null}
                      </label>
                    </div>
                    <div className="mb-3">
                      <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                          Address
                        </span>
                        <Input
                          type="text"
                          placeholder=""
                          className="mt-2"
                          name="delivery_company_address"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.delivery_company_address || ""}
                        />
                        {validation.errors.delivery_company_address ? (
                          <small className="text-rose-600">{validation.errors.delivery_company_address}</small>
                        ) : null}
                      </label>
                    </div>
                    <div className="mb-3">
                      <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                          Additional Address
                        </span>
                        <Input
                          type="text"
                          placeholder=""
                          className="mt-2"
                          name="delivery_additional_address"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.delivery_additional_address || ""}
                        />
                        {validation.errors.delivery_additional_address ? (
                          <small className="text-rose-600">{validation.errors.delivery_additional_address}</small>
                        ) : null}
                      </label>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-6">
                      <div className="mb-3">
                        <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                            City
                          </span>
                          <Input
                            type="text"
                            placeholder=""
                            className="mt-2"
                            name="delivery_city"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.delivery_city || ""}
                          />
                          {validation.errors.delivery_city ? (
                            <small className="text-rose-600">{validation.errors.delivery_city}</small>
                          ) : null}
                        </label>
                      </div>
                      <div className="mb-3">
                        <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                            State
                          </span>
                          <Input
                            type="text"
                            placeholder=""
                            className="mt-2"
                            name="delivery_state"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.delivery_state || ""}
                          />
                          {validation.errors.delivery_state ? (
                            <small className="text-rose-600">{validation.errors.delivery_state}</small>
                          ) : null}
                        </label>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-6">
                     <div className="mb-3">
                        <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                            Country
                          </span>
                          <Input
                            type="text"
                            placeholder=""
                            className="mt-2"
                            name="delivery_country"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.delivery_country || ""}
                          />
                          {validation.errors.delivery_country ? (
                            <small className="text-rose-600">{validation.errors.delivery_country}</small>
                          ) : null}
                        </label>
                      </div>
                      <div className="mb-3">
                        <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                            Pin Code
                          </span>
                          <Input
                            type="text"
                            placeholder=""
                            className="mt-2"
                            name="delivery_pin_code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.delivery_pin_code || ""}
                          />
                          {validation.errors.delivery_pin_code ? (
                            <small className="text-rose-600">{validation.errors.delivery_pin_code}</small>
                          ) : null}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ------------------------------Delivery Address-end----------------- */}

              </div>
            </div>
            <div className="flex justify-center">
                  <ButtonPrimary
                    className="sm:!px-7 mt-5 shadow-none"
                    type="submit"
                  >
                    Update Account
                  </ButtonPrimary>
                </div>
          </form>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
