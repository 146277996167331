import React, { FC, useEffect, useState, useMemo, ChangeEvent } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Datatable from "./Datatable";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData
} from "store/AppMaster/actions";
import api from "constants/api";
import { RootState } from 'store/reducers';
import {
  Typography,
  Button,
  Avatar,
} from "@material-tailwind/react";
import RevisedQuoteModal from "./RevisedQuoteModal";
import FollowUpModal from "./FollowUPModal";
import ConfirmOrderModal from "./ConfirmOrderModal";
import { isEmpty, set } from "lodash";
import moment from 'moment';
import { date } from "yup";

export interface AdminUserProps {
  data: any;
  result: any[]
}


const Index: FC<AdminUserProps> = (props) => {
  const dispatch = useDispatch();
  const [isApiCall, setIsApiCall] = useState(0)
  const [listCount, setListCount] = useState(0);
  const [listData, setListData] = useState([]);
  const [editListData, setEditData] = useState([]);
  const [updateListData, setUpdateListData] = useState(false);
  const [changeStatus, setChangeStatus] = useState('');
  const [showEditModalQuickView, setEditShowModalQuickView] = useState(false);
  const [filterData, setFilterData] = useState({
    start: 0,
    limit: 10,
    date: '',
    status: '',
    type: '',
    search: '',
    sort_by: "_id",
    sort_order: "asc",
  });

  const [statusOptions, setStatusOptions] = useState([

    { key: 'pending', value: 'Pending' },
    { key: 'revisedQuote', value: 'Revised Quote' },
    { key: 'followUp', value: 'Follow Up' },
    { key: 'orderConfirmed', value: 'Order Confirmed' }
  ])
  const [statusModal, setStatusModal] = useState(false);
  useEffect(() => {
    if (isApiCall == 0) {
      getListData();
      setIsApiCall(1);
    }
  }, [isApiCall]);

  const { data } = useSelector((state: RootState) => state.AppMaster);
  const [cartData, setCartData] = useState<any>([]);
  const [showRevisedQuoteModal, setShowRevisedQuoteModal] = useState<Boolean>(false);
  const [showFollowUpModal, setShowFollowUpModal] = useState<Boolean>(false);
  const [showConfirmOrderModal, setShowConfirmOrderModal] = useState<Boolean>(false);
  const [enquiryId, setEnquiryId] = useState<any>('');
  const [customerMAil, setCustomerMail] = useState<any>('');

  /**Change Status */
  const handleStatusChange = (event: any, status: string, id: string, row: any, element: string) => {
    setCartData(row.cart_items);
    setEnquiryId(id);
    setCustomerMail(row.email);
    if (element == 'select') {
      if (event.target.value === "revisedQuote") {
        setShowRevisedQuoteModal(true);

      } else if (event.target.value === "followUp") {
        setShowFollowUpModal(true);
      } else if (event.target.value === "orderConfirmed") {
        setShowConfirmOrderModal(true);
      }
    } else if (element == 'button') {
      if (status === "revisedQuote") {
        setShowRevisedQuoteModal(true);

      } else if (status === "followUp") {
        setShowFollowUpModal(true);
      }
    }

    // console.log(row);

    // if (status == 'Active') {
    //     status = 'Inactive';
    // } else {
    //     status = 'Active';
    // }
    // const updateData = {
    //     status: status,
    //     id: id,
    // };
    // dispatch(onUpdateData(updateData,'', api.changeStatusCategory))
  }

  const handleEditClick = (arg: any) => {
    setEditData(arg);
    setEditShowModalQuickView(true)
  };

  const getListData = async () => {
    setUpdateListData(true);
    dispatch(clearData())
    dispatch(onGetList(filterData, '', api.inquiryList));
  }

  if (data && updateListData == true) {
    if (data.length != 0) {

      setUpdateListData(false);
      setListData(data.result);
      setListCount(data.total_count);
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Order No",
        Cell: (cellProps:any) => {
          return (
              <div className="flex gap-3">
                  <a href={`/admin/inquiry/${cellProps.row.original._id}`}
                    className="text-secondary"
                  >
                    {cellProps.row.original.order_number}
                  </a>
              </div>
          );
        },
      },
      {
        Header: "Name",
        accessor: 'name',
      },
      {
        Header: "Email",
        accessor: 'email',
      },
      {
        Header: "Mobile",
        accessor: 'mobile',
      }, {
        Header: "Type",
        accessor: 'type',
        Cell: (cellProps: any) => {
          const { type } = cellProps.row.original;
          if (type === "requestDiscount") {
            return "Discount Requested";
          } else if (type === "confirm") {
            return "Confirmed";
          } else {
            return type; // Return the original type if it doesn't match the conditions
          }
        }
      }
      ,
      {
        Header: "Status",
        Cell: (cellProps: any) => {
          return (
            <>
              <select
                className={`nc-Select h-11 mt-1 block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
                disabled={cellProps.row.original.status == 'orderConfirmed'}
                onChange={(e) => {
                  handleStatusChange(e, cellProps.row.original.status, cellProps.row.original._id, cellProps.row.original, 'select');
                }}
              >
                {statusOptions.map((option, index) => (
                  <option key={index} value={option.key} selected={cellProps.row.original.status == option.key}>
                    {option.value}
                  </option>
                ))}
              </select>
              {cellProps.row.original.status != 'orderConfirmed' && <button type="button" className="bg-black text-white rounded-md p-2 mt-1 "
                onClick={(e) => {
                  e.preventDefault();
                  handleStatusChange(e, cellProps.row.original.status, cellProps.row.original._id, cellProps.row.original, 'button');
                }}>Repeat Selected status</button>}

            </>
          );
        }
      },
      {
        Header: "Date",
        accessor: 'createdAt',
        Cell: (cellProps:any) => {
          const createdAtDate = moment(cellProps.value); // Parse the date using moment
          const formattedDate = createdAtDate.format('DD-MM-YYYY hh:mm A'); // Format the date
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps: any) => {
          return (
            <div className="flex gap-3">
              <a href={`/admin/inquiry/${cellProps.row.original._id}`}
                className="text-secondary"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 192 512"><path d="M20 424.2h20V279.8H20c-11 0-20-9-20-20V212c0-11 9-20 20-20h112c11 0 20 9 20 20v212.2h20c11 0 20 9 20 20V492c0 11-9 20-20 20H20c-11 0-20-9-20-20v-47.8c0-11 9-20 20-20zM96 0C56.2 0 24 32.2 24 72s32.2 72 72 72 72-32.2 72-72S135.8 0 96 0z" /></svg>
              </a>
            </div>
          );
        },
      }
    ],
    []
  );


  return (
    <>
      <div className="container py-16 lg:pb-28 lg:pt-20">
        <Helmet>
          <title>Admin User</title>
        </Helmet>
        <div className="mb-12 sm:mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">Inquiry</h2>
        </div>
        <div className="mt-8 sm:mt-10 mb-8 sm:mb-10">

          <Datatable
            listData={listData}
            filterData={filterData}
            getListData={getListData}
            listCount={listCount}
            columns={columns}
            editData={editListData}
          />
        </div>
      </div>
      {showRevisedQuoteModal && <RevisedQuoteModal cartData={cartData} setCartData={setCartData} setShowRevisedQuoteModal={setShowRevisedQuoteModal} enquiryId={enquiryId} setIsApiCall={setIsApiCall} />
      }
      {showFollowUpModal && <FollowUpModal enquiryId={enquiryId} setShowFollowUpModal={setShowFollowUpModal} setIsApiCall={setIsApiCall} />
      }
      {showConfirmOrderModal && <ConfirmOrderModal enquiryId={enquiryId} setShowConfirmOrderModal={setShowConfirmOrderModal} setIsApiCall={setIsApiCall} customerMAil={customerMAil} />
      }
    </>
  );
};

export default withRouter(Index);