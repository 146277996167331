import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    API_ERROR,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_ERROR,
    GET_LIST,
    GET_LIST_FAIL,
    GET_LIST_SUCCESS,
    GET_DATA,
    GET_DATA_FAIL,
    GET_DATA_SUCCESS,
    ADD_NEW_DATA,
    ADD_DATA_SUCCESS,
    ADD_DATA_FAIL,
    UPDATE_DATA,
    UPDATE_DATA_SUCCESS,
    UPDATE_LIST_DATA_SUCCESS,
    UPDATE_DATA_FAIL,
    CLEAR_DATA,
    LOGIN_CUSTOMER,
    LOGOUT_CUSTOMER
  } from "./actionTypes"

  export const loginUser = (data, history, url) => {
    return {
      type: LOGIN_USER,
      payload: { data, history, url },
    }
  }

  export const loginCustomer = (data, history, url) => {
    return {
      type: LOGIN_CUSTOMER,
      payload: { data, history, url },
    }
  }

  export const loginSuccess = user => {
    return {
      type: LOGIN_SUCCESS,
      payload: user,
    }
  }

  export const logoutUser = ( history, url) => {
    return {
      type: LOGOUT_USER,
      payload: { history, url },
    }
  }

  export const logoutCustomer = ( history, url) => {
    return {
      type: LOGOUT_CUSTOMER,
      payload: { history, url },
    }
  }

  export const logoutUserSuccess = () => {
    return {
      type: LOGOUT_USER_SUCCESS,
      payload: {},
    }
  }

  export const apiError = error => {
    return {
      type: API_ERROR,
      payload: error,
    }
  }

  export const userForgetPassword = (user, history) => {
    return {
      type: FORGET_PASSWORD,
      payload: { user, history },
    }
  }

  export const userForgetPasswordSuccess = message => {
    return {
      type: FORGET_PASSWORD_SUCCESS,
      payload: message,
    }
  }

  export const userForgetPasswordError = message => {
    return {
      type: FORGET_PASSWORD_ERROR,
      payload: message,
    }
  }
  export const getList = (data, history, url)  => ({
    type: GET_LIST,
    payload: {data, history, url },
  })

  export const getListSuccess =  ( data) => ({
    type: GET_LIST_SUCCESS,
    payload: { data },
  })

  export const getListFail = error => ({
    type: GET_LIST_FAIL,
    payload: error,
  })

  export const getData =  (data, history, url)  => ({
    type: GET_DATA,
    payload: { data, history, url },
  })

  export const getDataSuccess = (data)  => ({
    type: GET_DATA_SUCCESS,
    payload: {data},
  })

  export const getDataFail = error => ({
    type: GET_DATA_FAIL,
    payload: error,
  })

  export const addNewData =  (data, history, url) => ({
    type: ADD_NEW_DATA,
    payload: { data, history, url },
  })

  export const addDataSuccess =  (data) => ({
    type: ADD_DATA_SUCCESS,
    payload: data,
  })

  export const addDataFail = error => ({
    type: ADD_DATA_FAIL,
    payload: error,
  })

  export const updateData =  (data, history, url) => ({
    type: UPDATE_DATA,
    payload: { data, history, url },
  })

  export const updateDataSuccess =  data => ({
    type: UPDATE_DATA_SUCCESS,
    payload: data,
  })

  export const updateListDataSuccess =  data => ({
    type: UPDATE_LIST_DATA_SUCCESS,
    payload: data,
  })

  export const updateDataFail = error => ({
    type: UPDATE_DATA_FAIL,
    payload: error,
  })

  export const clearData = () => ({
    type: CLEAR_DATA,
    payload:"",
  });
