import React, { FC, useEffect, useState, useMemo,useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Datatable from "./Datatable";
import NcImage from "shared/NcImage/NcImage";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Select from 'react-select'
import withRouter from "components/Common/withRouter";
import imageRightJpg from "images/Injection-Accessories.jpg";
import Checkbox from "shared/Checkbox/Checkbox";
import SubCategory from "./SubCategory";
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData
} from "store/AppMaster/actions";
import api from "constants/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isSheet } from "components/Common/commonFunction";
import { RootState } from 'store/reducers';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  Card,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";

toastr.options = {
  positionClass: "toast-top-right",
  timeOut: 5000,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  showDuration: 300,
  hideDuration: 1000
};


export interface ViewProps {
  data: any;
  result: any[]
}

interface HeaderRow {
  // Define the properties of your header row here
  [key: string]: string;
}

interface BodyRow {
  // Define the properties of your body row here
  [key: string]: string;
}

interface FilterRow {
  // Define the properties of your body row here
  [key: string]: string;
}
interface FilterRowData {
  [key: string]: string;
}

interface Row {
  [key: string]: string | number | undefined | { url?: string } | null;
}

const View: FC<ViewProps> = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const initialRender = useRef(true);
  const [isApiCall, setIsApiCall] = useState(0);
  const [isCategoryData, setIsCategoryData] = useState(false);
  const [optionListData, setOptionListData] = useState(false);
  const [optionCategory, setOptionCategory] = useState<string[]>([]);
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const [catData, setData] = useState({
    id: "",
    name: "",
    image: "",
    SubCategory:[]
  });


  useEffect(() => {
      if (isApiCall === 0) {
          getCategoryDetails();
          setIsApiCall(1);
      }
      initialRender.current = false;

      if (isCategoryData == true && optionCategory.length === 0) {
          getCategoryListData();
          setOptionListData(false);
      }
  }, [isApiCall, isCategoryData, optionCategory]);

  const { data } = useSelector((state: RootState) => state.AppMaster);

  const getCategoryListData = () => {
      if (optionCategory.length == 0) {
          setOptionListData(true);
          dispatch(clearData())
          const filterData = {};
          dispatch(onGetList(filterData, "", api.categoryList));
      }
  }

  const getCategoryDetails = () => {
      setIsCategoryData(true);
      //dispatch(clearData())
      let filterData = {
          id: params.id
      }
      dispatch(onGetData(filterData, '', api.getCategoryDetail));
  }

  if (data != null && data.result != undefined && optionCategory.length == 0) {
      if (data.length != 0 && data.result != 0) {
          setOptionListData(false);
          const categoryList = data.result
          .filter((subject: any) => subject._id !== catData.id)
          .map((subject: any) => ({
              value: subject._id,
              label: subject.name,
          }));
          setOptionCategory(categoryList);
      }
  }

  if (data != null && isCategoryData == true) {
      if (data.categoryData != null) {
          setIsCategoryData(false);
          setData((preValue) => {
            return {
              ...preValue,
              id: data.categoryData._id,
              name: data.categoryData.name,
              image: data.categoryData.image_url,
              SubCategory: data.categoryData.subCategory??[],
            };
          });
      }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {

      category_id: "",
      Sub_category_id:""
    },
    validationSchema: Yup.object({

      category_id: Yup.mixed()
        .test('fileFormat', 'Price sheet must be an xlsx.', (value) => {
          if (!value) return true; // If no image provided, pass validation
          return isSheet(value);
        }).required('Price sheet is required')

    }),
    onSubmit: async (values: any) => {
      const newData = {

         category_id: "",
         Sub_category_id:""
      };
      dispatch(onAddNewData(newData, "", api.createCategory))
    },
  });

  return (
    <div className="container py-16 lg:pb-28 lg:pt-20">
      <Helmet>
        <title>Category Details</title>
      </Helmet>
      <div className="mb-12 sm:mb-16">
        <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">Category Details</h2>
      </div>
      <div className="mt-8 sm:mt-10 mb-8 sm:mb-10">
        <div className="listingSectionSidebar__wrap shadow-lg border-0 p-8 rounded-2xl mb-8 sm:mb-10">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-32 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
              <img
                className="h-full w-full object-contain object-center"
                src={catData && catData.image}
              />
            </div>
            <div className="ml-5 md:ml-6 flex flex-1 flex-col">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
                <div>
                  <h4 className="text-2xl font-semibold md:mb-1.5">{catData && catData.name}</h4>
                </div>
              </div>

            </div>
          </div>
          <SubCategory
            show={showModalQuickView}
            onCloseModalQuickView={() => setShowModalQuickView(false)}
            optionCategory={optionCategory}
            id={catData.id}
            SubCat={
              catData.SubCategory.map((subject: any) => ({
                value: subject._id,
                label: subject.name,
            }))}
            getCategoryDetails={() => getCategoryDetails()}
          />
          <ButtonPrimary className="mt-3 mt-md-0 !block ml-auto"
                 onClick={() => {
                  setShowModalQuickView(true)
                  }
                }
                >
                  <span>Add Sub Category</span>
          </ButtonPrimary>
          <div className="overflow-x-auto">
          <Paper>
            <Table aria-label="simple table" className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <TableHead className="bg-gray-800 border-b text-center font-normal !text-white">
                    <TableRow className="text-white h-10 font-normal">
                      <TableCell
                          align={"left"}
                          colSpan={1}
                          rowSpan={1}
                          className="!text-white border-solid border-l border-white"
                        >
                         Sub Category Name
                      </TableCell>
                      <TableCell
                          align={"left"}
                          colSpan={1}
                          rowSpan={1}
                          className="!text-white border-solid border-l border-white"
                        >
                          Image
                      </TableCell>
                      <TableCell
                          align={"left"}
                          colSpan={1}
                          rowSpan={1}
                          className="!text-white border-solid border-l border-white"
                        >
                          Status
                      </TableCell>
                    </TableRow>
              </TableHead>
              <TableBody>
                {catData.SubCategory?.length > 0 && catData.SubCategory.map((row:any, index) => (
                  <TableRow>
                    <TableCell align="left">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">
                      <Avatar placeholder="" src={row.image_url ?row.image_url :""} alt={row.name} size="md"
                        onPointerEnterCapture={() => {}} // Empty function to satisfy TypeScript
                        onPointerLeaveCapture={() => {}} // Empty function to satisfy TypeScript
                        />
                    </TableCell>
                    <TableCell align="left">
                        <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset  ${row.status == 'Active' ? "bg-green-50 text-green-600 ring-green-500/10" : "bg-red-50 text-red-600 ring-red-500/10"}`}
                          >{row.status}
                              </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(View);
