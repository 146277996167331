import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment, useEffect, useState, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import {
    clearData,
    getList as onGetList,
    updateData as onUpdateData,
    addNewData as onAddNewData,
    getData as onGetData,
} from "store/AppMaster/actions";
import api from "constants/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isImageJpgOrPng, isImageSizeValid, callMultipartFormDataApi } from "components/Common/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from 'store/reducers';
import toastr from "toastr";
import "toastr/build/toastr.min.css";


export interface CreateProps {
    inqueryData?: any;
    setShowEnqyuiryListModal?: any;
}

const ListFollowUpModal: FC<CreateProps> = ({
    inqueryData,
    setShowEnqyuiryListModal,
}) => {












    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white w-[60%] rounded-lg shadow-xl">

                {/* Header */}
                <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200">
                    <h2 className="text-lg font-semibold">Follow UP History</h2>
                    <button
                        className="text-gray-500 hover:text-gray-700 transition-all"
                        onClick={(e) => { setShowEnqyuiryListModal(false) }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                {/* Body */}
                <div className="p-4">
                    {inqueryData.follow_up.map((followUp: any, index: any) => (
                        <div key={`followup ${index}`} className="mb-4 border border-gray-300 rounded p-3">
                            <p className="text-gray-500">{new Date(followUp.timestamp).toLocaleString()}</p>
                            <p className="mt-1">{followUp.message}</p>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
};

export default ListFollowUpModal;


