import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import api from "../../../constants/api"
import logo from '../../../images/logo-new.svg'




const EnquiryPdfView = () => {
    const params = useParams();

    const [enquiry, setEnquiry] = useState<any>({})
    const [totalQty, setTotalQty] = useState(0)
    const getEnquiry = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL + api.inquiryDetail
            const headers = { 'Content-Type': 'application/json' }



            const response = await axios.post(apiUrl, { id: params.id }, { headers })
            setEnquiry(response.data.data.enquiry);
            const tempTotalQty = response.data.data.enquiry.cart_items.reduce((acc: any, item: any) => acc + item.quantity, 0);
            setTotalQty(tempTotalQty)
        } catch (error) {

        }
    }

    useEffect(() => {
        getEnquiry()
    }, [])

    return (
        <div className="container">
            <header>
                <h1 style={{ textAlign: 'center', marginTop: '30px', fontSize: '20px', marginBottom: '30px', }}><strong>QUOTATION CUM PRO-FORMA INVOICE</strong></h1>
            </header>
            <main>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <tbody style={{ verticalAlign: 'top' }}>
                        <tr>
                            <td rowSpan={2} style={{ width: '60%', border: '1px solid #000', padding: '8px', textAlign: 'left', }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                    <img src={logo} alt="Company Logo" />
                                    <div style={{ marginLeft: '20px' }}>
                                        <strong>Vijaydeep Mould Accessories Pvt.Ltd.</strong>
                                        <p style={{ marginTop: '5px', marginBottom: '0px' }}># 205, 2nd floor, T-Square Building, Chandivali<br />
                                            Junction, Saki Vihar Road, Saki Naka, Andheri-<br />
                                            East,Mumbai 400072 Maharashtra<br />
                                            Tel: 61077900 | E-Mail:sales@vijaydeep.in<br />
                                            GSTIN/UIN:27AACCV4475F1Z6 / LUT No: 9843/ LUT<br />
                                            Date: 15/03/204<br />
                                            <Link to="http://www.vijaydeep.in" target="_blank" rel="noopener noreferrer">www.vijaydeep.in</Link><br />
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td style={{ width: '20%', border: '1px solid #000', padding: '8px', textAlign: 'left', }}>
                                <p style={{ marginBottom: '0px', marginTop: '0px' }}>Quotation/PI No.:<br />
                                    TQ-2324-13022
                                </p>
                            </td>
                            <td style={{ width: '20%', border: '1px solid #000', padding: '8px', textAlign: 'left', }}>
                                <p className="mb-0 mt-0">Dated:27/03/24<br />
                                    Ticket No: 216918
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ width: '20%', border: '1px solid #000', padding: '8px', textAlign: 'left', }}>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>Buyers Ref./PO No.:</p>
                            </td>
                            <td style={{ width: '20%', border: '1px solid #000', padding: '8px', textAlign: 'left', }}>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>PO Date:</p>
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan={2} style={{ width: '60%', border: '1px solid #000', padding: '8px', textAlign: 'left', }}>
                                <span style={{ fontSize: '15px' }}>
                                    <strong style={{ marginRight: '2px' }}>Buyers :</strong>
                                    Select Controls Pvt. Ltd.</span>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>El-27/1, Ttc Industrial Area , Electronic Zone, M.I.D.C., Mahape,,Navi
                                    Mumbai 400710,<br />
                                    Maharashtra, India<br />
                                    GSTIN/UIN :27AABCS6266N1ZS</p>
                            </td>
                            <td style={{ width: '20%', border: '1px solid #000', padding: '8px', textAlign: 'left', }}>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>Dispatch through:</p>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>Courier - Surface</p>
                            </td>
                            <td style={{ width: '20%', border: '1px solid #000', padding: '8px', textAlign: 'left', }}>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>Courier/Transporter Name:</p>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>Anjani Courier</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ width: '20%', border: '1px solid #000', padding: '8px', textAlign: 'left', }}>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>E-Way Bill No.:</p>
                            </td>
                            <td style={{ width: '20%', border: '1px solid #000', padding: '8px', textAlign: 'left', }}>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>E-Way Bill Date & Time:</p>
                            </td>
                        </tr> <tr>
                            <td style={{ width: '60%', border: '1px solid #000', padding: '8px', textAlign: 'left', }}>
                                <span style={{ fontSize: '15px' }}><strong style={{ marginRight: '2px' }}>Consignee :</strong>Selec
                                    Controls Pvt. Ltd.</span>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>C119, MIDC PAWANE,Navi Mumbai 400710 ,Maharashtra, India
                                </p>
                            </td>
                            <td style={{ width: '20%', border: '1px solid #000', padding: '8px', textAlign: 'left', }}>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>Terms of Delivery</p>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>Ex. Work</p>
                            </td>
                            <td style={{ width: '20%', border: '1px solid #000', padding: '8px', textAlign: 'left', }}>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>Destination :</p>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>At your works/Door<br />
                                    Delivery</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ width: '60%', border: '1px solid #000', padding: '8px', textAlign: 'left', }}>
                                <span style={{ fontSize: '15px' }}><strong style={{ marginRight: '2px' }}>Kind
                                    Attention:</strong>Aniket Tikhe</span>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>Ph: 8369162461 E:
                                </p>
                            </td>
                            <td style={{ width: '20%', border: '1px solid #000', padding: '8px', textAlign: 'left', }}>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>Terms of Payment:</p>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>30 days credit</p>
                            </td>
                            <td style={{ width: '20%', border: '1px solid #000', padding: '8px', textAlign: 'left', }}>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>Enquiry Type & Date:</p>
                                <p style={{ marginBottom: '0px', marginTop: '0px', }}>Email | 27/03/24</p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/* Description of Goods table */}
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th style={{ width: '3%', textAlign: 'center', fontSize: '15px', borderWidth: '0px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', padding: '8px' }}>Sr No </th>
                            <th style={{ width: '42%', textAlign: 'center', fontSize: '15px', borderWidth: '0px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', padding: '8px' }}>Name</th>
                            <th style={{ borderWidth: '0px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', textAlign: 'center', fontSize: '15px', padding: '8px' }}>HSN/SAC</th>
                            <th style={{ width: '4%', borderWidth: '0px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', textAlign: 'center', fontSize: '15px', padding: '8px' }}>Lead Time</th>
                            <th style={{ width: '4%', borderWidth: '0px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', textAlign: 'center', fontSize: '15px', padding: '8px' }}>Qty.</th>
                            <th style={{ borderWidth: '0px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', textAlign: 'center', fontSize: '15px', padding: '8px' }}>Rate</th>
                            <th style={{ borderWidth: '0px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', textAlign: 'center', fontSize: '15px', padding: '8px' }}>Unit</th>
                            <th style={{ borderWidth: '0px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', textAlign: 'center', fontSize: '15px', padding: '8px' }}>Disc%</th>
                            <th style={{ width: '8%', borderWidth: '0px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', textAlign: 'center', fontSize: '15px', padding: '8px' }}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {enquiry && enquiry.cart_items?.map((item: any, index: number) => (
                            <tr key={`product ${index}`}>
                                <td style={{ textAlign: 'center', fontSize: '15px', border: '1px solid #000', padding: '8px' }}>{index + 1}</td>
                                <td style={{ textAlign: 'left', fontSize: '15px', border: '1px solid #000', padding: '8px' }}>{item.code}</td>
                                <td style={{ textAlign: 'center', fontSize: '15px', border: '1px solid #000', padding: '8px' }}>73181600</td>
                                <td style={{ textAlign: 'center', fontSize: '15px', border: '1px solid #000', padding: '8px' }}>1<br />Day</td>
                                <td style={{ textAlign: 'center', fontSize: '15px', border: '1px solid #000' }}>
                                    {item.quantity}
                                    <br />Pcs</td>
                                <td style={{ textAlign: 'center', fontSize: '15px', border: '1px solid #000', padding: '8px' }}>INR<br />
                                    {item.type == 'standard' ? item.row.price : item.row.economic_price}</td>
                                <td style={{ textAlign: 'center', fontSize: '15px', border: '1px solid #000', padding: '8px' }}>Pcs</td>
                                <td style={{ textAlign: 'center', fontSize: '15px', border: '1px solid #000', padding: '8px' }}>{item.type == 'standard' ? item.row.discount : item.row.economic_discount}%</td>
                                <td style={{ textAlign: 'center', fontSize: '15px', border: '1px solid #000', padding: '8px' }}>
                                    {item.type === 'standard' ? (
                                        // If item type is standard, calculate discount price based on item.price and item.discount
                                        <span>INR {(item.row.price - (item.row.price * (item.row.discount / 100))).toFixed(2)}</span>
                                    ) : item.type === 'economic' ? (
                                        // If item type is economic, calculate discount price based on item.economic_price and item.economic_discount
                                        <span>INR {(item.row.economic_price - (item.row.economic_price * (item.row.economic_discount / 100))).toFixed(2)}</span>
                                    ) : (
                                        // Handle other cases here
                                        // For example, display a default value or handle additional cases
                                        <span>N/A</span>
                                    )}
                                </td>


                            </tr>
                        ))}


                        <tr style={{ verticalAlign: 'baseline', borderBottom: '0px', borderRight: '1px solid #000', borderLeft: '1px solid #000' }}>
                            <td colSpan={2} style={{ textAlign: 'end', borderRight: '0px', padding: '8px' }}>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}> Sub - Total</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>DEL/COURIER/TRANSPORTATION CHARGES</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Insurance Amount</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>SGST</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>CGST</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Total Amount Without Round Off.</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Round Off</p>
                                <strong>Total Amount</strong>
                            </td>
                            <td style={{ borderRight: '0px', borderLeft: '0px', padding: '8px' }}></td>
                            <td style={{ borderRight: '0px', borderLeft: '0px', padding: '8px' }}></td>
                            <td style={{ borderRight: '0px', borderLeft: '0px', padding: '8px', textAlign: 'center' }}>
                                {totalQty}
                            </td>
                            <td colSpan={4} style={{ textAlign: 'end', borderLeft: '0px', padding: '8px' }}>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>{enquiry.total_value_after_discount}</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>INR 120.00</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>INR 0.00</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>INR 290.74</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>INR 290.74</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>INR 3811.88</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>INR 0.12</p>
                                <strong>NR 3812</strong>
                            </td>
                        </tr>
                        <tr style={{ borderTop: '0px', paddingTop: '0px', borderRight: '1px solid #000', borderLeft: '1px solid #000' }}>
                            <td colSpan={2} style={{ borderRight: '0px', textAlign: 'left', padding: '8px' }}>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Amount Chargeable(in words)</p>
                                <strong>INR Three Thousand Eight Hundred Twelve Only</strong>
                            </td>
                            <td colSpan={6} style={{ borderRight: '0px', textAlign: 'left', padding: '8px' }}></td>
                            <td style={{ borderLeft: '0px', padding: '8px' }}>
                                E.& OE
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/* Taxable-value table */}
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead style={{ verticalAlign: 'middle' }}>
                        <tr>
                            <th rowSpan={2} style={{ width: '4%', textAlign: 'center', fontSize: '15px', borderWidth: '1px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', padding: '8px' }}>Sr No </th>
                            <th rowSpan={2} style={{ width: '42%', textAlign: 'center', fontSize: '15px', borderWidth: '1px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', padding: '8px' }}>HSN/SAC</th>
                            <th rowSpan={2} style={{ width: '8%', textAlign: 'center', fontSize: '15px', borderWidth: '1px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', padding: '8px' }}>Taxable
                                Value</th>
                            <th colSpan={2} style={{ width: '12%', textAlign: 'center', fontSize: '15px', borderWidth: '1px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', padding: '8px' }}>SGST </th>
                            <th colSpan={2} style={{ width: '12%', textAlign: 'center', fontSize: '15px', borderWidth: '1px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', padding: '8px' }}>CGST</th>
                            <th rowSpan={2} style={{ width: '15%', textAlign: 'center', fontSize: '15px', borderWidth: '1px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', padding: '8px' }}>Total Tax Amount</th>
                        </tr>
                        <tr>
                            <th style={{ textAlign: 'center', fontSize: '15px', borderWidth: '1px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', padding: '8px' }}>Rate</th>
                            <th style={{ textAlign: 'center', fontSize: '15px', borderWidth: '1px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', padding: '8px' }}>Amount</th>
                            <th style={{ textAlign: 'center', fontSize: '15px', borderWidth: '1px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', padding: '8px' }}>Rate</th>
                            <th style={{ textAlign: 'center', fontSize: '15px', borderWidth: '1px 1px 1px 1px', borderStyle: 'solid', borderColor: '#000', padding: '8px' }}>Amount</th>
                        </tr>
                    </thead>
                    <tbody style={{ borderLeft: '1px solid', borderRight: '1px solid', borderBottom: '1px solid' }}>
                        <tr>
                            <td style={{ textAlign: 'center', padding: '8px' }}>1</td>
                            <td style={{ textAlign: 'center', padding: '8px' }}>73181600</td>
                            <td style={{ textAlign: 'center', padding: '8px' }}>INR 3110.40</td>
                            <td style={{ textAlign: 'end', padding: '8px' }}>9%</td>
                            <td style={{ textAlign: 'end', padding: '8px' }}>INR 279.94</td>
                            <td style={{ textAlign: 'end', padding: '8px' }}>9%</td>
                            <td style={{ textAlign: 'end', padding: '8px' }}>INR 279.94</td>
                            <td style={{ textAlign: 'end', padding: '8px' }}>INR 559.88</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'center', padding: '8px' }}>2</td>
                            <td style={{ textAlign: 'center', padding: '8px' }}>996812</td>
                            <td style={{ textAlign: 'center', padding: '8px' }}>INR 120.00</td>
                            <td style={{ textAlign: 'end', padding: '8px' }}>9%</td>
                            <td style={{ textAlign: 'end', padding: '8px' }}>INR 10.80</td>
                            <td style={{ textAlign: 'end', padding: '8px' }}>9%</td>
                            <td style={{ textAlign: 'end', padding: '8px' }}>INR 10.80</td>
                            <td style={{ textAlign: 'end', padding: '8px' }}>INR 21.60</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'center', padding: '8px' }}>3</td>
                            <td style={{ textAlign: 'center', padding: '8px' }}></td>
                            <td style={{ textAlign: 'center', padding: '8px' }}>INR 0.00</td>
                            <td style={{ textAlign: 'end', padding: '8px' }}>9%</td>
                            <td style={{ textAlign: 'end', padding: '8px' }}>INR 0.00</td>
                            <td style={{ textAlign: 'end', padding: '8px' }}>9%</td>
                            <td style={{ textAlign: 'end', padding: '8px' }}>INR 0.00</td>
                            <td style={{ textAlign: 'end', padding: '8px' }}>INR 0.00</td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ textAlign: 'end', padding: '8px' }}><strong>Total</strong></td>
                            <td style={{ textAlign: 'center', padding: '8px' }}>INR 3230.40</td>
                            <td colSpan={2} style={{ textAlign: 'end', padding: '8px' }}>INR 290.74 </td>
                            <td colSpan={2} style={{ textAlign: 'end', padding: '8px' }}>NR 290.74</td>
                            <td style={{ textAlign: 'end', padding: '8px' }}>INR 581.48</td>
                        </tr>
                        <tr style={{ borderTop: '0px', paddingTop: '0px', borderRight: '1px solid #000', borderLeft: '1px solid #000' }}>
                            <td colSpan={2} style={{ borderRight: '0px', textAlign: 'left', padding: '8px' }}>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Amount Chargeable(in words)</p>
                                <strong>INR Three Thousand Eight Hundred Twelve Only</strong>
                            </td>
                            <td colSpan={6} style={{ borderRight: '0px', textAlign: 'left', padding: '8px' }}></td>

                        </tr>
                    </tbody>
                </table>

                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <tbody style={{ height: '320px' }}>
                        <tr>
                            <td style={{ borderWidth: '0px 1px 0px 1px', borderStyle: 'solid', borderColor: '#000', padding: '8px' }}></td>
                        </tr>
                    </tbody>
                </table>

                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px' }}>
                    <tbody>
                        <tr>
                            <td style={{ verticalAlign: 'baseline', padding: '8px', border: '1px solid #000' }}>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Vijaydeep Team Member: Vinod S</p>
                                <p style={{ marginTop: '6px', marginBottom: '6px', borderBottom: '1px solid gray' }}></p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>TERMS & CONDITIONS :</p>
                            </td>
                            <td style={{ width: '40%', padding: '8px', border: '1px solid #000' }}>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Please deposit the payment in the below mentioned bank account and
                                    intimate to us
                                    immediately.</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}><strong style={{ marginRight: '3px' }}>Account Name:</strong>Vijaydeep Mould Accessories Pvt Ltd</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}><strong style={{ marginRight: '3px' }}>Bank Name:</strong>HDFC BANK</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}><strong style={{ marginRight: '3px' }}>BRANCH A/c.No.:</strong>00862560003293</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}><strong style={{ marginRight: '3px' }}>Branch Name:</strong>VISHAL HALL,ANDHERI(EAST)</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}><strong style={{ marginRight: '3px' }}>IFS Code:</strong>RTGS/NEFT - HDFC0000086</p>
                                <p style={{ marginTop: '0px', marginBottom: '0px' }}><strong style={{ marginRight: '3px' }}>Company`s PAN No.:</strong></p>
                                <p style={{ marginTop: '10px', marginBottom: '10px', borderBottom: '1px solid gray' }}></p>
                                <p style={{ textAlign: 'end', marginRight: '40px', marginTop: '20px' }}><strong>For Vijaydeep Mould Accessories Pvt.Ltd.</strong></p>
                                <p style={{ textAlign: 'end', marginRight: '40px', marginTop: '20px', marginBottom: '10px' }}>Authorised Signatory</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </main>
        </div>
    )
}

export default EnquiryPdfView