import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import { useLocation } from "react-router-dom";
import React, { FC, useEffect, useState,useMemo } from "react";




export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}
const Locationtype = () => {
  let location = useLocation();
  return location.pathname.includes("/admin") ? 'admin' : 'user'
}

const Footer: React.FC = () => {
  const [categoryDataFooter,setCategoryData] =useState([]);

  if(categoryDataFooter.length==0){
    setTimeout(() => {
      let listData:any = localStorage.getItem("categoryData")
      let transformedData = [];
      const categoryData = JSON.parse(listData);
      if(categoryData!=null){
        transformedData = categoryData.map((item:any, index: number) => ({
          href:"/category/"+item.slug,
          label: item.name
        }));
      }
      setCategoryData(transformedData);
    }, 1000);
  }

  const firstHalf = categoryDataFooter.slice(0, Math.ceil(categoryDataFooter.length / 2));
  const secondHalf = categoryDataFooter.slice(Math.ceil(categoryDataFooter.length / 2));

  const widgetMenus: WidgetFooterMenu[] = [
    {
      id: "5",
      title: "Company",
      menus: [
        { href: "/", label: "Home" },
        { href: "/about", label: "About Us" },
        { href: "/products", label: "Products" },
        { href: "/contact", label: "Contact Us" },
      ],
    },
    {
      id: "1",
      title: "Products",
      menus: firstHalf,
    },
    {
      id: "2",
      title: "",
      menus:secondHalf,
    },
    // {
    //   id: "2",
    //   title: "Resources",
    //   menus: [
    //     { href: "#", label: "Best practices" },
    //     { href: "#", label: "Support" },
    //     { href: "#", label: "Developers" },
    //     { href: "#", label: "Learn design" },
    //   ],
    // },
    {
      id: "4",
      title: "Corporate Office",
      menus: [
        { href: "#", label: "#205, 2nd Floor, T-Square, Chandivali Junction,Saki Vihar Road, Saki Naka,Andheri East, Mumbai – 400072. India." },
        { href: "#", label: "Tel: +91-22 6107 7900" },
        { href: "#", label: "E-mail : sales@vijaydeep.in" },
      ],
    },
  ];

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000  hover:text-secondary-50"
                href={item.href}
                rel="noopener noreferrer"
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div>
      {
        Locationtype() === 'admin' ? (
          <div className="nc-Footer relative py-5 lg:pt-5 lg:pb-5 border-t border-neutral-200 dark:border-neutral-700">
            <div className="container">
              <h1 className="text-slate-500">
              {new Date().getFullYear()} ©{" "}
                              {process.env.REACT_APP_NAME}
              </h1>
            </div>
          </div>
        ) : (
          <div className="nc-Footer relative py-20 lg:pt-28 lg:pb-24 border-t border-neutral-200 dark:border-neutral-700">
            <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
              <div className="grid grid-cols-3 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
                <div className="col-span-2 md:col-span-1">
                  <Logo />
                </div>
               {/*  <div className="col-span-2 flex items-center md:col-span-3">
                  <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
                </div> */}
              </div>
              {widgetMenus.map(renderWidgetMenuItem)}
            </div>
          </div>
        )
      }

    </div>


  );
};

export default Footer;
