import TableContainer from 'components/Common/TableContainer';
import React from 'react';
import { Link } from 'react-router-dom';

interface DatatableProps {
    children?: React.ReactNode;
    listData: any[];
    filterData: any;
    getListData: any;
    listCount: any;
    columns: any[];
}

const List: React.FC<DatatableProps> = ({ listData,filterData,getListData,listCount,columns }) => {

    return (
        <TableContainer
            columns={columns}
            data={(listData) ? listData : []}
            isGlobalFilter={true}
            noDataIndication={() => (<div className="text-center">No Data Found</div>)}
            filterData={filterData}
            getListData={getListData}
            listCount={listCount}
            className="table mb-0"
        />
    )
};

export default List;