import Label from "components/Label/Label";
import React, { Fragment, useState, useEffect } from "react";
import { useCart } from "context/CartContext";
import { Helmet } from "react-helmet-async";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  addNewData as onAddNewData,
} from "store/AppMaster/actions";
import api from "constants/api";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const CheckoutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartData, setCartData] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const { setCart, setTotalPrice, cart, totalPrice, updateCart, removeFromCart, increaseQuantity, decreaseQuantity, handleProductTypeSelection, showTotalPrice, totalDiscount } = useCart();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [localTotalPrice, setLocalTotalPrice] = useState(0)
  const [localTotalDiscount, setLocalTotalDiscount] = useState(0)

  const [authUserName, setAuthName] = useState("")
  const [authUserEmail, setAuthEmail] = useState("")
  const [selectedType, setSelectedType] = useState("")
  const [userDetails, setUserDetails] = useState([])
  const [productType, setProductType] = useState("pending")
  const [specialMessage, setSpecialMessage] = useState("")

  const [frmData, setFrmData] = useState({
    name: authUserName,
    email: authUserEmail,
    mobile: "",
    designation: "",
    company: "",
    address: "",
    state: "",
    country: "",
    pincode: "",
    subject: "",
    message: "",
    cart_items: cartData,
    status: "Unread"
  });

  // useEffect(() => {
  //   const cartList = localStorage.getItem('cart');
  //   setCartData(cartList ? JSON.parse(cartList) : []);
  //   const price = localStorage.getItem('Total Price')
  //   // @ts-ignore
  //   setLocalTotalPrice(price)
  //   // setCartCount(cartList ? JSON.parse(cartList).length : 0);
  // }, []);
  useEffect(() => {
    const cartList = localStorage.getItem('cart');

    setCartCount(cartList ? JSON.parse(cartList).length : 0);

    setCartData(cartList ? JSON.parse(cartList) : []);
    let price = localStorage.getItem('Total Price')
    // @ts-ignore
    price = price * 1
    // @ts-ignore
    setLocalTotalPrice(price)
    let discount = localStorage.getItem('Total Discount')
    // @ts-ignore
    discount= discount * 1
    // @ts-ignore
    setLocalTotalDiscount(discount);
    // setCartCount(cartList ? JSON.parse(cartList).length : 0);
    if (localStorage.getItem("AuthCustomerDetails")) {
      const authUserDetails = JSON.parse(localStorage.getItem("AuthCustomerDetails") || "{}");
      setAuthName(authUserDetails.first_name + ' ' + authUserDetails.last_name);
      setUserDetails(authUserDetails);
      setAuthEmail(authUserDetails.email);
      setFrmData((prev) => ({ ...prev,
        name: authUserDetails.first_name+' '+authUserDetails.last_name,
        email: authUserDetails.email,
        mobile: authUserDetails.mobile,
        designation: authUserDetails.designation,
        company: authUserDetails.company_name,
        address: authUserDetails.company_address,
        state: authUserDetails.state,
        country: authUserDetails.country,
        pincode: authUserDetails.pincode
      }))
      setSpecialMessage(authUserDetails.special_message)
    }

  }, [totalPrice]);


  const onChangeHandler = (event: any) => {
    const { name, value } = event.target;
    setFrmData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  }


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: (frmData && frmData.name) || "",
      email: (frmData && frmData.email) || "",
      mobile: (frmData && frmData.mobile) || "",
      designation: (frmData && frmData.designation) || "",
      company: (frmData && frmData.company) || "",
      address: (frmData && frmData.address) || "",
      state: (frmData && frmData.state) || "",
      country: (frmData && frmData.country) || "",
      pincode: (frmData && frmData.pincode) || "",
      subject: (frmData && frmData.subject) || "",
      message: (frmData && frmData.message) || "",
      cart_items: (frmData && frmData.cart_items) || "",
      status: (frmData && frmData.status) || "",
      total_value: totalPrice ? totalPrice : localTotalPrice,
      total_value_after_discount: (totalPrice ? totalPrice : localTotalPrice) - (totalDiscount ? totalDiscount : localTotalDiscount)
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name").matches(
        /^[A-Za-z ]+$/i,
        "Must be a valid Name"
      ).max(50, "Must be less than  50 characters"),
      email: Yup.string().required("Please Enter Email").matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Must be a valid Email"
      ),
      mobile: Yup.string().required("Please Enter Mobile").matches(
        /^[+0-9]+$/i,
        "Please Enter Valid Mobile"
      ).min(9, "Please Enter Valid Mobile").max(12, "Please Enter Valid Mobile"),
      message: Yup.string().required("Please Enter Message")
    }),
    onSubmit: values => {
      const newData = {
        name: values["name"],
        email: values["email"],
        mobile: values["mobile"],
        designation: values["designation"],
        company: values["company"],
        address: values["address"],
        state: values["state"],
        country: values["country"],
        pincode: values["pincode"],
        subject: values["subject"],
        message: values["message"],
        cart_items: localStorage.getItem('cart'),
        status: values["status"],
        type: selectedType,
        total_value: totalPrice ? totalPrice : localTotalPrice,
        total_value_after_discount: (totalPrice ? totalPrice : localTotalPrice) - (totalDiscount ? totalDiscount : localTotalDiscount)
      };
      dispatch(onAddNewData(newData, "", api.frontEnquiry))
      localStorage.setItem('cart', "");
      localStorage.setItem('Total Price', "");
      setCart([]);
      setTotalPrice(0);
      updateCart();
      // window.location.reload();
      setShowSuccessPopup(true)
    },
  });

  const handleCheckoutClick = (e: any) => {
    const { name } = e.target;
    setSelectedType(name)
    if(cartCount>0){
      validation.handleSubmit();
    }else{
      navigate('/checkout')
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if(cartCount>0){
      validation.handleSubmit();
    }else{
      navigate('/checkout')
    }
  }

  const renderProduct = (item: any, index: number) => {
    const { code, quantity, image, id, row, slug, type, econ_quantity } = item;
    // console.log(row.economic_code);

    return (
      <>
        {(type == 'standard' || type == 'pending') &&
          <div key={index} className={`relative flex p-5 items-center sm:items-start ${type === 'pending' ? 'bg-sky-100' : ''}`}>
            <div className="relative h-28 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
              <img
                src={image}
                alt={code}
                className="h-full w-full object-contain object-center"
              />
              <Link to={`/${slug}`} className="absolute inset-0"></Link>
            </div>

            <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
              <div>
                <div className="flex justify-between ">
                  <div className="flex-[1.5] ">
                    <h3 className="text-base font-semibold">
                      <Link to={`/${slug}`}>{code}</Link>
                    </h3>

                  </div>

                  <div className="flex-1 flex justify-end">
                    <Link
                      to=""
                      onClick={(e) => removeFromCart(e, id, row, code,'standard')}
                      className="relative z-10 font-medium text-primary-6000 hover:text-primary-500 text-sm "
                    >
                      Remove
                    </Link>

                  </div>
                </div>
              </div>

              <div className="flex justify-between mt-auto pt-4  text-sm">
                <h3 className="text-base font-medium ">
                  Price : ₹ {row.price ? row.price * quantity : '0'}
                </h3>
                <div className="hidden sm:block text-center relative">
                  <p className="text-gray-500 dark:text-slate-400">{`Qty ${quantity}`}</p>
                  {/* <NcInputNumber className="relative z-10" /> */}
                </div>
              </div>
              <div className="flex justify-between mt-auto pt-2  text-sm">
                <h3 className="text-base font-medium ">
                  Discount : {row.discount ? row.discount : '0'}%
                </h3>
                <div className="hidden sm:block text-center relative">
                  <span className="cursor-pointer" onClick={(e) => increaseQuantity(e, id, row, code,'standard')}><AddCircleOutlineIcon /></span>
                  <span>|</span>
                  <span className="cursor-pointer" onClick={(e) => decreaseQuantity(e, id, row, code,'standard')}><RemoveCircleOutlineIcon /></span>
                </div>
              </div>
              {type == 'pending' &&
              <div className="flex justify-between mt-6 pt-2  text-sm">
                <h3 className="text-base font-medium "></h3>
                <div className="hidden sm:block text-center relative">
                  <span>Option 1</span>
                </div>
              </div>}
            </div>


          </div>
        }
        {(type == 'pending' || type == 'economic') &&
          <>
            <div className={`relative flex p-5 items-center sm:items-start ${type === 'pending' ? 'bg-sky-100' : ''}`}>
              <div className="relative h-28 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
                <img
                  src={image}
                  alt={code}
                  className="h-full w-full object-contain object-center"
                />
                <Link to={`/${slug}`} className="absolute inset-0"></Link>
              </div>

              <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
                <div>
                  <div className="flex justify-between ">



                    <div className="flex-[1.5] ">
                      <h3 className="text-base font-semibold">
                        <Link to={`/${slug}`}>{row.economic_code}</Link>
                      </h3>

                    </div>

                    <div className="flex-1 flex justify-end">
                        <Link
                          to=""
                          onClick={(e) => removeFromCart(e, id, row, code,'economic')}
                          className="relative z-10 font-medium text-primary-6000 hover:text-primary-500 text-sm "
                        >
                          Remove
                        </Link>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between mt-auto pt-4  text-sm">
                  <h3 className="text-base font-medium ">
                    Price : ₹ {row.economic_price ? row.economic_price * econ_quantity : '0'}
                  </h3>
                  <div className="hidden sm:block text-center relative">
                    <p className="text-gray-500 dark:text-slate-400">{`Qty ${econ_quantity}`}</p>
                    {/* <NcInputNumber className="relative z-10" /> */}
                  </div>
                </div>
                <div className="flex justify-between mt-auto pt-2  text-sm">
                    <h3 className="text-base font-medium ">
                      Discount : {row.economic_discount ? row.economic_discount : '0'}%
                    </h3>
                    <div className="flex justify-end mt-1">
                      <span className="cursor-pointer" onClick={(e) => increaseQuantity(e, id, row, code,'economic')}><AddCircleOutlineIcon /></span>
                      <span>|</span>
                      <span className="cursor-pointer" onClick={(e) => decreaseQuantity(e, id, row, code,'economic')}><RemoveCircleOutlineIcon /></span>
                    </div>
                </div>
                {type == 'pending' &&
                <div className="flex justify-between mt-6 pt-2  text-sm">
                  <h3 className="text-base font-medium "></h3>
                  <div className="hidden sm:block text-center relative">
                    <span>Option 2</span>
                  </div>
                </div>}
              </div>
            </div>
          {/*   {type == 'pending' &&
              <form className="bg-green-700 text-white p-4 rounded-2xl"
                onSubmit={() => { handleProductTypeSelection(productType, id, code) }}
              >
                <h5>Pick one of the above</h5>

                <div className="flex items-center">
                  <input
                    type="radio"
                    id="standard"
                    name="choice"
                    value="standard"
                    className="mr-2"
                    onChange={(e) => { setProductType(e.target.value) }}
                  />
                  <label htmlFor="standard" className="mr-4">Standard</label>
                  <input
                    type="radio"
                    id="economic"
                    name="choice"
                    value="economic"
                    className="mr-2"
                    onChange={(e) => { setProductType(e.target.value) }}
                  />
                  <label htmlFor="economic">Economic</label>
                  <button type='submit' className="bg-white text-green-700 p-2 rounded-md ml-8">Confirm</button>
                </div>
              </form>
            } */}

          </>
        }
      </>
    );
  };

  const renderLeft = () => {
    return (
      <div className="space-y-8">
        {specialMessage &&
          <div className="bg-yellow-200 text-yellow-800 p-4 rounded-md border border-yellow-400">
            {specialMessage}
          </div>}


        <div id="ShippingAddress" className="scroll-mt-24">
          <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
            <div
              className={`border-0 border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 block
            }`}
            >
              {/* ============ */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                <div>
                  <Label className="text-sm">Name<span className="text-rose-600">*</span></Label>
                  <Input className="mt-1.5" placeholder="Name" name="name"
                    onChange={onChangeHandler} value={frmData.name} />
                  {validation.errors.name ? (
                    <small className="text-rose-600">{validation.errors.name}</small>
                  ) : null}
                </div>
                <div>
                  <Label className="text-sm">Email<span className="text-rose-600">*</span></Label>
                  <Input className="mt-1.5" type="email" name="email" placeholder="Email" onChange={onChangeHandler} value={frmData.email} />
                  {validation.errors.email ? (
                    <small className="text-rose-600">{validation.errors.email}</small>
                  ) : null}
                </div>
              </div>
              {/* ---- */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                <div>
                  <Label>Phone number<span className="text-rose-600">*</span></Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-slate-100 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl las la-phone-volume"></i>
                    </span>
                    <Input
                      name="mobile"
                      className="!rounded-l-none"
                      placeholder="003 888 232"
                      onChange={onChangeHandler}
                      value={frmData.mobile}
                    />
                  </div>
                  {validation.errors.mobile ? (
                    <small className="text-rose-600">{validation.errors.mobile}</small>
                  ) : null}
                </div>
                <div>
                  <Label className="text-sm">Designation</Label>
                  <Input className="mt-1.5" name="designation" type="text" placeholder="Designation"
                    onChange={onChangeHandler} value={frmData.designation}
                  />
                </div>
              </div>
              {/* ---- */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                <div>
                  <Label className="text-sm">Company</Label>
                  <Input className="mt-1.5" name="company" type="text" placeholder="Company"
                    onChange={onChangeHandler}
                    value={frmData.company}
                  />
                </div>
                <div>
                  <Label className="text-sm">Address</Label>
                  <Input className="mt-1.5" name="address" type="text" placeholder="Address"
                    onChange={onChangeHandler}
                    value={frmData.address}
                  />
                </div>
              </div>
              {/* ---- */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                <div>
                  <Label className="text-sm">State</Label>
                  <Input className="mt-1.5" name="state" type="text" placeholder="State"
                    onChange={onChangeHandler}
                    value={frmData.state}
                  />
                </div>
                <div>
                  <Label className="text-sm">Country</Label>
                  <Input className="mt-1.5" name="country" type="text" placeholder="Country"
                    onChange={onChangeHandler}
                    value={frmData.country}
                  />
                </div>
              </div>
              {/* ============ */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                <div>
                  <Label className="text-sm">Pincode</Label>
                  <Input className="mt-1.5" name="pincode" type="Number" placeholder="Pincode"
                    onChange={onChangeHandler}
                    value={frmData.pincode}
                  />
                </div>
                <div>
                  <Label className="text-sm">Subject</Label>
                  <Input className="mt-1.5" name="subject" type="text" placeholder="Subject"
                    onChange={onChangeHandler}
                    value={frmData.subject}
                  />
                </div>
              </div>
              {/* ============ */}
              <div className="grid grid-cols-1">
                <label className="block">
                  <Label>Message<span className="text-rose-600">*</span></Label>

                  <Textarea className="mt-1" name="message" placeholder="Comment" rows={6}
                    onChange={onChangeHandler}
                    value={frmData.message}
                  />
                  {validation.errors.message ? (
                    <small className="text-rose-600">{validation.errors.message}</small>
                  ) : null}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCartFrom = () => {
    return (
      <div className="flex flex-col lg:flex-row">
        {showSuccessPopup ? (
          <div className="container mx-auto mt-10 p-6 bg-gray-100 rounded-lg shadow-md text-center">
            <div className="flex flex-col items-center justify-center mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-10 h-10 inline-block align-middle mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
              </svg>
              <h2 className="text-black-600 text-2xl font-bold">Success! Our executive will contact you.</h2>
            </div>
            <button className="inline-block px-4 py-2 bg-black text-white rounded-md text-center font-semibold text-lg hover:bg-blue-600 transition duration-300" onClick={(e) => { e.preventDefault(); navigate('/'); }}>Go to Home Page</button>
          </div>
        ) : cartCount > 0 ? (
          <>
            <form onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
              return false;
            }} method="post">
              <div className="flex-1">{renderLeft()}</div>
              {showTotalPrice && (
                <div className="flex items-center justify-end gap-3 mt-5">
                  <button type="submit" name="confirm" className="bg-black text-white rounded-full px-4 py-2 hover:border-black hover:border-2 focus:outline-none focus:border-none focus:ring-2 focus:ring-black" onClick={handleCheckoutClick}>
                    Confirm
                  </button>
                  <button type="submit" name='requestDiscount' className="bg-black text-white rounded-full px-4 py-2 hover:border-black hover:border-2 focus:outline-none focus:border-none focus:ring-2 focus:ring-black" onClick={handleCheckoutClick}>
                    Request Discount
                  </button>
                </div>
              )}
            </form>
            <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>
            <div className="w-full lg:w-[36%]">
              <h3 className="text-lg font-semibold">Order summary</h3>
              <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700">
                {cart ? cart.map(renderProduct) : (cartData ? cartData.map(renderProduct) : '')}
              </div>
              {showTotalPrice && (
                <div className="mt-10 pt-6 text-sm border-t border-slate-200/70">
                  <div className="bg-slate-100 dark:bg-slate-900 p-5">
                    <div className="flex justify-between items-center mt-5">
                      <h3 className="text-base font-medium">Price</h3>
                      <h3 className="text-base font-medium text-right">₹ {totalPrice || localTotalPrice}</h3>
                    </div>
                    <div className="flex justify-between items-center mt-5">
                      <h3 className="text-base font-medium">Discount</h3>
                      <h3 className="text-base font-medium text-right">₹ {((totalPrice || localTotalPrice) > 0 ? (totalDiscount || localTotalDiscount) : 0).toFixed(2)}</h3>
                    </div>
                    <div className="flex justify-between items-center mt-5">
                      <h3 className="text-base font-medium">Total Price</h3>
                      <h3 className="text-base font-medium text-right">
                      ₹ {Math.max((totalPrice || localTotalPrice) - (totalDiscount || localTotalDiscount), 0).toFixed(2)}
                      </h3>
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-2 pt-2 text-sm border-t border-slate-200/70">
                <div className="bg-slate-100 dark:bg-slate-900 p-5">
                  <div className="flex justify-between items-center">
                    <h3 className="text-base font-medium">GST + Freight Charges Extra in Cart</h3>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div style={{
            backgroundColor: 'white',
            color: 'black',
            padding: '20px',
            borderRadius: '8px',
            textAlign: 'center',
            maxWidth: '400px',
            margin: '30px auto',
            border: '1px solid #ccc',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
          }}>
            <h1 style={{ marginBottom: '20px', fontWeight: 'bold' }}>Your cart is empty!</h1>
            <div className="pt-8">
              <button className="bg-black text-white rounded-full px-4 py-2 hover:bg-blue-600 transition duration-300" onClick={() => navigate('/products')}>
                Shop now
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
      <div className="nc-CheckoutPage">
        <Helmet>
          <title>Checkout | Vijaydeep</title>
        </Helmet>

        <main className="container py-16 lg:pb-28 lg:pt-20 ">
          <div className="mb-16">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
              Checkout
            </h2>
            <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
              <Link to={"/"} className="">
                Homepage
              </Link>
              <span className="text-xs mx-1 sm:mx-1.5">/</span>
              <span className="underline">Checkout</span>
            </div>
          </div>
          {renderCartFrom()}
        </main>
      </div>
  );
};

export default CheckoutPage;
