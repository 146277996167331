import React from "react";

interface ProductTableProps {
    product_data_headers: any[];
    product_data_rows: any[]; // Array of data rows
}

const ProductTable: React.FC<ProductTableProps> = ({ product_data_headers, product_data_rows }) => {

    // console.log(product_data_headers, product_data_rows);

    return (
        <div className="overflow-y-auto whitespace-nowrap mt-4">
            <table className="border border-collapse border-gray-300 data-table">
                <thead>
                    <tr>
                        {product_data_headers.map((header, index) => (
                            <th key={index} className="border border-gray-800 px-4 py-2 text-left">{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {product_data_rows.map((row, rowIndex) => (
                        <tr key={rowIndex} className="border border-gray-300">
                            {Object.values(row).map((cellValue: any, cellIndex: any) => (
                                <td key={cellIndex} className="border border-gray-800 px-4 py-2">{cellValue}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

        </div>
    );
};

export default ProductTable;
