import React, { FC, useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Datatable from "./Datatable";
import NcImage from "shared/NcImage/NcImage";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import withRouter from "components/Common/withRouter";
import imageRightJpg from "images/Injection-Accessories.jpg";
import Checkbox from "shared/Checkbox/Checkbox";
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData
} from "store/AppMaster/actions";
import api from "constants/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isSheet } from "components/Common/commonFunction";

import { RootState } from 'store/reducers';
import UploadImage from "./UploadImage";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import UpdatePriceModal from "./UpdatePriceModal";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
toastr.options = {
  positionClass: "toast-top-right",
  timeOut: 5000,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  showDuration: 300,
  hideDuration: 1000
};


export interface ViewProps {
  data: any;
  result: any[]
}

interface HeaderRow {
  // Define the properties of your header row here
  [key: string]: string;
}

interface BodyRow {
  // Define the properties of your body row here
  [key: string]: string;
}

interface FilterRow {
  // Define the properties of your body row here
  [key: string]: string;
}
interface FilterRowData {
  [key: string]: string;
}

interface Row {
  [key: string]: string | number | undefined | { url?: string } | null;
}

const View: FC<ViewProps> = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [isApiCall, setIsApiCall] = useState(0);
  const [categoryData, setData] = useState([]);
  const [headerRows, setHeaderData] = useState<HeaderRow[]>([]);
  const [bodyRows, setBodyData] = useState<BodyRow[]>([]);
  const [filterRows, setFilterData] = useState<FilterRow[]>([]);
  const [groupData, setGroupData] = useState(false);
  const [filterRowState, setFilterRowState] = useState<FilterRowData[]>([]);
  const [showUpdatePriceModal, setShowUpdatePriceModal] = useState(false);
  const [bulkPriceUpdateSheet, setBulkPriceUpdateSheet] = useState('');
  const [bulkThreeDFile, setBulkThreeDFile] = useState('');
  const [indexData, setIndex] = useState(0);
  const [isFilter, setFilter] = useState(false);
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const [productData, setProductData] = useState({
    id: "",
    title: "",
    sub_title: "",
    meta_title: "",
    meta_desc: "",
    raw_material: "",
    case_hardness: "",
    note: "",
    category: "",
    sub_category: "",
    data_sheet: "",
    data_sheet_url: "",
    product_sheet: "",
    product_sheet_url: "",
    line_drawing: "",
    line_drawing_url: "",
    image: "",
    image_url: "",
    filter_option: ""
  });
  const [updatePriceProduct, setUpdatePriceProduct] = useState({
    codeDetails: {},
    productId: '',
    productPrice: '',
    productDiscount: '',
    economicCode: '',
    economicPrice: '',
    economicDiscount: '',
  })

  useEffect(() => {
    if (isApiCall == 0) {
      getGroupDetails();
      setIsApiCall(1);
      // alert('asdasfd')
    }
  }, [isApiCall]);

  const { data } = useSelector((state: RootState) => state.AppMaster);

  const getGroupDetails = () => {
    setGroupData(true);
    let filterData = {
      id: params.id
    }
    dispatch(onGetData(filterData, '', api.productGroupDetail));
  }

  if (data.productGroup != null && groupData == true) {
    setGroupData(false);
    // console.log(data);
    if (data.productGroup.product_header_data != null) {
      setHeaderData(data.productGroup.product_header_data)
    }
    if (data.productGroup.product_data != null) {
      setBodyData(data.productGroup.product_data)
    }

    if (data.productGroup.filter_option && data.productGroup.filter_option.length > 0) {
      data.productGroup.filter_option.map((row: any) => {
        setFilterRowState((prevState: FilterRow[]) => [
          ...prevState,
          row,
        ]);
      })
    }
    setProductData((preValue) => {
      return {
        ...preValue,
        id: data.productGroup._id,
        title: data.productGroup.title,
        sub_title: data.productGroup.sub_title,
        meta_title: data.productGroup.meta_title,
        meta_desc: data.productGroup.meta_desc,
        raw_material: data.productGroup.raw_material,
        case_hardness: data.productGroup.case_hardness,
        note: data.productGroup.note,
        category: data.productGroup.category,
        sub_category: data.productGroup.sub_category,
        filter_option: data.productGroup.filter_option,
        data_sheet_url: (data.productGroup.data_sheet?.url) || "",
        product_sheet_url: (data.productGroup.product_sheet?.url) || "",
        line_drawing_url: (data.productGroup.line_drawing?.url) || "",
        image_url: (data.productGroup.image?.url) || "",
      };
    });
  }

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  const calculateColSpan = (currentLetters: string[], cellIndex: number) => {
    const cellLetter = currentLetters[cellIndex];
    let count = 1;
    for (let i = alphabet.indexOf(cellLetter) + 1; i < alphabet.length; i++) {
      const currentLetter = alphabet[i];
      if (!currentLetters.includes(currentLetter)) {
        count++;
      } else {
        break;
      }
    }
    return count;
  };

  const calculateRowSpan = (index: number, letter: string) => {
    let count = 1;
    for (let i = index + 1; i < headerRows.length; i++) {
      if (Object.keys(headerRows[i]).includes(letter)) {
        break;
      }
      count++;
    }
    return count;
  };

  const handleUploadImageClick = (arg: any) => {
    setIndex(arg);
    setShowModalQuickView(true)
  };

  // Extract all keys from the product header data
  let allKeys: any = [];

  if (headerRows?.length > 0) {
    allKeys = headerRows.flatMap((obj) => Object.keys(obj));
  }

  if (headerRows?.length === 0 && bodyRows.length > 0) {
    // Use keys from the first row of bodyRows if headerRows is empty
    const firstRowKeys = Object.keys(bodyRows[0]);
    allKeys = firstRowKeys;
  }

  // Count the occurrences of each key
  const keyOccurrences = allKeys.reduce((count: any, key: any) => {
    count[key] = (count[key] || 0) + 1;
    return count;
  }, {});

  // Create an array of key-value pairs for unique keys
  let keyValuePairsArray: any = {}
  if (headerRows?.length > 0) {
    keyValuePairsArray = Object.keys(keyOccurrences)
      // .filter((key) => keyOccurrences[key] === 1)
      .map((uniqueKey) => {
        const values = headerRows
          .filter((obj) => obj.hasOwnProperty(uniqueKey))
          .map((obj) => obj[uniqueKey]);

        return {
          key: uniqueKey,
          value: values.length > 0 ? values.join(", ") : "", // Convert array to a comma-separated string
        };
      });
  }

  if (headerRows?.length === 0 && bodyRows.length > 0) {
    keyValuePairsArray = Object.keys(keyOccurrences)
      .filter((key) => keyOccurrences[key] === 1)
      .map((uniqueKey) => {
        const values = headerRows
          .concat(bodyRows[0])
          .filter((obj) => obj.hasOwnProperty(uniqueKey))
          .map((obj) => obj[uniqueKey]);

        return {
          key: uniqueKey,
          value: values.length > 0 ? values.join(", ") : "", // Convert array to a comma-separated string
        };
      });
  }




  if (keyValuePairsArray.length > 0 && isFilter == false) {
    setFilter(true);
    setFilterData(keyValuePairsArray);
  }

  const handleChangeCategories = (checked: boolean, item: any) => {
    if (checked) {
      setFilterRowState((prevState: FilterRow[]) => [
        ...prevState,
        item,
      ]);
    } else {
      setFilterRowState((prevState: FilterRow[]) =>
        prevState.filter((rowItem) => rowItem.key !== item.key)
      );
    }
  };

  const updateFilterOption = () => {
    const updateData = {
      filter_option: filterRowState,
      id: params.id,
    };
    dispatch(onUpdateData(updateData, '', api.productGroupUpdateFilter))
  }

  const handleSheetChange = (event: any) => {
    const file = event.target.files[0];
    setBulkPriceUpdateSheet(file);
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {

      bulkPriceUpdateSheet: bulkPriceUpdateSheet || "",
      productId: productData.id || "",

    },
    validationSchema: Yup.object({

      bulkPriceUpdateSheet: Yup.mixed()
        .test('fileFormat', 'Price sheet must be an xlsx.', (value) => {
          if (!value) return true; // If no image provided, pass validation
          return isSheet(value);
        }).required('Price sheet is required')

    }),
    onSubmit: async (values: any) => {
      const newData = {

        bulkPriceUpdateSheet: bulkPriceUpdateSheet,
        productId: productData.id,
      };
      //  dispatch(onAddNewData(newData, "", api.createCategory))
      const url = process.env.REACT_APP_API_URL + api.productGroupBulkPriceUpdate
      // callMultipartFormDataApi(url, newData)
      // navigate("/admin/products");
      const headers = {
        'Content-Type': 'multipart/form-data',
        // @ts-ignore
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("AuthUser"))}`
      };
      const options = { headers: headers }
      try {
        const response = await axios.post(url, newData, options);

        if (response.data.statusCode === 200) {
          toastr.success(response.data.message);
          // getGroupDetails();
          // setIsApiCall(0)
          window.location.reload();


        } else {
          toastr.error(response.data.message);
        }

        return response.data.statusCode;
      } catch (error) {
        console.error('API Error:', error);
        toastr.error('An error occurred while processing your request');
        throw error; // Rethrow the error
      }
    },
  });


  const handleThreeDSheetChange = (e: any) => {
    const file = e.target.files[0];
    setBulkThreeDFile(file);
  }
  const validationTwo = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {

      bulkThreeDFile: bulkThreeDFile || "",
      productId: productData.id || "",

    },
    validationSchema: Yup.object({

      bulkThreeDFile: Yup.mixed()
        .test('fileFormat', 'Selected File must be an xlsx.', (value) => {
          if (!value) return true; // If no image provided, pass validation
          return isSheet(value);
        }).required('File Sheet is required')

    }),
    onSubmit: async (values: any) => {
      const newData = {

        bulkThreeDFile: bulkThreeDFile,
        productId: productData.id,
      };
      //  dispatch(onAddNewData(newData, "", api.createCategory))
      const url = process.env.REACT_APP_API_URL + api.productGroupBulkThreeDUpdate
      // callMultipartFormDataApi(url, newData)
      // navigate("/admin/products");
      const headers = {
        'Content-Type': 'multipart/form-data',
        // @ts-ignore
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("AuthUser"))}`
      };
      const options = { headers: headers }
      try {
        const response = await axios.post(url, newData, options);

        if (response.data.statusCode === 200) {
          toastr.success(response.data.message);
          // getGroupDetails();
          // setIsApiCall(0)
          window.location.reload();

        } else {
          toastr.error(response.data.message);
        }

        return response.data.statusCode;
      } catch (error) {
        console.error('API Error:', error);
        toastr.error('An error occurred while processing your request');
        throw error; // Rethrow the error
      }
    },
  });
  return (
    <div className="container py-16 lg:pb-28 lg:pt-20">
      <Helmet>
        <title>Product Details</title>
      </Helmet>
      <div className="mb-12 sm:mb-16">
        <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">Product Details</h2>
      </div>
      <div className="mt-8 sm:mt-10 mb-8 sm:mb-10">
        <div className="listingSectionSidebar__wrap shadow-lg border-0 p-8 rounded-2xl mb-8 sm:mb-10">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-32 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
              <img
                className="h-full w-full object-contain object-center"
                src={imageRightJpg}
              />
            </div>
            <div className="ml-5 md:ml-6 flex flex-1 flex-col">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div>
                  <h4 className="text-2xl font-semibold md:mb-1.5">{productData && productData.title}</h4>
                  <span className="text-sm text-slate-700">{productData && productData.sub_title}</span>
                </div>
                <div>
                  <h4 className="text-1xl sm:text-2xl font-semibold md:mb-1.5">{Array.isArray(productData.category) && productData.category.length > 0
                    ? productData.category[0].name
                    : productData.category}</h4>
                </div>

              </div>

            </div>

          </div>
          <div className="block lg:flex items-center gap-12">
            <div className="flex-1">
              <form className="block sm:flex items-center" onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                // console.log(updatePriceProduct);

                return false;
              }}>
                <div>

                  <Label>Upload Product Price & Discount Sheet</Label>
                  <Input
                    type="file"
                    name="bulkPriceUpdateSheet"
                    placeholder=""
                    className="mt-0 mt-md-1 !px-1"
                    onChange={handleSheetChange}
                  />
                  {validation.errors.bulkPriceUpdateSheet ? (
                    // @ts-ignore
                    <small className="text-rose-600">{validation.errors.bulkPriceUpdateSheet}</small>
                  ) : null}
                </div>
                <ButtonPrimary className="mt-2 mt-md-0 !block ml-auto" type="submit">
                  <span>Submit</span>
                </ButtonPrimary>
              </form>

            </div>
            <div className="flex-1 mt-3 mt-md-0">
              <form className="block sm:flex items-center" onSubmit={e => {
                e.preventDefault();
                validationTwo.handleSubmit();
                // console.log(updatePriceProduct);

                return false;
              }}>
                <label className="block">

                  <Label>Upload 3-D Sheet</Label>
                  <Input
                    type="file"
                    name="bulkThreeDFile"
                    placeholder=""
                    className="mt-0 mt-md-1 !px-1"
                    onChange={handleThreeDSheetChange}
                  />
                  {validationTwo.errors.bulkThreeDFile ? (
                    // @ts-ignore
                    <small className="text-rose-600">{validationTwo.errors.bulkThreeDFile}</small>
                  ) : null}
                </label>
                <ButtonPrimary className="mt-2 mt-md-0 !block ml-auto" type="submit">
                  <span>Submit</span>
                </ButtonPrimary>
              </form>

            </div>
          </div>
        </div>

        <div className="listingSectionSidebar__wrap shadow-lg border-0 p-8 rounded-2xl mb-8 sm:mb-10">
          <div className="flex items-center">
            <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div>
                  <h4 className="text-2xl font-semibold md:mb-1.5">Filter Option</h4>
                </div>
              </div>
              <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-4 mt-2">
                {filterRows.length > 0 && filterRows.map((row, rowIndex) =>
                  <div>
                    <Checkbox
                      name={row.value}
                      label={row.value}
                      defaultChecked={Array.isArray(productData.filter_option) && productData.filter_option.some((item: any) => item.key === row.key)}
                      onChange={(checked) =>
                        handleChangeCategories(checked, row)
                      }
                    />
                  </div>
                )}
              </div>
              <div className="grid grid-cols-1 text-right mt-5 lg:mt-0">
                <div>
                  <ButtonPrimary onClick={() => updateFilterOption()}>Update</ButtonPrimary>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <Paper>
            <Table aria-label="simple table" className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <TableHead className="bg-gray-800 border-b text-center font-normal !text-white">
                {headerRows?.length > 0 && headerRows.map((row, rowIndex) => {
                  const currentLetters = Object.keys(row);
                  const isLastRow = rowIndex === headerRows.length - 1;

                  return (
                    <TableRow key={`header-row-${rowIndex}`} className="text-white h-10 font-normal">
                      {Object.entries(row).map(([key, value], cellIndex) => {
                        const colSpan = calculateColSpan(currentLetters, cellIndex);
                        const rowSpan = calculateRowSpan(rowIndex, key);
                        const isRightAligned = colSpan > 1;

                        return (
                          <React.Fragment key={`header-cell-${cellIndex}`}>
                            {isLastRow && (
                              <TableCell
                                align={isRightAligned ? "left" : 'center'}
                                colSpan={1}
                                rowSpan={1}
                                className="!text-white border-solid border-l border-white"
                              >
                                {value}
                              </TableCell>
                            )}

                            {!isLastRow && (
                              <TableCell
                                align={isRightAligned ? "left" : 'center'}
                                colSpan={colSpan}
                                rowSpan={rowSpan}
                                className="!text-white border-solid border-l border-white"
                              >
                                {value}
                              </TableCell>
                            )}

                            {cellIndex === currentLetters.length - 1 && (
                              <>
                              <TableCell
                                key={`action-cell-${cellIndex}`}
                                colSpan={1}  // Adjust the colSpan as needed
                                rowSpan={rowSpan}
                                align={"center"}
                                className="!text-white border-solid border-l border-white"
                              >
                                Price
                              </TableCell>
                              <TableCell
                                key={`action-cell-${cellIndex}`}
                                colSpan={1}  // Adjust the colSpan as needed
                                rowSpan={rowSpan}
                                align={"center"}
                                className="!text-white border-solid border-l border-white"
                              >
                                Discount
                              </TableCell>
                              <TableCell
                                key={`action-cell-${cellIndex}`}
                                colSpan={1}  // Adjust the colSpan as needed
                                rowSpan={rowSpan}
                                align={"center"}
                                className="!text-white border-solid border-l border-white"
                              >
                                Economic Code
                              </TableCell>
                              <TableCell
                                key={`action-cell-${cellIndex}`}
                                colSpan={1}  // Adjust the colSpan as needed
                                rowSpan={rowSpan}
                                align={"center"}
                                className="!text-white border-solid border-l border-white"
                              >
                                Economic Price
                              </TableCell>
                              <TableCell
                                key={`action-cell-${cellIndex}`}
                                colSpan={1}  // Adjust the colSpan as needed
                                rowSpan={rowSpan}
                                align={"center"}
                                className="!text-white border-solid border-l border-white"
                              >
                                Economic Discount
                              </TableCell>
                              <TableCell
                                align={'center'}
                                key={`action-cell-${cellIndex}`}
                                colSpan={22}  // Adjust the colSpan as needed
                                rowSpan={1}
                                className="!text-white border-solid border-l border-white"
                              >
                                {isLastRow && (
                                  <p>Action</p>
                                )}
                              </TableCell>
                              </>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </TableRow>
                  );
                })}

                {headerRows?.length == 0 && bodyRows.map((row, rowIndex) => {
                  if (rowIndex == 0) {
                    const currentLetters = Object.keys(row);
                    return (
                      <TableRow key={`header-row-${rowIndex}`} className="text-white h-10 font-normal">
                        {Object.entries(row).map(([key, value], cellIndex) => {

                          const colSpan = calculateColSpan(currentLetters, cellIndex);
                          const rowSpan = calculateRowSpan(rowIndex, key);
                          const isRightAligned = colSpan > 1;

                          return (
                            <React.Fragment>
                              <TableCell
                                key={`header-cell-${cellIndex}`}
                                align={"center"}
                                colSpan={1}
                                rowSpan={rowSpan}
                                className="!text-white border-solid border-l border-white"
                              >
                                {value}
                              </TableCell>

                              {cellIndex === currentLetters.length - 1 && (
                                <>
                                <TableCell
                                  key={`action-cell-${cellIndex}`}
                                  colSpan={1}  // Adjust the colSpan as needed
                                  rowSpan={rowSpan}
                                  align={"center"}
                                  className="!text-white border-solid border-l border-white"
                                >
                                  Price
                                </TableCell>
                                <TableCell
                                  key={`action-cell-${cellIndex}`}
                                  colSpan={1}  // Adjust the colSpan as needed
                                  rowSpan={rowSpan}
                                  align={"center"}
                                  className="!text-white border-solid border-l border-white"
                                >
                                  Discount
                                </TableCell>
                                <TableCell
                                  key={`action-cell-${cellIndex}`}
                                  colSpan={1}  // Adjust the colSpan as needed
                                  rowSpan={rowSpan}
                                  align={"center"}
                                  className="!text-white border-solid border-l border-white"
                                >
                                  Economic Code
                                </TableCell>
                                <TableCell
                                  key={`action-cell-${cellIndex}`}
                                  colSpan={1}  // Adjust the colSpan as needed
                                  rowSpan={rowSpan}
                                  align={"center"}
                                  className="!text-white border-solid border-l border-white"
                                >
                                  Economic Price
                                </TableCell>
                                <TableCell
                                  key={`action-cell-${cellIndex}`}
                                  colSpan={1}  // Adjust the colSpan as needed
                                  rowSpan={rowSpan}
                                  align={"center"}
                                  className="!text-white border-solid border-l border-white"
                                >
                                  Economic Discount
                                </TableCell>
                                <TableCell
                                  key={`action-cell-${cellIndex}`}
                                  colSpan={1}  // Adjust the colSpan as needed
                                  rowSpan={rowSpan}
                                  align={"center"}
                                  className="!text-white border-solid border-l border-white"
                                >
                                  Action
                                </TableCell>
                                </>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </TableRow>
                    );
                  }
                })}
              </TableHead>
              <TableBody>
                {headerRows?.length > 0 && bodyRows && bodyRows.map((row, index) => (
                  <TableRow key={`body-row-${index}`}>
                    {Object.entries(row).map(([key, cell]) => (
                       key !== 'image' && key !== 'price' && key !== 'discount' && key !== 'economic_code' && key !== 'economic_price' && key !== 'economic_discount' && (
                        <TableCell key={`body-cell-${key}`} align="center">
                        {cell}
                      </TableCell>
                      )
                    ))}

                    {/* Render an additional row with "Upload Image" button if "image" key is not present */}
                    <TableCell align="center">
                      {row.hasOwnProperty('price') ? row['price'] : <span>-</span>}
                    </TableCell>
                    <TableCell align="center">
                      {row.hasOwnProperty('discount') ? row['discount'] : <span>-</span>}
                    </TableCell>
                    <TableCell align="center">
                      {row.hasOwnProperty('economic_code') ? row['economic_code'] : <span>-</span>}
                    </TableCell>
                    <TableCell align="center">
                      {row.hasOwnProperty('economic_price') ? row['economic_price'] : <span>-</span>}
                    </TableCell>
                    <TableCell align="center">
                      {row.hasOwnProperty('economic_discount') ? row['economic_discount'] : <span>-</span>}
                    </TableCell>
                    <TableCell align="center">
                      <div className="flex items-center justify-center gap-5">
                        {row.hasOwnProperty("image") ? (
                          // Render content when "image" property exists in row
                            <>
                              <a href={row["image"]} className="text-secondary" target="_blank" rel="noopener noreferrer">
                                <svg className="w-5 h-5 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3" />
                                </svg>
                              </a>
                              <a href="#" className="text-secondary" onClick={() => { handleUploadImageClick(index); }}>
                                <svg className="w-6 h-6 text-gray-800 dark:text-white m-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2M12 4v12m0-12 4 4m-4-4L8 8" />
                                </svg>
                              </a>
                            </>
                          ) : (
                            // Render content when "image" property does not exist in row
                            <a href="#" className="text-secondary" onClick={() => { handleUploadImageClick(index); }}>
                              <svg className="w-6 h-6 text-gray-800 dark:text-white m-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2M12 4v12m0-12 4 4m-4-4L8 8" />
                              </svg>
                            </a>
                        )}
                          <ButtonPrimary
                            onClick={() => {
                              setShowUpdatePriceModal(true)
                              // console.log('hi',row,productData.id);
                              setUpdatePriceProduct(prev => ({ ...prev, codeDetails: row, productId: productData.id, productPrice: '', }))

                            }
                            }>
                            <span >Add Price</span>
                          </ButtonPrimary>
                      </div>
                    </TableCell>

                  </TableRow>
                ))}

                {headerRows?.length == 0 && bodyRows && bodyRows.map((row, index) => {
                  if (index != 0) {
                    return (
                      <TableRow key={`body-row-${index}`}>
                        {Object.entries(row).map(([key, cell]) => (
                          key !== 'image' && key !== 'price' && key !== 'discount' && key !== 'economic_code' && key !== 'economic_price' && key !== 'economic_discount' && (
                            <TableCell key={`body-cell-${key}`} align="center">
                            {cell}
                          </TableCell>
                          )
                        ))}

                        {/* Render an additional row with "Upload Image" button if "image" key is not present */}
                        <TableCell align="center">
                          {row.hasOwnProperty('price') ? row['price'] : <span>-</span>}
                        </TableCell>
                        <TableCell align="center">
                          {row.hasOwnProperty('discount') ? row['discount'] : <span>-</span>}
                        </TableCell>
                        <TableCell align="center">
                          {row.hasOwnProperty('economic_code') ? row['economic_code'] : <span>-</span>}
                        </TableCell>
                        <TableCell align="center">
                          {row.hasOwnProperty('economic_price') ? row['economic_price'] : <span>-</span>}
                        </TableCell>
                        <TableCell align="center">
                          {row.hasOwnProperty('economic_discount') ? row['economic_discount'] : <span>-</span>}
                        </TableCell>
                        <TableCell align="center">
                          <div className="flex items-center justify-center gap-5">
                            {row.hasOwnProperty("image") ? (
                              // Render content when "image" property exists in row
                                <>
                                  <a href={row["image"]} className="text-secondary" target="_blank" rel="noopener noreferrer">
                                    <svg className="w-5 h-5 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3" />
                                    </svg>
                                  </a>
                                  <a href="#" className="text-secondary" onClick={() => { handleUploadImageClick(index); }}>
                                    <svg className="w-6 h-6 text-gray-800 dark:text-white m-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2M12 4v12m0-12 4 4m-4-4L8 8" />
                                    </svg>
                                  </a>
                                </>
                              ) : (
                                // Render content when "image" property does not exist in row
                                <a href="#" className="text-secondary" onClick={() => { handleUploadImageClick(index); }}>
                                  <svg className="w-6 h-6 text-gray-800 dark:text-white m-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2M12 4v12m0-12 4 4m-4-4L8 8" />
                                  </svg>
                                </a>
                            )}
                              <ButtonPrimary
                                onClick={() => {
                                  setShowUpdatePriceModal(true)
                                  // console.log('hi',row,productData.id);
                                  setUpdatePriceProduct(prev => ({ ...prev, codeDetails: row, productId: productData.id, productPrice: '', }))

                                }
                                }>
                                <span >Add Price</span>
                              </ButtonPrimary>
                          </div>
                        </TableCell>

                      </TableRow>
                    )
                  }
                }
                )}
              </TableBody>
            </Table>
          </Paper>
          <UploadImage
            show={showModalQuickView}
            onCloseModalQuickView={() => setShowModalQuickView(false)}
            getGroupDetails={() => getGroupDetails()}
            indexData={indexData}
            id={params.id}
            title={productData.title}
          />
        </div>
      </div>
      {
        showUpdatePriceModal && <UpdatePriceModal

          // onCloseModalQuickView={() => setShowModalQuickView(false)}
          updatePriceProduct={updatePriceProduct}
          setUpdatePriceProduct={setUpdatePriceProduct}
          setShow={setShowUpdatePriceModal}
          getGroupDetails={() => getGroupDetails()}
          setIsApiCall={setIsApiCall}

        // getListData={getListData}
        />
      }


    </div>
  );
};

export default withRouter(View);
