import React from "react";
import { useLocation } from "react-router-dom";
import HeaderLogged from "components/Header/HeaderLogged";
import Header from "components/Header/Header";
import AdminHeader from "components/Admin/Header/Header";

const SiteHeader = () => {
  let location = useLocation();

  //check path name contain /admin/ then return HeaderLogged else return Header
  return location.pathname.includes("/admin") ? (
    <AdminHeader />
  ) : (
   location.pathname === "/home2" ? <Header /> : <HeaderLogged />
  );
};

export default SiteHeader;
