import {
  GET_LIST_FAIL,
  GET_LIST_SUCCESS,
  GET_DATA_FAIL,
  GET_DATA_SUCCESS,
  ADD_DATA_SUCCESS,
  ADD_DATA_FAIL,
  UPDATE_DATA_SUCCESS,
  UPDATE_LIST_DATA_SUCCESS,
  UPDATE_DATA_FAIL,
  GET_DATA,
  CLEAR_DATA,
  GET_LIST,
  ADD_NEW_DATA,
  UPDATE_DATA,
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOGIN_CUSTOMER,
  LOGOUT_CUSTOMER
} from "./actionTypes";

const INIT_STATE = {
  data: [],
  listCount: 0,
  success: "",
  error: "",
  loading: false,
  images: "",
  forgetSuccessMsg: null,
  forgetError: null,
};

const AppMaster = (state = INIT_STATE, action) => {

  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_CUSTOMER:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LOGOUT_USER:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_CUSTOMER:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case API_ERROR:
      return {
        ...state,
        error: action.payload, loading: false
      };
    case GET_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_SUCCESS:

      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };

    case GET_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        //error: action.payload,
      };
    case ADD_NEW_DATA:
      return {
        ...state,
        loading: true,
      };
    case ADD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case ADD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_DATA:
      return {
        ...state,
        loading: true,

      };
    case UPDATE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case UPDATE_LIST_DATA_SUCCESS:
      const updatedResult = state.data.result.map(item => {
        if (item._id === action.payload._id) {
          return action.payload;
        }
        return item;
      });

      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          result: updatedResult,
        },
      };

    case UPDATE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_DATA:
      return { ...state, data: null, error: null };

    default:
      return state;
  }
};

export default AppMaster;
