import { combineReducers } from "redux";

// Authentication
import AppMaster from "./AppMaster/reducer";

const rootReducer = combineReducers({
  AppMaster:AppMaster
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
