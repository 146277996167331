import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import AvatarDropdown from "./AvatarDropdown";
import LoginDropdown from "./LoginDropdown";
import Navigation from "shared/Navigation/Navigation";
import CartDropdown from "./CartDropdown";
import { XMarkIcon } from "@heroicons/react/24/outline";

import React, { FC, useEffect, useState,useMemo } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData
} from "store/AppMaster/actions";
import api from "constants/api";
import { RootState } from 'store/reducers';

export interface MainNav2LoggedProps {}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchParam = new URLSearchParams(location.search).get('search');
  const [authCustomerDetails, setCustomerDetails] = useState("")
  const [isApiCall, setIsApiCall] = useState(0)
  const [updateListData, setUpdateListData] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterData, setFilterData] = useState({
      start: 0,
      limit: 10,
      search: '',
      sort_by: "name",
      sort_order:"asc",
  });
  const categoryData = localStorage.getItem("categoryData")

  useEffect(() => {
    if (!categoryData) {
      getListData();
      setIsApiCall(1);
    }
    if (localStorage.getItem("AuthCustomerDetails")) {
      const custJson = JSON.parse(localStorage.getItem("AuthCustomerDetails") || "{}");
      setCustomerDetails(custJson);
    }
    if(searchParam){
      setSearchQuery(searchParam)
    }
  }, [categoryData,searchParam]);

  const { data } = useSelector((state: RootState) => state.AppMaster);

  const getListData = () => {
    setUpdateListData(true);
    dispatch(clearData())
    const filterData = {};
    dispatch(onGetList(filterData, '', api.frontCategory));
  }

  if (data!=null && updateListData == true) {
     if(data.category && data.category.length!=0){
        setUpdateListData(false);
        localStorage.setItem("categoryData", JSON.stringify(data.category));
     }
  }

  const inputRef = React.createRef<HTMLInputElement>();
  const [showSearchForm, setShowSearchForm] = useState(false);


  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const handleFormSubmit = (e:any) => {
    e.preventDefault();

    // Construct your query string based on the input value
    if(searchQuery){

      let queryString = `?search=${encodeURIComponent(searchQuery)}`;
      navigate("/products" + queryString);
    }else{
      navigate("/products");

    }
  };

  const handleClose = () => {
    setShowSearchForm(false)
    setSearchQuery("");
    // Construct your query string based on the input value
    navigate("/products");
  };

  const renderSearchForm = () => {
    return (
      <form
        onSubmit={handleFormSubmit}
        className="flex-1 py-2 text-slate-900 dark:text-slate-100"
      >
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 h-full rounded">
          {renderMagnifyingGlassIcon()}
          <input
            ref={inputRef}
            type="text"
            placeholder="Enter product code or product Name"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
            autoFocus
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button type="button" onClick={handleClose}>
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
        <input type="submit" hidden value="" />
      </form>
    );
  };

  const renderContent = () => {
    return (
      <div className="h-20 flex justify-between">
        <div className="flex items-center xl:hidden xl:flex-1">
          <MenuBar />
        </div>

        <div className="lg:flex-1 flex items-center">
          <Logo className="flex-shrink-0" />
        </div>

        <div className="flex-[2] hidden lg:flex justify-center mx-4">
          {showSearchForm ? renderSearchForm() : <Navigation />}
        </div>

        <div className="flex-2 flex items-center justify-end text-slate-700 dark:text-slate-100">
          {!showSearchForm && (
            <button
              className="hidden lg:flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none items-center justify-center"
              onClick={() => setShowSearchForm(!showSearchForm)}
            >
              {renderMagnifyingGlassIcon()}
            </button>
          )}
          {authCustomerDetails ? (
            <AvatarDropdown />
          ) : (
            <LoginDropdown />
          )}
          {/* {authCustomerDetails && (
            <Link to="/products" className="nc-Button relative h-auto hidden md:inline-block items-center justify-center rounded-full transition-colors text-sm font-medium py-2.5 px-4 sm:px-6  ttnc-ButtonSecondary bg-white text-slate-700 dark:bg-slate-900 dark:text-slate-300 hover:bg-gray-100 dark:hover:bg-slate-800  border border-slate-300 dark:border-slate-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0">Get Quotation</Link>
          )} */}
        {/*   <CartDropdown /> */}
        </div>
      </div>
    );
  };

  return (
    <div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
      <div className="container ">{renderContent()}</div>
    </div>
  );
};

export default MainNav2Logged;
