import withRouter from "components/Common/withRouter";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "store/AppMaster/actions";

const AdminLogout: FC<any> = props => {
   const dispatch = useDispatch();
   const history = useNavigate();
   useEffect(() => {
    dispatch(logoutUser(history,''));
  }, [dispatch, history]);


   return <></>;
};

export default withRouter(AdminLogout);

