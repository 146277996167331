import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment, useEffect, useState, useMemo, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Select from 'react-select';
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import withRouter from "components/Common/withRouter";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { useFormik } from "formik";
import { RootState } from 'store/reducers';
import { isImageJpgOrPng, isImageSizeValid, callMultipartFormDataApi, isSheet, isImageJpgOrPngOrPdf,presignedUrl,uploadFileToS3 } from "components/Common/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "containers/Admin/Loader";
import {
    clearData,
    getList as onGetList,
    getData as onGetData,
    updateData as onUpdateData,
    addNewData as onAddNewData,
} from "store/AppMaster/actions";
import api from "constants/api";
import {
    Typography,
    Button,
    Avatar,
} from "@material-tailwind/react";


import noImageFound from '../../../images/No_Image_Available.jpg'
export interface EditProps {
    show: boolean;
    onCloseModalQuickView: () => void;
    optionCategory: any[];
}

const Edit: FC<EditProps> = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const initialRender = useRef(true);
    const location = useLocation();
    const dispatch = useDispatch();
    const [isApiCall, setIsApiCall] = useState(0)
    const [optionListData, setOptionListData] = useState(false);
    const [groupData, setGroupData] = useState<Boolean>(false);
    const [optionCategory, setOptionCategory] = useState<any[]>([]);
    const [selectedData, setSelectedData] = useState([]);
    const [loderImage, setLoader] = useState(false);


    const [frmData, setFrmData] = useState({
        id: "",
        title: "",
        sub_title: "",
        meta_title: "",
        meta_desc: "",
        raw_material: "",
        case_hardness: "",
        note: "",
        category_id: "",
        sub_category_id: "",
        selected_category: "",
        data_sheet: "",
        data_sheet_url: "",
        product_sheet: "",
        product_sheet_url: "",
        line_drawing: "",
        line_drawing_url: "",
        product_table: "",
        product_table_url: "",
        image: "",
        image_url: "",
        youTube_url: "",
        order_no: "",
    });

    const onChangeHandler = (event: any) => {
        const { name, value } = event.target;
        setFrmData((preValue) => {
            return {
                ...preValue,
                [name]: value,
            };
        });
    }

    const onChangeSelect = (event: any) => {
        const selectedValues = event.map((subject: any) => subject.value);
        setFrmData((preValue) => {
            return {
                ...preValue,
                "category_id": selectedValues
            };
        });
        setSelectedData(event)
    };

    const handleImageChange = async (event: any) => {
        const file = event.target.files[0];
        try {
            console.log('frmData_before',frmData);
            // Get pre-signed URL from the backend
            const apiUrl = process.env.REACT_APP_API_URL + api.presignedUrl
            const newData = {
                filename: file.name,
                filetype: file.type,
                folder: 'product_group/images'
            };
            let url = await presignedUrl(apiUrl,newData);

            setLoader(true);
            // Upload the file to S3
            uploadFileToS3(file,url.data)
            .then((data) => {
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
            });

            setFrmData((preValue) => {
                return {
                    ...preValue,
                    image: url.filename,
                };
            });
            console.log('frmData_after',frmData);

        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Failed to upload file');
        }
    }

   const handleFileChange = async (event: any) => {
        const file = event.target.files[0];
        try {

            // Get pre-signed URL from the backend
            const apiUrl = process.env.REACT_APP_API_URL + api.presignedUrl
            const newData = {
                filename: file.name,
                filetype: file.type,
                folder: 'product_group/pdf'
            };
            let url = await presignedUrl(apiUrl,newData);

            setLoader(true);
            // Upload the file to S3
            uploadFileToS3(file,url.data)
            .then((data) => {
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
            });

            setFrmData((preValue) => {
                return {
                    ...preValue,
                    data_sheet: url.filename,
                };
            });

        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Failed to upload file');
        }
    }

    const handleSheetChange = (event: any) => {
        const file = event.target.files[0];
        setFrmData((preValue) => {
            return {
                ...preValue,
                product_sheet: file,
            };
        });
    }
    const handleTableChange = (event: any) => {
        const file = event.target.files[0];
        setFrmData((preValue) => {
            return {
                ...preValue,
                product_table: file,
            };
        });
    }

    const handleLineDrawingChange = async (event: any) => {
        const file = event.target.files[0];
        try {
            // Get pre-signed URL from the backend
            const apiUrl = process.env.REACT_APP_API_URL + api.presignedUrl
            const newData = {
                filename: file.name,
                filetype: file.type,
                folder: 'product_group/line_drawing'
            };
            let url = await presignedUrl(apiUrl,newData);

            setLoader(true);
            // Upload the file to S3
            uploadFileToS3(file,url.data)
            .then((data) => {
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
            });

            setFrmData((preValue) => {
                return {
                    ...preValue,
                    line_drawing: url.filename,
                };
            });

        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Failed to upload file');
        }
    }

    useEffect(() => {
        if (isApiCall === 0) {
            getGroupDetails();
            setIsApiCall(1);
        }
        initialRender.current = false;

        if (groupData == true && optionCategory.length === 0) {
            getCategoryListData();
            setOptionListData(false);
        }
    }, [isApiCall, groupData, optionCategory]);

    const { data } = useSelector((state: RootState) => state.AppMaster);

    const getCategoryListData = () => {
        if (optionCategory.length == 0) {
            setOptionListData(true);
            dispatch(clearData())
            const filterData = {};
            dispatch(onGetList(filterData, "", api.categoryList));
        }
    }

    const getGroupDetails = () => {
        setGroupData(true);
        //dispatch(clearData())
        let filterData = {
            id: params.id
        }
        dispatch(onGetData(filterData, '', api.productGroupDetail));
    }

    if (data != null && data.result != undefined && optionCategory.length == 0) {
        if (data.length != 0 && data.result != 0) {
            setOptionListData(false);
            const categoryList = data.result
            .map((subject: any) => ({
                value: subject._id,
                label: subject.name,
            }));
            setOptionCategory(categoryList);
        }
    }

    if (data != null && groupData == true) {
        if (data.productGroup != null) {
            setGroupData(false);
            // console.log(data);

            setFrmData((preValue) => {
                return {
                    ...preValue,
                    id: data.productGroup._id,
                    title: data.productGroup.title,
                    sub_title: data.productGroup.sub_title,
                    meta_title: data.productGroup.meta_title,
                    meta_desc: data.productGroup.meta_desc,
                    raw_material: data.productGroup.raw_material,
                    case_hardness: data.productGroup.case_hardness,
                    note: data.productGroup.note,
                    category_id: (data.productGroup.category.length !== 0) ? data.productGroup.category.map((subject: any) => subject._id) : "",
                    selected_category: (data.productGroup.category.length !== 0) ? data.productGroup.category.map((subject: any) => ({
                        value: subject._id,
                        label: subject.name,
                    })) : [],
                    sub_category_id: "",
                    data_sheet_url: (data.productGroup.data_sheet !== (undefined || null)) ? data.productGroup.data_sheet.url : "",
                    product_sheet_url: (data.productGroup.product_sheet !== (undefined || null)) ? data.productGroup.product_sheet.url : "",
                    product_table_url: (data.productGroup.product_table !== (undefined || null)) ? data.productGroup.product_table.url : "",
                    line_drawing_url: (data.productGroup.line_drawing !== (undefined || null)) ? data.productGroup.line_drawing.url : "",
                    image_url: (data.productGroup.image !== (undefined || null)) ? data.productGroup.image.url : "",
                    youTube_url: data.productGroup.youTube_url,
                    order_no: data.productGroup.order_no??1,
                };
            });
        }
    }
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed


        enableReinitialize: true,
        initialValues: {
            title: (frmData && frmData.title) || "",
            sub_title: (frmData && frmData.sub_title) || "",
            meta_title: (frmData && frmData.meta_title) || "",
            meta_desc: (frmData && frmData.meta_desc) || "",
            raw_material: (frmData && frmData.raw_material) || "",
            case_hardness: (frmData && frmData.case_hardness) || "",
            note: (frmData && frmData.note) || "",
            category_id: (frmData && frmData.category_id) || "",
            sub_category_id: (frmData && frmData.sub_category_id) || "",
            image: (frmData && frmData.image) || "",
            data_sheet: (frmData && frmData.data_sheet) || "",
            product_sheet: (frmData && frmData.product_sheet) || "",
            product_table: (frmData && frmData.product_table) || "",
            line_drawing: (frmData && frmData.line_drawing) || "",
            youTube_url: (frmData && frmData.youTube_url) || "",
            order_no: (frmData && frmData.order_no) || 1,
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Please Enter Title"),
            sub_title: Yup.string().required("Please Enter Sub Title"),
            order_no: Yup.number(),
            product_sheet: Yup.mixed()
                .test('fileFormat', 'Product sheet must be an xlsx.', (value) => {
                    if (!value) return true; // If no image provided, pass validation
                    return isSheet(value);
                }),
            product_table: Yup.mixed()
                .test('fileFormat', 'Product table must be an xlsx.', (value) => {
                    if (!value) return true; // If no image provided, pass validation
                    return isSheet(value);
                }),
           /*  image: Yup.mixed()
                .test('fileFormat', 'Image must be an jpeg, jpg or png', (value) => {
                    if (!value) return true; // If no image provided, pass validation
                    return isImageJpgOrPng(value);
                }),
            line_drawing: Yup.mixed()
                .test('fileFormat', 'Line drawing must be an jpeg, jpg, png or pdf.', (value) => {
                    if (!value) return true; // If no image provided, pass validation
                    return isImageJpgOrPngOrPdf(value);
                }), */
        }),
        onSubmit: values => {
            const newData = {
                id: frmData.id,
                title: values["title"],
                sub_title: values["sub_title"],
                meta_title: values["meta_title"],
                meta_desc: values["meta_desc"],
                raw_material: values["raw_material"],
                case_hardness: values["case_hardness"],
                note: values["note"],
                category_id: Array.isArray(values["category_id"]) ? values["category_id"].join(",") : values["category_id"],
                sub_category_id: values["sub_category_id"],
                image: values["image"],
                data_sheet: values["data_sheet"],
                product_sheet: values["product_sheet"],
                product_table: values["product_table"],
                line_drawing: values["line_drawing"],
                youTube_url: values["youTube_url"],
                order_no: values["order_no"],
            };
            //  dispatch(onAddNewData(newData, "", api.createCategory))
            const url = process.env.REACT_APP_API_URL + api.productGroupUpdate
            callMultipartFormDataApi(url, newData)
            navigate("/admin/products");
        },
    });


    return (
        <div className="container py-16 lg:pb-28 lg:pt-20">
            <Helmet>
                <title>Edit Product Group</title>
            </Helmet>
            <Loader
                loderImage={loderImage}
            />
            <div className="mb-12 sm:mb-16">
                <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">Edit Product Group</h2>
            </div>
            <div className="mt-8 sm:mt-10">
                <div className="flex-1  rounded-xl hiddenScrollbar">
                    <form className="grid md:grid-cols-2 grid-cols-1 gap-6"
                        onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                        method="post">
                        <label className="block">
                            <Label>Title</Label>
                            <Input
                                name="title"
                                placeholder="Title"
                                type="text"
                                className="mt-1"
                                value={frmData.title}
                                onChange={onChangeHandler}
                            />
                            {validation.errors.title ? (
                                <small className="text-rose-600">{validation.errors.title}</small>
                            ) : null}
                        </label>
                        <label className="block">
                            <Label>Sub Title</Label>
                            <Input
                                name="sub_title"
                                value={frmData.sub_title}
                                placeholder="Sub Title"
                                type="text"
                                className="mt-1"
                                onChange={onChangeHandler}
                            />
                            {validation.errors.sub_title ? (
                                <small className="text-rose-600">{validation.errors.sub_title}</small>
                            ) : null}
                        </label>
                        <label className="block">
                            <Label>Category</Label>
                            <Select
                                name="category_id"
                                options={optionCategory}
                                isMulti={true}
                                onChange={(event:any) => onChangeSelect(event)}
                                className="mt-1"
                                value={(selectedData.length>0)? selectedData : frmData.selected_category}
                            />
                            {validation.errors.sub_title ? (
                                <small className="text-rose-600">{validation.errors.sub_title}</small>
                            ) : null}
                        </label>
                        {/*   <label className="block">
                <Label>Sub Category</Label>
                <Select
                name="sub_category_id"
                className="mt-1"
                onChange={(event) => onChangeSelect(event, "sub_category_id")}
                />
                {validation.errors.sub_title ? (
                    <small className="text-rose-600">{validation.errors.sub_title}</small>
                ) : null}
            </label> */}
                        <label className="block">
                            <Label>Raw Material</Label>
                            <Textarea
                                name="raw_material"
                                placeholder="Raw Material"
                                rows={2}
                                className="mt-1"
                                value={frmData.raw_material}
                                onChange={onChangeHandler}
                            />
                        </label>

                        <label className="block">
                            <Label>Case Hardness</Label>
                            <Textarea
                                name="case_hardness"
                                rows={2}
                                placeholder="Case Hardness"
                                className="mt-1"
                                value={frmData.case_hardness}
                                onChange={onChangeHandler}
                            />
                        </label>
                        <label className="block" style={{ width: '100%' }}>
                            <Label>Note</Label>
                            <Textarea
                                name="note"
                                rows={2}
                                placeholder="Note"
                                className="mt-1"
                                value={frmData.note}
                                onChange={onChangeHandler}
                            />

                        </label>

                        <label className="block">
                            <Label>Meta Title</Label>
                            <Textarea
                                name="meta_title"
                                rows={2}
                                placeholder="Meta Title"
                                className="mt-1"
                                value={frmData.meta_title}
                                onChange={onChangeHandler}
                            />
                        </label>
                        <label className="block">
                            <Label>Meta Description</Label>
                            <Textarea
                                name="meta_desc"
                                rows={2}
                                placeholder="Meta Description"
                                className="mt-1"
                                value={frmData.meta_desc}
                                onChange={onChangeHandler}
                            />
                        </label>
                        <label className="block">
                            <Label>Youtube URL</Label>
                            <Textarea
                                name="youTube_url"
                                rows={2}
                                placeholder="Enter URL"
                                className="mt-1"
                                value={frmData.youTube_url}
                                onChange={onChangeHandler}
                            />
                        </label>
                        <label className="block">
                            <Label>Order No</Label>
                            <Input
                                name="order_no"
                                placeholder="Order No"
                                type="text"
                                className="mt-1"
                                value={frmData.order_no}
                                onChange={onChangeHandler}
                            />
                            {validation.errors.order_no ? (
                                <small className="text-rose-600">{validation.errors.order_no}</small>
                            ) : null}
                        </label>
                        <label className="block">
                            <Label>Image</Label>
                            <div className="d-block">
                                <Avatar placeholder="" src={frmData.image_url ? frmData.image_url : noImageFound} alt="" size="md"
                                 onPointerEnterCapture={() => {}}
                                 onPointerLeaveCapture={() => {}}
                                />
                            </div>
                            <Input
                                type="file"
                                name="image"
                                placeholder=""
                                className="mt-1"
                                onChange={handleImageChange}
                            />
                            {validation.errors.image ? (
                                <small className="text-rose-600">{validation.errors.image}</small>
                            ) : null}
                        </label>
                        <label className="block">
                            <Label>Catalog File</Label>
                            <div className="d-block">
                                <Avatar placeholder="" src={frmData.data_sheet_url ? frmData.data_sheet_url : noImageFound} alt="" size="md"
                                 onPointerEnterCapture={() => {}}
                                 onPointerLeaveCapture={() => {}}
                                />
                            </div>
                            <Input
                                type="file"
                                name="data_sheet"
                                placeholder=""
                                className="mt-1"
                                onChange={handleFileChange}
                            />
                            {validation.errors.data_sheet ? (
                                <small className="text-rose-600">{validation.errors.data_sheet}</small>
                            ) : null}
                        </label>
                        <label className="block">
                            <Label>Line Drawing</Label>
                            <div className="d-block">
                                <Avatar placeholder="" src={frmData.line_drawing_url ? frmData.line_drawing_url : noImageFound} alt="" size="md"
                                 onPointerEnterCapture={() => {}}
                                 onPointerLeaveCapture={() => {}}
                                />
                            </div>
                            <Input
                                type="file"
                                name="line_drawing"
                                placeholder=""
                                className="mt-1"
                                onChange={handleLineDrawingChange}
                            />
                            {validation.errors.line_drawing ? (
                                <small className="text-rose-600">{validation.errors.line_drawing}</small>
                            ) : null}
                        </label>
                        <label className="block">
                            <Label>Product Table</Label>
                            <div className="d-block">
                                <Avatar placeholder="" src={frmData.product_sheet_url ? frmData.product_sheet_url : noImageFound} alt="" size="md"
                                 onPointerEnterCapture={() => {}}
                                 onPointerLeaveCapture={() => {}}
                                />
                            </div>
                            <Input
                                type="file"
                                name="product_sheet"
                                placeholder=""
                                className="mt-1"
                                onChange={handleSheetChange}
                            />
                            {validation.errors.product_sheet ? (
                                <small className="text-rose-600">{validation.errors.product_sheet}</small>
                            ) : null}
                        </label>
                        <label className="block">
                            <Label>Raw Material Table</Label>
                            <div className="d-block">
                                <Avatar placeholder="" src={frmData.product_table_url ? frmData.product_table_url : noImageFound} alt="" size="md"
                                 onPointerEnterCapture={() => {}}
                                 onPointerLeaveCapture={() => {}}
                                />
                            </div>
                            <Input
                                type="file"
                                name="product_table"
                                placeholder=""
                                className="mt-1"
                                onChange={handleTableChange}
                            />
                            {validation.errors.product_sheet ? (
                                <small className="text-rose-600">{validation.errors.product_table}</small>
                            ) : null}
                        </label>
                        <div className="mt-4">
                            <ButtonPrimary type="submit">Submit</ButtonPrimary>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Edit);
