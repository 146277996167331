import PropTypes from "prop-types"
import React, { useEffect, useState, FC } from "react"
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import api from "constants/api"
import withRouter from "components/Common/withRouter"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { validateOTP } from "components/Common/commonFunction";
import {
    clearData,
    getList as onGetList,
    updateData as onUpdateData,
    addNewData as onAddNewData,
  } from "store/AppMaster/actions";

import { useFormik,Formik } from "formik"
import * as Yup from "yup"
import { RootState } from 'store/reducers';


export interface ForgotPassProps {
  className?: string;
  props?:any;
}


const ForgotPass: FC<ForgotPassProps> = ({ className = "",props }) => {

  const dispatch = useDispatch();
  const [color, setColor] = useState("#2A3491");
  const { data } = useSelector((state: RootState) => state.AppMaster);
  const [isSent, setIsSent] = useState(false)
  const [response_email, setEmail] = useState("")
  const [isVerify, setIsVerify] = useState(false)
  const [isForgot, setForgotPass] = useState(false)
  const [isVerifyEmail, setEmailVerify] = useState(false)
  const [isChange, setChangePassword] = useState(false)
  // Form validation
  const validation = useFormik({
     initialValues: {
       email: "",
     },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid Email")
        .max(255)
        .required("Email is required")
    }),
    onSubmit: values => {
      const newData = {
        email: values.email
      }
      dispatch(onAddNewData(newData, "", api.resendOTP))
      setForgotPass(true);
      setEmail(values.email);
    },
  })

  const validationOtp = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: response_email,
      otp: ""
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid Email")
        .max(255)
        .required("Email is required"),
      otp: Yup.string().required("Please Enter OTP").matches(
        /^[+0-9]+$/i,
        "Please Enter Valid OTP"
      ).min(6, "Please Enter Valid OTP").max(6, "Please Enter Valid OTP")
    }),
    onSubmit: values => {
      const newData = {
        email: values.email,
        otp: values["otp"]
      };
      const apiUrl = process.env.REACT_APP_API_URL + api.verifyOTP
    // dispatch(onAddNewData(newData, "", api.verifyOTP))
     validateOTP(apiUrl,newData)
      .then((data) => {
        setEmail(values.email);
        setEmailVerify(true);
      })
      .catch((error) => {
        setEmailVerify(false);
      });
    },
  });

  const changePassword = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirm_password: ""
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Password is required')
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Please ensure your password is a minimum of eight characters and includes at least one letter, one number, and one special character"
        ).min(8, 'Password must be at least 6 characters'),
      confirm_password: Yup.string().required('Confirm Password is required')
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Please ensure your password is a minimum of eight characters and includes at least one letter, one number, and one special character"
        ).min(8, 'Password must be at least 6 characters')
        .oneOf([Yup.ref('password')], 'Passwords must match'),
    }),
    onSubmit: values => {
      const newData = {
        email: response_email,
        password: values["password"]
      };
      dispatch(onAddNewData(newData, "", api.changePassword))
      setChangePassword(true);
    },
  });

  const resendOTP = () => {
    const newData = {
      email: response_email,
    };
    dispatch(onAddNewData(newData, "", api.resendOTP))
  }

  if (data && data.otpDetails != null && isVerifyEmail== true) {
    setTimeout(() => {
       setIsVerify(true);
    }, 1000);
  }

  if (data && data.otpDetails != null && isForgot == true) {
    setTimeout(() => {
      setForgotPass(false);
      setIsSent(true)
    }, 1000);
  }

  if (data && data.passwordDetails != null && isChange == true) {
    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  }


  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Forgot Password | Vijaydeep</title>
      </Helmet>
      {isVerify && (
        <div className="container mb-24 lg:mb-32">
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Forgot Password
          </h2>
          <div className="max-w-md mx-auto space-y-6 ">
            {/* FORM */}
            <form className="grid grid-cols-1 gap-6"
              onSubmit={e => {
                e.preventDefault();
                changePassword.handleSubmit();
                return false;
              }}
              method="post">
                <label className="block">
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Password
                    </span>
                    <Input type="password" className="mt-1"
                    name="password"
                    onChange={changePassword.handleChange}
                    onBlur={changePassword.handleBlur}
                    value={changePassword.values.password || ""}
                    />
                    {changePassword.errors.password ? (
                    <small className="text-rose-600">{changePassword.errors.password}</small>
                    ) : null}
                </label>
                <label className="block">
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Confirm Password
                    </span>
                    <Input type="password" className="mt-1"
                    name="confirm_password"
                    onChange={changePassword.handleChange}
                    onBlur={changePassword.handleBlur}
                    value={changePassword.values.confirm_password || ""}
                    />
                    {changePassword.errors.confirm_password ? (
                    <small className="text-rose-600">{changePassword.errors.confirm_password}</small>
                    ) : null}
                </label>
              <ButtonPrimary type="submit">Change Password</ButtonPrimary>
            </form>
          </div>
        </div>
      )}
      {isSent && !isVerify && (
        <div className="container mb-24 lg:mb-32">
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Verify Email Address
          </h2>
          <div className="max-w-md mx-auto space-y-6 ">
            {/* FORM */}
            <form className="grid grid-cols-1 gap-6"
              onSubmit={e => {
                e.preventDefault();
                validationOtp.handleSubmit();
                return false;
              }}
              method="post">
               <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                    Email address
                </span>
                <Input
                    type="email"
                    name="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    onChange={validationOtp.handleChange}
                    onBlur={validationOtp.handleBlur}
                    value={validationOtp.values.email || ""}
                />
                {validationOtp.errors.email ? (
                                <small className="text-rose-600">{validationOtp.errors.email}</small>
                            ) : null}
                </label>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  OTP
                </span>
                <Input
                  type="text"
                  placeholder=""
                  className="mt-1"
                  name="otp"
                  onChange={validationOtp.handleChange}
                  onBlur={validationOtp.handleBlur}
                  value={validationOtp.values.otp || ""}
                />
                {validationOtp.errors.otp ? (
                  <small className="text-rose-600">{validationOtp.errors.otp}</small>
                ) : null}
              </label>
              <ButtonPrimary type="submit">Verify OTP</ButtonPrimary>
            </form>

            {/* ==== */}
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
              Don't get OTP? {` `}
              <Link className="text-primary-600" to="#" onClick={resendOTP}>
                Resend OTP
              </Link>
            </span>
          </div>
        </div>
      )}
      {!isSent && !isVerify && (
        <div className="container mb-24 lg:mb-32">
            <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Forgot Password
            </h2>
            <div className="max-w-md mx-auto space-y-6">
            {/* FORM */}
            <form className="grid grid-cols-1 gap-6"
            onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
                }}
            >
                <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                    Email address
                </span>
                <Input
                    type="email"
                    name="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                />
                {validation.errors.email ? (
                                <small className="text-rose-600">{validation.errors.email}</small>
                            ) : null}
                </label>
                <ButtonPrimary type="submit">Send OTP</ButtonPrimary>
            </form>
            </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(ForgotPass);