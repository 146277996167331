import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment, useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import {
    clearData,
    getList as onGetList,
    updateData as onUpdateData,
    addNewData as onAddNewData,
} from "store/AppMaster/actions";
import api from "constants/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isImageJpgOrPng, isImageSizeValid, callMultipartFormDataApi } from "components/Common/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 5000,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000
};

export interface CreateProps {
    setIsApiCall?: any;
    enquiryId?: any;
    setShowConfirmOrderModal?: any;
    customerMAil?: any;
}

const ConfirmOrderModal: FC<CreateProps> = ({
    enquiryId,
    setIsApiCall,
    setShowConfirmOrderModal,
    customerMAil
}) => {
    const location = useLocation();
    const dispatch = useDispatch();


    const onChangeHandler = (event: any) => {


        const { name, value } = event.target;

    }



    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            id: enquiryId,
            message: "",
            status: "orderConfirmed",
            customerMAil: customerMAil,
        },
        validationSchema: Yup.object({
            message: Yup.string()
                .required("Please enter a message")
        }),
        onSubmit: async values => {
            const newData = {
                message: values.message,
                id: enquiryId,
                status: 'orderConfirmed',
                customerMail: customerMAil
            };

            dispatch(onUpdateData(newData, '', api.frontUpdateEnquiryStatusAndCart))
            setIsApiCall(0);
            setShowConfirmOrderModal(false);
        },
    });

    return (


        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white w-96 rounded-lg shadow-xl">
                <form onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();

                    return false;
                }}>
                    {/* Header */}
                    <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200">
                        <h2 className="text-lg font-semibold">Add a confirmation message</h2>
                        <button
                            className="text-gray-500 hover:text-gray-700 transition-all"
                            onClick={(e) => { setShowConfirmOrderModal(false) }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                    {/* Body */}
                    <div className="p-4">
                        {/* Message Input */}
                        <div className="mb-4">
                            <label htmlFor="code" className="block text-sm font-medium text-gray-700">Message</label>
                            <textarea
                                id="code"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                name="message"
                                value={validation.values.message}
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                            />
                            {validation.errors.message ? (
                                <small className="text-rose-600">{validation.errors.message}</small>
                            ) : null}
                        </div>

                        <div className="text-center mt-3">
                            <ButtonPrimary type="submit">
                                <span>Submit</span>
                            </ButtonPrimary>
                        </div>
                    </div>
                </form>
            </div>
        </div>





    );
};

export default ConfirmOrderModal;


