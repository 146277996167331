import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import {
  useAsyncDebounce,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  Card,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  filterData,
  getListData,
  listCount,
}) {
  const count = listCount;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || filterData.search);
    filterData.search = value;
    filterData.start = 0;
    getListData();
  }, 200);

  return (
    <React.Fragment>
      <div className="search-box">
        <div className="search-label">
          <label htmlFor="search-bar-0" className="position-relative  m-0">
            <span id="search-bar-0-label" className="sr-only">
              Search this table
            </span>
            <input
              onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              id="search-bar-0"
              type="text"
              className="form-control mb-0"
              placeholder={`${count} records...`}
              value={filterData.search}
            />
            <i className="bx bx-search-alt search-icon"></i>
          </label>

        </div>
      </div>
    </React.Fragment>
  );
}

const TableContainer = ({columns,data,isGlobalFilter,filterData,noDataIndication,getListData,listCount,className,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    //gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: filterData.limit,
        // sortBy: [
        //   {
        //     id: "id",
        //     desc: true,
        //   },
        // ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );
  // console.log(data);
  const [pageIndex, setPageIndex] = useState(0);

  const pageCount = Math.ceil(listCount / pageSize);

  const canNextPage = pageIndex < pageCount - 1;
  const canPreviousPage = pageIndex > 0;
  const nextPage = () => {
    setPageIndex(Number(pageIndex) + 1);
    let start = Number(pageIndex) + 1;
    filterData.start = filterData.limit * start;
    getListData();
  };
  const previousPage = () => {
    setPageIndex(Number(pageIndex) - 1);
    let start = Number(pageIndex) - 1;
    filterData.start = filterData.limit * start;
    getListData();
  };
  const gotoPage = pageIndex => {
    setPageIndex(Number(pageIndex));
    let start = Number(pageIndex);
    filterData.start = filterData.limit * start;
    getListData();
  };

  const generateSortingIndicator = column => {
    if (column.isSorted) {
      let isSortedDesc = column.isSortedDesc ? "desc" : "asc";
      filterData.sort_by = column.id;
      filterData.sort_order = column.isSortedDesc ? "desc" : "asc";
      if (column.id != filterData.sort_by && isSortedDesc != filterData.sort_order) {
        filterData.start = 0;
        getListData();
      }
    }
    return column.id == filterData.sort_by && filterData.sort_order == "desc" ? " 🔽" : column.id == filterData.sort_by && filterData.sort_order == "asc" ? '🔼' : '';

  };

  const onChangeInSelect = event => {
    filterData.limit = Number(event.target.value);
    filterData.start = 0;
    setPageSize(Number(event.target.value));
    getListData();
  };
  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
    filterData.start = filterData.limit * page + 1;
    getListData();
  };
  return (
    <Fragment>
      <>
        <div className="overflow-x-auto">
          <table className="w-full min-w-max table-auto text-left" {...getTableProps()}>
            <thead className="bg-gray-800 border-b">
              {headerGroups.map(headerGroup => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th key={column.id} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 uppercase text-white">
                      <Typography
                        {...column.getSortByToggleProps()}
                        placeholder=""
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none"
                        onPointerEnterCapture={() => {}}
                        onPointerLeaveCapture={() => {}}
                      >
                        {column.render("Header")}
                        {generateSortingIndicator(column)}
                      </Typography>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="align-middle">
              {page.length === 0 && (
                <><tr className="text-center"> <td colSpan={columns.length}> No Data Found</td> </tr>  </>
              )}
              {page.map(row => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                      {row.cells.map(cell => {
                        const isLast = cell === cell.length - 1;
                        const classes = isLast
                          ? "p-4"
                          : "p-4 border-b border-blue-gray-50";
                        return (
                          <td className={classes} key={cell.id} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;