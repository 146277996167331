import React, { Component } from 'react';
import logoNewOld from '../src/images/logo-new.svg'; // Import the image

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    localStorage.removeItem('categoryData');
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    localStorage.removeItem('categoryData');
    // You can also log the error to an error reporting service
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }


  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
          <div className="max-w-md px-4 py-8 bg-white shadow-lg rounded-lg">
            <img src={logoNewOld} alt="Logo" className="mx-auto mb-4" /> {/* Include the image */}
            <h2 className="text-3xl font-semibold text-gray-800 mb-4">Sorry, something went wrong</h2>
            <p className="text-gray-600 mb-6">Please refresh the page to try again.</p>
            <button
              onClick={() => window.location.href="/"}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              Refresh
            </button>
          </div>
        </div>
      );

    }

    return this.props.children;
  }
}

export default ErrorBoundary;