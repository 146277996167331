export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"


export const LOGIN_CUSTOMER = "LOGIN_CUSTOMER"

export const LOGOUT_CUSTOMER = "LOGOUT_CUSTOMER"

export const FORGET_PASSWORD = "FORGET_PASSWORD"
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS"
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR"

/* LIST */
export const GET_LIST = "GET_LIST"
export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS"
export const GET_LIST_FAIL = "GET_LIST_FAIL"

/* DATA */
export const GET_DATA = "GET_DATA"
export const GET_DATA_SUCCESS = "GET_DATA_SUCCESS"
export const GET_DATA_FAIL = "GET_DATA_FAIL"

/**
 * add DATA
 */
export const ADD_NEW_DATA = "ADD_NEW_DATA"
export const ADD_DATA_SUCCESS = "ADD_DATA_SUCCESS"
export const ADD_DATA_FAIL = "ADD_DATA_FAIL"

/**
 * Edit DATA
 */
export const UPDATE_DATA = "UPDATE_DATA"
export const UPDATE_DATA_SUCCESS = "UPDATE_DATA_SUCCESS"
export const UPDATE_LIST_DATA_SUCCESS = "UPDATE_LIST_DATA_SUCCESS"
export const UPDATE_DATA_FAIL = "UPDATE_DATA_FAIL"

/**
 * Clear DATA
 */
export const CLEAR_DATA = "CLEAR_DATA"