import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { CartProvider } from './context/CartContext';
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "rc-slider/assets/index.css";
import "styles/__theme_colors.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import './global.css';

const MainApp = () => {
  useEffect(() => {
    const disableContextMenu = (event: MouseEvent) => {
      event.preventDefault();
    };

    // Add event listener to the document
    document.addEventListener('contextmenu', disableContextMenu);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('contextmenu', disableContextMenu);
    };
  }, []);

  return (
    <Provider store={store}>
      <CartProvider>
        <App />
      </CartProvider>
    </Provider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<MainApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
