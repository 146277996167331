import { call, put, takeEvery } from "redux-saga/effects";
import { fetchApiJSON } from '../../helpers/api';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  GET_LIST,
  GET_DATA,
  ADD_NEW_DATA,
  UPDATE_DATA,
  UPDATE_LIST_DATA_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
  LOGIN_CUSTOMER,
  LOGOUT_CUSTOMER,
} from "./actionTypes";
import {
  getListFail,
  getListSuccess,
  getDataFail,
  getDataSuccess,
  addDataFail,
  addDataSuccess,
  updateDataSuccess,
  updateListDataSuccess,
  updateDataFail,
  apiError,
} from "./actions";
import { isEmpty } from "lodash";
toastr.options = {
  positionClass: "toast-top-right",
  timeOut: 5000,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  showDuration: 300,
  hideDuration: 1000
};

//Login User
function* loginUser({ payload: { data, history, url } }) {
  try {
    const options = {
      body: JSON.stringify(data),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const apiUrl = process.env.REACT_APP_API_URL + url;
    const response = yield call(fetchApiJSON, apiUrl, options);

    if (response.statusCode == 200) {
      localStorage.setItem("AuthUser", JSON.stringify(response.data.token));
      localStorage.setItem("AuthUserDetails", JSON.stringify(response.data.userDetail));
     // history("/admin/dashboard");
      window.location.href ="/admin/dashboard";
      yield put(getDataSuccess(response.data));
    } else if (response.statusCode == 500) {
      toastr.error(response.message, '');
    } else {
      toastr.error(response.message, '');
      yield put(apiError(response.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

//Logout User
function* logoutUser({ payload: { history, url } }) {
  localStorage.removeItem("AuthUser");
  localStorage.removeItem("AuthUserDetails");
  window.location.href ="/admin/login";
}

//Login User
function* loginCustomer({ payload: { data, history, url } }) {
  try {
    const options = {
      body: JSON.stringify(data),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const apiUrl = process.env.REACT_APP_API_URL + url;
    const response = yield call(fetchApiJSON, apiUrl, options);

    if (response.statusCode == 200) {
      localStorage.setItem("AuthCustomer", JSON.stringify(response.data.token));
      localStorage.setItem("AuthCustomerDetails", JSON.stringify(response.data.userDetail));
      const url =localStorage.getItem("redirectUrl") ? localStorage.getItem("redirectUrl") :"/";
      toastr.success(response.message, '');
      setTimeout(() => {
        window.location.href =url;
      }, 100);
      yield put(getDataSuccess(response.data));
    } else if (response.statusCode == 500) {
      toastr.error(response.message, '');
    } else {
      toastr.error(response.message, '');
      yield put(apiError(response.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

//Logout User
function* logoutCustomer({ payload: { history, url } }) {
  localStorage.removeItem("AuthCustomer");
  localStorage.removeItem("AuthCustomerDetails");
  localStorage.removeItem("redirectUrl");
  window.location.href ="/";
}

function* fetchList({ payload: { data, history, url } }) {
  let authData=""
  if(url=="font-end/product-group"){
    authData = JSON.parse(localStorage.getItem("AuthCustomer"));
  }else{
    authData = JSON.parse(localStorage.getItem("AuthUser"));
  }

  const options = {
    body: JSON.stringify(data),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authData
    },
  };
  const apiUrl = process.env.REACT_APP_API_URL + url;
  try {
    const response = yield call(fetchApiJSON, apiUrl, options);
    if (response.statusCode == 200 || response.statusCode == 404) {
      yield put(getListSuccess(response.data));
    } else if (response.statusCode == 401) {
      toastr.error(response.message, '');
      yield put(getListFail(response.message));
      localStorage.removeItem("AuthUser");
      localStorage.removeItem("AuthUserDetails");
      window.location.href ="/admin/login";
    } else {
      toastr.error(response.message, '');
      yield put(getListFail(response.message));
    }
  } catch (error) {
    yield put(getListFail(error));
  }
}
function* onUpdateData({ payload: { data, history, url } }) {
  let authData=""
  if(url=="font-end/update-customer"){
    authData = JSON.parse(localStorage.getItem("AuthCustomer"));
  }else{
    authData = JSON.parse(localStorage.getItem("AuthUser"));
  }
  // console.log(data)
  const options = {
    body: JSON.stringify(data),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authData
    },
  };
  const apiUrl = process.env.REACT_APP_API_URL + url;
  try {
    const response = yield call(fetchApiJSON, apiUrl, options);
    if (response.statusCode == 200) {
      toastr.success(response.message, '');
      // console.log(response.data)
      yield put(addDataSuccess(response.data));
    } else if (response.statusCode == 401) {
      toastr.error(response.message, '');
      yield put(getListFail(response.message));
      localStorage.removeItem("AuthUser");
      localStorage.removeItem("AuthUserDetails");
      window.location.href ="/admin/login";
    } else {
      toastr.error(response.message, '');
      yield put(updateDataFail(response));
    }
  } catch (error) {
    yield put(updateDataFail(error));
  }
}
function* onAddNewData({ payload: { data, history, url } }) {
  const authData = JSON.parse(localStorage.getItem("AuthUser"));
  const options = {
    body: JSON.stringify(data),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authData
    },
  };
  const apiUrl = process.env.REACT_APP_API_URL + url;
  try {
    const response = yield call(fetchApiJSON, apiUrl, options);
    if (response.statusCode == 200) {
      toastr.success(response.message, '');
      yield put(addDataSuccess(response.data));
    } else if (response.statusCode == 401) {
      toastr.error(response.message, '');
      yield put(getListFail(response.message));
      localStorage.removeItem("AuthUser");
      localStorage.removeItem("AuthUserDetails");
      window.location.href ="/admin/login";
    } else {
      toastr.error(response.message, '');
      yield put(addDataFail(response));
    }
  } catch (error) {
    yield put(addDataFail(error));
  }
}
function* onGetData({ payload: { data, history, url } }) {
  let authData=""
  if(url=="font-end/product-details" || url=="font-end/customer-details"){
    authData = JSON.parse(localStorage.getItem("AuthCustomer"));
  }else{
    authData = JSON.parse(localStorage.getItem("AuthUser"));
  }
  const options = {
    body: JSON.stringify(data),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authData
    },
  };
  const apiUrl = process.env.REACT_APP_API_URL + url;
  try {
    const response = yield call(fetchApiJSON, apiUrl, options);
    if (response.statusCode == 200 || response.statusCode == 404) {
      // console.log(response.data);
      yield put(getDataSuccess(response.data));
    } else if (response.statusCode == 401) {
      toastr.error(response.message, '');
      yield put(getListFail(response.message));
      localStorage.removeItem("AuthUser");
        localStorage.removeItem("AuthUserDetails");
        window.location.href ="/admin/login";
    } else {
      if(url=="font-end/customer-details"){
        localStorage.removeItem("AuthCustomer");
        localStorage.removeItem("AuthUserDetails");
        window.location.href ="/login";
      }else{
        toastr.error(response.message, '');
        yield put(addDataFail(response.message));
      }
    }
  } catch (error) {
    yield put(getDataFail(error));
  }
}
function* appMasterSaga() {

  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(LOGIN_CUSTOMER, loginCustomer);
  yield takeEvery(LOGOUT_CUSTOMER, logoutCustomer);
  yield takeEvery(GET_LIST, fetchList);
  yield takeEvery(GET_DATA, onGetData);
  yield takeEvery(ADD_NEW_DATA, onAddNewData);
  yield takeEvery(UPDATE_DATA, onUpdateData);
}
export default appMasterSaga;
