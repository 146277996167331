import React, { FC } from "react";
import Nav from "shared/Nav/Nav";
import NavItem from "shared/NavItem/NavItem";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import TabFilters from "components/TabFilters";
import { Transition } from "@headlessui/react";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { useState } from 'react';

export interface AdminHeaderFilterSearchPageProps {
  className?: string;
  filterData:any;
  getListData:any;
}

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const AdminHeaderFilterSearchPage: FC<AdminHeaderFilterSearchPageProps> = ({
  className = "mb-12",
  filterData,
  getListData
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  // const [tabActive, setTabActive] = React.useState("All items");
  const [status, setStatus] = useState('');
  const [type, setType] = useState('');

  const [value, onChange] = useState<Value>([new Date(), new Date()]);

  const handleStatusChange = (e:any) => {
    setStatus(e.target.value);
  };

  const handleTypeChange = (e:any) => {
    setType(e.target.value);
  };

  const handleFilter = async () => {
    filterData.date = value;
    filterData.status = status;
    filterData.type = type;
    getListData();
  };

  const handleClearFilter = async () => {
    setStatus('');
    setType('');
    onChange([new Date(), new Date()]);
    filterData.date = '';
    filterData.status = '';
    filterData.type = '';
    getListData();
  };

  return (
    <div>
      <div className="top-0">
        <ButtonPrimary
          className="w-auto !pr-16"
          sizeClass="pl-4 py-2.5 sm:pl-6"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <svg
            className={`w-4 h-4 sm:w-6 sm:h-6`}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.87 17.12L18.87 16.12"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span className="block truncate ml-2.5">Filter</span>
          <span className="absolute top-1/2 -translate-y-1/2 right-5">
            <ChevronDownIcon
              className={`w-4 h-4 sm:w-5 sm:h-5 ${isOpen ? "rotate-180" : ""
                }`}
              aria-hidden="true"
            />
          </span>
        </ButtonPrimary>
      </div>

      <Transition
        show={isOpen}
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full px-3 md:px-5 py-7 rounded rounded-2xl bg-white border shadow-sm dark:bg-opacity-90 mb-4 mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-3 relative">
            <div className="flex flex-col justify-center">
              <span className="capitalize text-blue-gray-900 font-normal pb-2">Date</span>
              <DateRangePicker
                className=" border text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={onChange}
                value={value}
              />
            </div>
            <div className="flex flex-col justify-center">
              <span className="capitalize text-blue-gray-900 font-normal pb-2">Status</span>
              <select id="countries" className=" border text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
               onChange={(e) => {
                handleStatusChange(e);
              }}
              value={status}
              >
                <option value="" >Select Status</option>
                <option value="Pending">Pending</option>
                <option value="revisedQuote">Revised Quote</option>
                <option value="followUp">Follow Up</option>
                <option value="orderConfirmed">Order Confirmed</option>
              </select>
            </div>
            <div className="flex flex-col justify-center">
              <span className="capitalize text-blue-gray-900 font-normal pb-2">Type</span>
              <select className=" border text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
               onChange={(e) => {
                handleTypeChange(e);
              }}
              value={type}
              >
                <option value="" >Select Type</option>
                <option value="requestDiscount">Discount Requested</option>
                <option value="confirm">Confirmed</option>
              </select>
            </div>
            <div className="flex lg:justify-start 2xl:justify-center">
              <button type="button" className="bg-black text-white rounded-md p-3 md:mt-8 m-2"
               onClick={handleFilter}
               >
                Filter
              </button>
              <button type="button" className="bg-black text-white rounded-md p-3 md:mt-8 m-2"
               onClick={handleClearFilter}
               >
                Clear
              </button>
            </div>
          </div>
        </div>

      </Transition>
    </div>
  );
};

export default AdminHeaderFilterSearchPage;
