import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment, useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import {
  clearData,
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
} from "store/AppMaster/actions";
import api from "constants/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isImageJpgOrPng, isImageSizeValid, callMultipartFormDataApi } from "components/Common/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { set } from "lodash";
toastr.options = {
  positionClass: "toast-top-right",
  timeOut: 5000,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  showDuration: 300,
  hideDuration: 1000
};

export interface CreateProps {
  // getGroupDetails: () => void;
  // updatePriceProduct?: any;
  // setUpdatePriceProduct?: any;
  setShowRevisedQuoteModal?: any;
  setIsApiCall?: any;
  cartData?: any;
  setCartData?: any;
  enquiryId?: any;

}
const RevisedQuoteModal: FC<CreateProps> = ({
  // onCloseModalQuickView,
  // getGroupDetails,
  // updatePriceProduct,
  // setUpdatePriceProduct,
  cartData,
  setCartData,
  setShowRevisedQuoteModal,
  setIsApiCall,
  enquiryId
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [updatePriceProduct, setUpdatePriceProduct] = useState<any>({
    codeDetails: "",
    productId: "",
    productPrice: "",
    productDiscount: "",
  });
  const [originalItem, setOriginalItem] = useState<any>([]);
  const [error, setError] = useState<any>(false);
  const [errorIdsAndCodes, setErrorIdsAndCodes] = useState<any>([]);
  const [Price, setPrice] = useState<any>(0);
  const [totalDiscount, setTotalDiscount] = useState<any>(0);
  const [totalPrice, setTotalPrice] = useState<any>(0);




  const onChangeHandler = (event: any, item: any) => {
    const updatedErrorIdsAndCodes = errorIdsAndCodes.filter((errorItem: any) => !(errorItem.id === item.id && errorItem.code === item.code));
    setErrorIdsAndCodes([...updatedErrorIdsAndCodes]);

    const { name, value } = event.target;


    setCartData((preValue: any) => {
      let data = preValue.map((row: any) => {
        if (row.id === item.id && row.code === item.code) {
          originalItem.push(row);
          setOriginalItem([...originalItem]);
          let newRowRow = {};
          if (item.type === 'standard') {
            newRowRow = {
              price: name === 'productPrice' ? value : row.row.price,
              discount: name === 'productDiscount' ? value : row.row.discount,
            };
          } else if (item.type === 'economic') {
            newRowRow = {
              economic_price: name === 'productEconPrice' ? value : row.row.economic_price,
              economic_discount: name === 'productEconDiscount' ? value : row.row.economic_discount,
            };
          }else if (item.type === 'pending') {
            newRowRow = {
              price: name === 'productPrice' ? value : row.row.price,
              discount: name === 'productDiscount' ? value : row.row.discount,
              economic_price: name === 'productEconPrice' ? value : row.row.economic_price,
              economic_discount: name === 'productEconDiscount' ? value : row.row.economic_discount,
            };
          }
          row.row = {
            ...row.row,
            ...newRowRow
          };
        }
        return row;
      });
      return data;
    });
  };



  const handleSubmit = () => {
    // const isError = cartData.some((row: any) => {
    //   let isRowError = false;
    //   if (row.type === 'standard' && row.row.price < row.row.discount) {
    //     isRowError = true;
    //   } else if (row.type === 'economic' && row.row.economic_price < row.row.economic_discount) {
    //     isRowError = true;
    //   }
    //   if (isRowError) {
    //     errorIdsAndCodes.push({ id: row.id, code: row.code });
    //     setErrorIdsAndCodes([...errorIdsAndCodes]);
    //   }
    //   return isRowError;
    // });

    // if (isError) {
    //   setCartData((prevCartData: any) => {
    //     return prevCartData.map((row: any) => {
    //       const originalRow = originalItem.find((originalRow: any) => originalRow.id === row.id && originalRow.code === row.code);
    //       return originalRow ? originalRow : row;
    //     });
    //   });
    // } else {
    setErrorIdsAndCodes([]);
    const updateData = {
      id: enquiryId,
      cart_items: cartData,
      status: 'revisedQuote',

    }
    dispatch(onUpdateData(updateData, '', api.frontUpdateEnquiryStatusAndCart))
    setShowRevisedQuoteModal(false);
    setIsApiCall(0);
    // }
  };

  useEffect(() => {
    if (cartData) {

      let localPrice = 0;
      let localTotalDiscount = 0;

      cartData.forEach((item: any) => {
        let price = 0;
        let discount = 0;
        let econ_price = 0;
        let econ_discount = 0;
        if (item.type === 'standard') {
          price = parseFloat(item.row.price);
          discount = parseFloat(item.row.discount);
        } else if (item.type === 'economic') {
          econ_price = parseFloat(item.row.economic_price);
          econ_discount = parseFloat(item.row.economic_discount);
        }else if (item.type === 'pending') {
          price = parseFloat(item.row.price);
          discount = parseFloat(item.row.discount);
          econ_price = parseFloat(item.row.economic_price);
          econ_discount = parseFloat(item.row.economic_discount);
        }
        let discountedPrice = (price * (discount / 100));
        let discountedEconPrice = (econ_price * (econ_discount / 100));

        localPrice += price + econ_price; // Accumulating the discounted price
        localTotalDiscount += discountedPrice + discountedEconPrice; // Accumulating the total discount
      });
      localPrice = localPrice * 1;
      // @ts-ignore
      localPrice = localPrice.toFixed(2);
      setPrice(localPrice);
      localTotalDiscount = localTotalDiscount * 1;
      // @ts-ignore
      localTotalDiscount = localTotalDiscount.toFixed(2);
      setTotalDiscount(localTotalDiscount);
      let localTotalPrice = localPrice - localTotalDiscount;
      // @ts-ignore
      localTotalPrice = localTotalPrice.toFixed(2);
      setTotalPrice(localTotalPrice);
    }
  }, [cartData]);
  return (


    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ">
      <div className="bg-white w-96 rounded-lg shadow-xl w-[80%]">
        <form onSubmit={e => {
          e.preventDefault();
          // console.log(updatePriceProduct);
          handleSubmit();

          return false;
        }}>
          {/* Header */}
          <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200">
            <h2 className="text-lg font-semibold">Edit Quotation</h2>
            <button
              className="text-gray-500 hover:text-gray-700 transition-all"
              onClick={(e) => { setShowRevisedQuoteModal(false) }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          {/* Body */}
          <div className="p-4">
            {cartData && cartData.map((item: any, index: any) => (
             <>
                {item.type == 'standard' && (
                  <div className="md:flex block items-center justify-evenly md:gap-5 xl:gap-0">
                    <div className="mb-3 md:mb-0">
                      <label htmlFor="code" className="block text-sm font-medium text-gray-700">Code</label>
                      <input
                        id="code"
                        type="text"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        disabled
                        value={item.code}
                      />
                    </div>

                    <div className="mb-3 md:mb-0">
                      <label htmlFor="productPrice" className="block text-sm font-medium text-gray-700">Product Price</label>
                      <input
                        id="productPrice"
                        type="text"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        onChange={(e) => { onChangeHandler(e, item) }}
                        name="productPrice"
                        value={item.row.price}
                      />
                      {(errorIdsAndCodes.some((row: any) => row.id === item.id && row.code === item.code) ? (
                        <small className="text-rose-600">Price cannot be less than discount.</small>
                      ) : null)}
                    </div>

                    <div>
                      <label htmlFor="productPrice" className="block text-sm font-medium text-gray-700">Product Discount(%)</label>
                      <input
                        id="productPrice"
                        type="text"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        onChange={(e) => { onChangeHandler(e, item) }}
                        name="productDiscount"
                        value={item.row.discount}
                      />
                    </div>
                  </div>
                )}

                {item.type == 'economic' && (
                   <div className="md:flex block items-center justify-evenly md:gap-5 xl:gap-0">
                    <div className="mb-3 md:mb-0">
                      <label htmlFor="code" className="block text-sm font-medium text-gray-700">Code</label>
                      <input
                        id="code"
                        type="text"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        disabled
                        value={item.row.economic_code}
                      />
                    </div>

                    <div className="mb-3 md:mb-0">
                      <label htmlFor="productEconPrice" className="block text-sm font-medium text-gray-700">Product Price</label>
                      <input
                        id="productEconPrice"
                        type="text"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        onChange={(e) => { onChangeHandler(e, item) }}
                        name="productEconPrice"
                        value={item.row.economic_price}
                      />
                      {(errorIdsAndCodes.some((row: any) => row.id === item.id && row.code === item.code) ? (
                        <small className="text-rose-600">Price cannot be less than discount.</small>
                      ) : null)}
                    </div>

                    <div>
                      <label htmlFor="productPrice" className="block text-sm font-medium text-gray-700">Product Discount(%)</label>
                      <input
                        id="productPrice"
                        type="text"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        onChange={(e) => { onChangeHandler(e, item) }}
                        name="productEconDiscount"
                        value={item.row.economic_discount}
                      />
                    </div>
                  </div>
                )}

                {item.type == 'pending' && (
                  <>
                  <div className="md:flex block items-center justify-evenly md:gap-5 xl:gap-0">
                    <div className="mb-3 md:mb-0">
                      <label htmlFor="code" className="block text-sm font-medium text-gray-700">Code</label>
                      <input
                        id="code"
                        type="text"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        disabled
                        value={item.code}
                      />
                    </div>

                    <div className="mb-3 md:mb-0">
                      <label htmlFor="productPrice" className="block text-sm font-medium text-gray-700">Product Price</label>
                      <input
                        id="productPrice"
                        type="text"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        onChange={(e) => { onChangeHandler(e, item) }}
                        name="productPrice"
                        value={item.row.price}
                      />
                      {(errorIdsAndCodes.some((row: any) => row.id === item.id && row.code === item.code) ? (
                        <small className="text-rose-600">Price cannot be less than discount.</small>
                      ) : null)}
                    </div>

                    <div>
                      <label htmlFor="productPrice" className="block text-sm font-medium text-gray-700">Product Discount(%)</label>
                      <input
                        id="productPrice"
                        type="text"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        onChange={(e) => { onChangeHandler(e, item) }}
                        name="productDiscount"
                        value={item.row.discount}
                      />
                    </div>
                  </div>
                    <div className="md:flex block items-center justify-evenly md:gap-5 xl:gap-0">
                      <div className="mb-3 md:mb-0">
                        <label htmlFor="code" className="block text-sm font-medium text-gray-700">Code</label>
                        <input
                          id="code"
                          type="text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          disabled
                          value={item.row.economic_code}
                        />
                      </div>

                      <div className="mb-3 md:mb-0">
                        <label htmlFor="productEconPrice" className="block text-sm font-medium text-gray-700">Product Price</label>
                        <input
                          id="productEconPrice"
                          type="text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(e) => { onChangeHandler(e, item) }}
                          name="productEconPrice"
                          value={item.row.economic_price}
                        />
                        {(errorIdsAndCodes.some((row: any) => row.id === item.id && row.code === item.code) ? (
                          <small className="text-rose-600">Price cannot be less than discount.</small>
                        ) : null)}
                      </div>

                      <div>
                        <label htmlFor="productPrice" className="block text-sm font-medium text-gray-700">Product Discount(%)</label>
                        <input
                          id="productPrice"
                          type="text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(e) => { onChangeHandler(e, item) }}
                          name="productEconDiscount"
                          value={item.row.economic_discount}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            ))}
            <div className="md:flex block justify-between w-full max-w-xl p-4 mt-5 mx-auto border border-gray-300 rounded-lg bg-white shadow-md">
              <div className="text-lg font-bold text-black mb-2">Price: {isNaN(Price) ? '' : Price}</div>
              <div className="text-lg font-bold text-black mb-2">Discount: {isNaN(totalDiscount) ? '' : totalDiscount}</div>
              <div className="text-lg font-bold text-black mb-2">Total Price: {isNaN(totalPrice) ? '' : totalPrice}</div>
            </div>
            <div className="text-end mt-5 ">
              <ButtonPrimary type="submit">
                <span>Submit</span>
              </ButtonPrimary>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RevisedQuoteModal