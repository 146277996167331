import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment, useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import {
    clearData,
    getList as onGetList,
    updateData as onUpdateData,
    addNewData as onAddNewData,
} from "store/AppMaster/actions";
import api from "constants/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isImageJpgOrPng, isImageSizeValid, callMultipartFormDataApi } from "components/Common/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
toastr.options = {
  positionClass: "toast-top-right",
  timeOut: 5000,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  showDuration: 300,
  hideDuration: 1000
};

export interface CreateProps {
    getGroupDetails: () => void;
    updatePriceProduct?: any;
    setUpdatePriceProduct?: any;
    setShow?: any;
    setIsApiCall? : any;
}

const UpdatePriceModal: FC<CreateProps> = ({
    // onCloseModalQuickView,
    getGroupDetails,
    updatePriceProduct,
    setUpdatePriceProduct,
    setShow,
    setIsApiCall,
}) => {
    const location = useLocation();
    const dispatch = useDispatch();


    const onChangeHandler = (event: any) => {


        const { name, value } = event.target;
        setUpdatePriceProduct((preValue: any) => {
            return {
                ...preValue,
                [name]: value,
            };
        });
    }



    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            codeDetails: updatePriceProduct.codeDetails || "",
            productId: updatePriceProduct.productId || "",
            productPrice: updatePriceProduct.productPrice,
            productDiscount: updatePriceProduct.productDiscount || "",
            economicCode: updatePriceProduct.economicCode || "",
            economicPrice: updatePriceProduct.economicPrice || "",
            economicDiscount: updatePriceProduct.economicDiscount || ""
        },
        validationSchema: Yup.object({
            productPrice: Yup.number()
            .typeError("Please enter a number")
            .required("Please enter Price")
        }),
        onSubmit: async values => {
            const newData = {
                codeDetails: updatePriceProduct.codeDetails || "",
                productId: updatePriceProduct.productId || "",
                productPrice: updatePriceProduct.productPrice || "",
                productDiscount: updatePriceProduct.productDiscount || "",
                economicCode: updatePriceProduct.economicCode || "",
                economicPrice: updatePriceProduct.economicPrice || "",
                economicDiscount: updatePriceProduct.economicDiscount || ""
            };
            //  dispatch(onAddNewData(newData, "", api.createCategory))
            // console.log(newData);

            const url = process.env.REACT_APP_API_URL + api.productGroupSinglePriceUpdate
            const headers = {
                'Content-Type': 'application/json',
                // @ts-ignore
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("AuthUser"))}`
              };
              const options={headers:headers}
            try {
                const response = await axios.post(url, newData, options);

                if (response.data.statusCode === 200) {
                  toastr.success(response.data.message);
                //   getGroupDetails();
                //   setIsApiCall(0)
                  setShow(false);
                  window.location.reload();

                } else {
                  toastr.error(response.data.message);
                }

                return response.data.statusCode;
              } catch (error) {
                console.error('API Error:', error);
                toastr.error('An error occurred while processing your request');
                throw error; // Rethrow the error
              }

        },
    });

    return (


        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white w-96 rounded-lg shadow-xl">
            <form   onSubmit={e => {
                      e.preventDefault();
                      validation.handleSubmit();
                    // console.log(updatePriceProduct);

                      return false;
                  }}>
                {/* Header */}
                <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200">
                    <h2 className="text-lg font-semibold">Add Price & Discount</h2>
                    <button
                        className="text-gray-500 hover:text-gray-700 transition-all"
                        onClick={(e) => { setShow(false) }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                {/* Body */}
                <div className="p-4">
                    {/* Code Input */}
                    <div className="mb-4">
                        <label htmlFor="code" className="block text-sm font-medium text-gray-700">Code</label>
                        <input
                            id="code"
                            type="text"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            disabled
                            value={updatePriceProduct.codeDetails.A ? updatePriceProduct.codeDetails.A : updatePriceProduct.codeDetails.B || ""}
                        />
                    </div>
                    {/* Product Price Input */}
                    <div className="mb-4">
                        <label htmlFor="productPrice" className="block text-sm font-medium text-gray-700">Product Price</label>
                        <input
                            id="productPrice"
                            type="text"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            onChange={onChangeHandler}
                            name="productPrice"
                        />
                        {validation.errors.productPrice ? (
                            // @ts-ignore
                            <small className="text-rose-600">{validation.errors.productPrice}</small>
                        ) : null}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="productDiscount" className="block text-sm font-medium text-gray-700">Product Discount</label>
                        <input
                            id="productDiscount"
                            type="text"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            onChange={onChangeHandler}
                            name="productDiscount"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="economicCode" className="block text-sm font-medium text-gray-700">Economic Code</label>
                        <input
                            id="economicCode"
                            type="text"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            onChange={onChangeHandler}
                            name="economicCode"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="economicPrice" className="block text-sm font-medium text-gray-700">Economic Price</label>
                        <input
                            id="economicPrice"
                            type="text"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            onChange={onChangeHandler}
                            name="economicPrice"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="economicDiscount" className="block text-sm font-medium text-gray-700">Economic Discount</label>
                        <input
                            id="economicDiscount"
                            type="text"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            onChange={onChangeHandler}
                            name="economicDiscount"
                        />
                    </div>
                    <div className="text-center mt-3">
                        <ButtonPrimary type="submit">
                            <span>Submit</span>
                        </ButtonPrimary>
                    </div>
                </div>
            </form>
        </div>
    </div>





    );
};

export default UpdatePriceModal;


