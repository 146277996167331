import PropTypes from "prop-types"
import React, { useEffect, useState, FC } from "react"
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import api from "constants/api"
import withRouter from "components/Common/withRouter"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getData, loginUser } from "store/AppMaster/actions"

// Formik validation
import { useFormik,Formik } from "formik"
import * as Yup from "yup"


const AdminLogin: FC<any> = props => {


   const dispatch = useDispatch();

   // Form validation
   const validation = useFormik({
      initialValues: {
        email: "",
        password:"",
      },
     validationSchema: Yup.object({
       email: Yup.string()
         .email("Must be a valid Email")
         .max(255)
         .required("Email is required"),
       password: Yup.string().required("Please Enter Your Password"),
     }),
     onSubmit: values => {
       const loginData = {
         email: values.email,
         password: values.password
       }
       dispatch(loginUser(loginData, props.router.navigate, api.loginUser))
       validation.resetForm()
     },
   })

  return (
    <div className={`nc-PageLogin`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                name="email"
                placeholder="example@example.com"
                className="mt-1"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ""}
              />
              {validation.errors.email ? (
                            <small className="text-rose-600">{validation.errors.email}</small>
                        ) : null}
            </label>
            <label className="block">
              <Input type="password"
                className="mt-1"
                name="password"
                value={
                  validation.values.password || ""
                }
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
               />
               {validation.errors.password ? (
                            <small className="text-rose-600">{validation.errors.password}</small>
                        ) : null}
            </label>
            <ButtonPrimary type="submit">Login</ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AdminLogin);

AdminLogin.propTypes = {
  history: PropTypes.object,
}
