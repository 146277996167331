import axios from "axios";
import { isEmpty } from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
toastr.options = {
  positionClass: "toast-top-right",
  timeOut: 5000,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  showDuration: 300,
  hideDuration: 1000
};
export function isImageJpgOrPngOrPdf(dataURL) {
  try {
    return dataURL.type=="image/jpeg" || dataURL.type=="image/jpg" || dataURL.type=="image/png" || dataURL.type=="application/pdf";
  } catch (err) {
    return false;
  }
}
export function isImageJpgOrPng(dataURL) {
  try {
    return dataURL.type=="image/jpeg" || dataURL.type=="image/jpg" || dataURL.type=="image/png";
  } catch (err) {
    return false;
  }
}
export function isSheet(dataURL) {
  try {
    return dataURL.type=="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  } catch (err) {
    return false;
  }
}
export function isImageSizeValid(dataURL, minWidth, minHeight) {
  const img = new Image();
  img.src = dataURL;

  return new Promise((resolve) => {
    img.onload = () => {
      if (img.width >= minWidth && img.height >= minHeight) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
  });
}
export function isBase64(dataURL) {
  try {
    return btoa(atob(dataURL)) === dataURL;
  } catch (err) {
    return false;
  }
}
export function formateDate(date, format) {
  const dateFormat = format ? format : "DD-MM-YYYY";
  const date1 = moment(new Date(date)).format(dateFormat);
  return date1;
};
export function isVideoValidation(dataURL) {
  try {
    return dataURL.startsWith('data:video/');
  } catch (err) {
    return false;
  }
}
export function getDates(startDate, endDate) {
  const dateArray = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}
export function formateTime(time, format) {
  const timeFormat = format ? format : "HH:mm";
  const time1 = moment(new Date(time)).format(timeFormat);
  return time1;
}
export function isPDF(base64) {
  const header = base64.substring(0, 25);
  return header.includes('data:application/pdf') || header.includes('data:application/octet-stream');
}

export function isDOC(base64) {
  const header = base64.substring(0, 20);
  return header.includes('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
    header.includes('data:application/msword') || header.includes('data:application/msw');
}

export function changeThemeColor(newPrimaryColorCode, newSecondaryColor) {
  const root = document.documentElement;
  if (!isEmpty(newPrimaryColorCode) && newPrimaryColorCode != null) {
    root.style.setProperty('--primary-color', newPrimaryColorCode);
    root.style.setProperty('--bs-primary-rgb', newPrimaryColorCode);
  } else {
    root.style.setProperty('--primary-color', '#013281');
    root.style.setProperty('--bs-primary-rgb', '#013281');
  }
  if (!isEmpty(newSecondaryColor) && newSecondaryColor != null) {
    root.style.setProperty('--secondary-color', newSecondaryColor);
    root.style.setProperty('--bs-secondary-rgb', newSecondaryColor);
  } else {
    root.style.setProperty('--primary-color', '#FF9A4A');
    root.style.setProperty('--bs-primary-rgb', '#FF9A4A');
  }
}

export async function callMultipartFormDataApi(apiUrl, formData) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem("AuthUser"))}`
  };

  const options = {
    headers: headers,
    maxContentLength: 1024 * 1024 * 50, // Example: Set to 50 MB
    maxBodyLength: 1024 * 1024 * 50, // Example: Set to 50 MB
  };

  try {
    const response = await axios.post(apiUrl, formData, options);

    if (response.data.statusCode === 200) {
      toastr.success(response.data.message);
    } else if(response.data.statusCode === 400) {
      toastr.error(response.data.message);
    }else{
      toastr.error(response.data.message);
    }

    return response.data.statusCode;
  } catch (error) {
  //  console.error('API Error:', error.response.data.message);
    toastr.error( error.response.data.message);
    throw error; // Rethrow the error
  }
}


export async function presignedUrl(apiUrl, formData) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem("AuthUser"))}`
  };

  const options = {
    headers: headers
  };

  try {
    const response = await axios.post(apiUrl, formData, options);
    return response.data.data;
  } catch (error) {
    toastr.error( error.response.data.message);
    throw error; // Rethrow the error
  }
}

export async function validateOTP(apiUrl, formData) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem("AuthUser"))}`
  };

  const options = {
    headers: headers
  };

  try {
    const response = await axios.post(apiUrl, formData, options);
    return response.data.data;
  } catch (error) {
    toastr.error( error.response.data.message);
    throw error; // Rethrow the error
  }
}

export async function uploadFileToS3(file, presignedUrl,type="file") {
  try {
    const response = await axios.put(presignedUrl, file, {
      headers: {
        'Content-Type': (type=="file")? file.type : "binary/octet-stream",
      },
    });
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error('Server responded with:', error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Request setup error:', error.message);
    }
    console.error('Axios error:', error); // Log the full error object for debugging
  }
}

export function encodeString(str) {
  if (str == null || str == undefined || str == '') {
    return '';
  }
  return btoa(str);
}
export function decodeString(str) {
  if (str == null || str == undefined || str == '') {
    return '';
  }
  return atob(str);
}