import { Dialog, Transition } from "@headlessui/react";
import React, { useState, useEffect, FC, Fragment } from "react";
import { useLocation } from "react-router-dom";
import SkewLoader from "react-spinners/SkewLoader";


export interface loaderProps {
  loderImage: boolean;
}

const Loader: FC<loaderProps> = ({
  loderImage
}) => {
const [color, setColor] = useState("#2a3491");

  return (
    <div>
      {loderImage && (
        <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
          <div className="absolute z-50">
            <SkewLoader color={color} loading={loderImage} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Loader;
