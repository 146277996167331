import React, { FC, useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useParams,Navigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Datatable from "./Datatable";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData
} from "store/AppMaster/actions";
import api from "constants/api";
import { RootState } from 'store/reducers';
import Edit from "./Edit";

export interface ViewProps {
  data: any;
  result: any[]
}


const View: FC<ViewProps> = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [isApiCall, setIsApiCall] = useState(0);
  const [categoryData, setData] = useState([]);


  useEffect(() => {
    if (isApiCall == 0) {
      getCategoyData();
      setIsApiCall(1);
    }
  }, [isApiCall]);

  const { data } = useSelector((state: RootState) => state.AppMaster);
  const getCategoyData = () => {
    let filterData = {
      id: params.id
    }
    dispatch(onGetData(filterData, '', api.getCustomerDetail));
  }

  if (data != null) {
    if (data.length != 0) {
      setData(data.result);
    }
  }

  return (
    <div className="container py-16 lg:pb-28 lg:pt-20">
      <Helmet>
        <title>User Details</title>
      </Helmet>
      <div className="mb-12 sm:mb-16">
        <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">User Details</h2>
      </div>
      <div className="mt-8 sm:mt-10 mb-8 sm:mb-10 listingSectionSidebar__wrap shadow-lg border-0 p-8 rounded-2xl">
        <div className="grid gap-4 md:grid-cols-4 grid-cols-1  text-left">
          <div className="text-2xl font-semibold">sonia</div>
          <div>
            <span className="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset  bg-green-50 text-green-600 ring-green-500/10">Admin</span>
            </div>
            <div className="text-base font-semibold flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" viewBox="0 0 512 512"><path d="M497.4 361.8l-112-48a24 24 0 0 0 -28 6.9l-49.6 60.6A370.7 370.7 0 0 1 130.6 204.1l60.6-49.6a23.9 23.9 0 0 0 6.9-28l-48-112A24.2 24.2 0 0 0 122.6 .6l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.3 24.3 0 0 0 -14-27.6z"/></svg>
              9876543210
              </div>
          <div className="text-base font-semibold flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" viewBox="0 0 512 512"><path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7 .3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2 .4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"/></svg>
            sonia@yopmail.com</div>

        </div>
      </div>
    </div>
  );
};

export default withRouter(View);
