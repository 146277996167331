import { Dialog, Transition } from "@headlessui/react";
import React, { FC,Fragment, useEffect, useState,useMemo } from "react";
import { useLocation } from "react-router-dom";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isImageJpgOrPng, isImageSizeValid, callMultipartFormDataApi, isSheet, isImageJpgOrPngOrPdf,presignedUrl,uploadFileToS3 } from "components/Common/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import {
    clearData,
    getList as onGetList,
     updateData as onUpdateData,
    addNewData as onAddNewData,
  } from "store/AppMaster/actions";
import api from "constants/api";
import Loader from "containers/Admin/Loader";
export interface CreateProps {
  show: boolean;
  onCloseModalQuickView: () => void;
  getGroupDetails: () => void;
  indexData:any;
  id:any;
  title:any;
}

const UploadImage: FC<CreateProps> = ({
  show,
  onCloseModalQuickView,
  getGroupDetails,
  indexData,
  id,
  title
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loderImage, setLoader] = useState(false);
  const [frmData,setFrmData] = useState({
        id:id,
        productDataObjectIndex:indexData,
        image:"",
  });

  const handleFileChange =async (event:any) =>{
    const file =event.target.files[0];
    try {
        // Get pre-signed URL from the backend
        const apiUrl = process.env.REACT_APP_API_URL + api.presignedUrl
        title = title;

        const newData = {
            filename: file.name,
            filetype: file.type,
            folder: "3d_file/"+title
        };
        let url = await presignedUrl(apiUrl,newData);
        setLoader(true);
        // Upload the file to S3
        uploadFileToS3(file,url.data,"3d")
        .then((data) => {
            setLoader(false);
        })
        .catch((error) => {
            setLoader(false);
        });

        setFrmData((preValue) => {
            return {
                ...preValue,
                image: url.filename,
            };
        });

    } catch (error) {
        console.error('Error uploading file:', error);
        alert('Failed to upload file');
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
        image: (frmData && frmData.image) || "",
    },
    validationSchema: Yup.object({
        image: Yup.string().required("Please Select Image")
    }),
    onSubmit: async values => {
        const newData = {
            id: id,
            productDataObjectIndex: indexData,
            image: values["image"]
        };
        const url=process.env.REACT_APP_API_URL+api.productUploadImage
        const result = await callMultipartFormDataApi(url, newData);
        onCloseModalQuickView();
        if(result==200){
            window.location.reload();
        }
    },
  });

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50"
        onClose={onCloseModalQuickView}
      >
        <Loader
                loderImage={loderImage}
            />
        <div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-flex xl:py-8 w-full max-w-5xl max-h-full">
              <div
                className="flex-1 flex overflow-hidden max-h-full p-8 w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white
                dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl"
              >
                <span className="absolute right-3 top-3 z-50">
                  <ButtonClose onClick={onCloseModalQuickView} />
                </span>

                <div className="flex-1  rounded-xl hiddenScrollbar">
                  <h2 className="text-2xl font-semibold hover:text-primary-6000 transition-colors mb-2.5">Upload 3D File</h2>
                  <form className="grid grid-cols-2 gap-6"
                     onSubmit={e => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                  }}
                  method="post">
                    <label className="block">
                      <Input
                        type="file"
                        name="image"
                        placeholder=""
                        className="mt-1"
                        onChange={handleFileChange}
                      />
                      {validation.errors.image ? (
                            <small className="text-rose-600">{validation.errors.image}</small>
                        ) : null}
                    </label>
                    <div className="text-right mt-4">
                    <ButtonPrimary type="submit">Upload</ButtonPrimary>
                   </div>
                  </form>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UploadImage;
