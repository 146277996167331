import { Helmet } from "react-helmet-async";
import ProductCard from "components/ProductCard";
import SidebarFilters from "./SidebarFilters";
import React, { FC, useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  getList as onGetList,
  clearData
} from "store/AppMaster/actions";
import api from "constants/api";
import { RootState } from 'store/reducers';
import SkewLoader from "react-spinners/SkewLoader";
import { list } from "@material-tailwind/react";
import { set } from "lodash";
import Pagination from "shared/Pagination/Pagination";
export interface PageCollectionProps {
  className?: string;
}

const PageCollection: FC<PageCollectionProps> = ({ className = "" }) => {

  const [color, setColor] = useState("#2A3491");

  const dispatch = useDispatch();
  const params = useParams();

  let categoryData = localStorage.getItem("categoryData")

  // Parse the JSON string into an array of objects
  let categorySlugData = categoryData ? JSON.parse(categoryData) : [];

  // Specify the slug you want to find
  let targetSlug = params.slug;

  // Find the object with the matching slug
  let foundCategory = categorySlugData.find((category: any) => category.slug === targetSlug);

  const [isApiCall, setIsApiCall] = useState(0)
  const [listdata, setListData] = useState([])
  const [updateListData, setUpdateListData] = useState(false);
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(18)
  const [prevCategoryId, setPrevCategoryId] = useState('')
  const [listDataLength, setListDataLength] = useState(0);
  const [makeScrollApiCall, setMakeScrollApiCall] = useState(true)
  const [filterCategoryData, setFilterData] = useState({
    start: 0,
    limit: limit,
    search: '',
    category: '',
    sort_by: "_id",
    sort_order: "asc",
    page: page,
  });
  useEffect(() => {
    getCategoryData();

  }, [params, page])
  const [productCount, setProductCount] = useState(0);


  const { data } = useSelector((state: RootState) => state.AppMaster);
  const { loading } = useSelector((state: RootState) => state.AppMaster);


  const getListData = (foundCategoryId?: any) => {

    if (prevCategoryId && foundCategoryId && (prevCategoryId !== foundCategoryId)) {

      setUpdateListData(true);
      dispatch(clearData());

      let filter = {
        start: filterCategoryData.start,
        limit: filterCategoryData.limit,
        search: '',
        category: foundCategoryId,
        sort_by: "_id",
        sort_order: "asc",
        page: filterCategoryData.page,
      };

      setPrevCategoryId(foundCategoryId);

      setPage(1)
      dispatch(onGetList(filter, '', api.frontProductGroup));

    } else if (foundCategoryId) {

      setUpdateListData(true);
      dispatch(clearData());


      let filter = {
        start: filterCategoryData.start,
        limit: filterCategoryData.limit,
        search: '',
        category: foundCategoryId,
        sort_by: "_id",
        sort_order: "asc",
        page: filterCategoryData.page,
      };

      setPrevCategoryId(foundCategoryId)

      dispatch(onGetList(filter, '', api.frontProductGroup));
    }
  }

  const getCategoryData = async () => {

    let categoryDataString = localStorage.getItem("categoryData")
    let categoryData = categoryDataString ? JSON.parse(categoryDataString) : [];
    if (categoryData.length == 0) {
      const url = process.env.REACT_APP_API_URL + api.frontCategory
      const headers = {
        'Content-Type': 'application/json',
        // @ts-ignore
        // 'Authorization': `Bearer ${JSON.parse(localStorage.getItem("AuthUser"))}`
      };
      const options = { headers: headers }
      try {
        const response = await axios.post(url, options);

        if (response.data.statusCode === 200) {


          localStorage.setItem("categoryData", JSON.stringify(response.data.data.category));

          // Specify the slug you want to find
          targetSlug = params.slug;

          // Find the object with the matching slug
          foundCategory = response.data.data.category.find((category: any) => category.slug === targetSlug);

          setFilterData(prev => (
            {
              ...prev,
              category: foundCategory._id,
            }
          ))
        } else {
        }
        getListData(foundCategory._id);
        return;
      } catch (error) {
        console.error('API Error:', error);
        throw error; // Rethrow the error
      }
    }

    else {
      foundCategory = categoryData.find((category: any) => category.slug === targetSlug);

      setFilterData(prev => (
        {
          ...prev,
          category: foundCategory._id,
        }
      ))
      getListData(foundCategory._id);

    }
  }


  if (data != null && updateListData == true) {
    // console.log(data);

    if (data.length != 0 && data.result.length > 0) {
      setUpdateListData(false);
      // setListData(data.result);
      // console.log(data.result);
      // setMakeScrollApiCall(true)
      setProductCount(data.total_count);


      // Check if listData has items and if their category names match
      // @ts-ignore

      if (listdata.length > 0 && listdata[0].category[0].name === data.result[0].category[0].name) {
        // @ts-ignore

        //setListData((prev: any) => ([...prev, ...data.result]));
        setListData(data.result);
        // @ts-ignore
        // console.log(listdata[0].category[0].name, data.result[0].category[0].name,);


      } else {
        setListData(data.result);
        // console.log('hi');

      }


    }
  }


  const handelInfiniteScroll = async () => {
    // console.log("scrollHeight" + document.documentElement.scrollHeight);
    // console.log("innerHeight" + window.innerHeight);
    // console.log("scrollTop" + document.documentElement.scrollTop);
    // console.log(data);

    try {

      setPage((prev) => prev + 1);


      // console.log('changing');


    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className={`nc-PageCollection ${className}`}
      data-nc-id="PageCollection"
    >
      <Helmet>
        <title>{foundCategory ? foundCategory.name : ''}  | Vijaydeep</title>
        <meta
          name="title"
          content={foundCategory ? foundCategory.meta_title : ''}
        />
        <meta
          name="title"
          content={foundCategory ? foundCategory.meta_description : ''}
        />
      </Helmet>
      {loading && (
        <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
          <div className="absolute z-50">
            <SkewLoader color={color} loading={loading} />
          </div>
        </div>
      )}

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="max-w-screen-sm">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
              {foundCategory && foundCategory.name}
            </h2>
            {/*   <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
              We not only help you design exceptional products, but also make it
              easy for you to share your designs with more like-minded people.
            </span> */}
          </div>

          <hr className="border-slate-200 dark:border-slate-700" />
          <main>
            {/* LOOP ITEMS */}
            <div className="flex flex-col lg:flex-row">
              <div className="lg:w-1/3 xl:w-1/4 pr-4">
                <SidebarFilters
                  getListData={getListData}
                  filterCategoryData={filterCategoryData}
                />
              </div>
              <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mx-4 border-t lg:border-t-0"></div>
              <div className="flex-1 ">
                <div className="flex-1 grid sm:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-10 ">
                  {listdata && listdata.length > 0 ? (
                    listdata.map((item, index) => (
                      <ProductCard data={item} key={index} />
                    ))
                  ) : (
                    <p className="">No data found</p>
                  )}
                </div>
                <div className="flex justify-left items-center mt-10 gap-x-10">
                  {/* <button className="text-black bg-white border border-black rounded-3xl px-4 py-2 shadow-md hover:bg-gray-200" onClick={handelInfiniteScroll}>Load More</button> */}
                  <Pagination
                   filterCategoryData={filterCategoryData}
                   getListData={getListData}
                   listCount={productCount}
                  />
                </div>

              </div>

            </div>

          </main>
        </div>
      </div>
    </div>
  );
};

export default PageCollection;
