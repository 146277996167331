import React, { FC, useEffect, useState,useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Datatable from "./Datatable";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData
} from "store/AppMaster/actions";
import api from "constants/api";
import { RootState } from 'store/reducers';
import {
  Typography,
  Button,
  Avatar,
} from "@material-tailwind/react";
import moment from 'moment';

export interface AdminUserProps {
  data:any;
  result:any[]
}


const Index: FC<AdminUserProps> = (props) => {
  const dispatch = useDispatch();
  const [isApiCall, setIsApiCall] = useState(0)
  const [listCount, setListCount] = useState(0);
  const [listData, setListData] = useState([]);
  const [editListData, setEditData] = useState([]);
  const [updateListData, setUpdateListData] = useState(false);
  const [changeStatus, setChangeStatus] = useState('');
  const [showEditModalQuickView, setEditShowModalQuickView] = useState(false);
  const [filterData, setFilterData] = useState({
      start: 0,
      limit: 10,
      search: '',
      sort_by: "_id",
      sort_order:"asc",
  });

  useEffect(() => {
    if (isApiCall == 0) {
      getListData();
      setIsApiCall(1);
    }
  }, [isApiCall]);

  const { data } = useSelector((state: RootState) => state.AppMaster);


  const getListData = async () => {
    setUpdateListData(true);
    dispatch(clearData())
    dispatch(onGetList(filterData, '', api.contactInquiryList));
  }

  if (data!=null && updateListData == true) {
     if(data.length!=0){
        setUpdateListData(false);
        setListData(data.result);
        setListCount(data.total_count);
     }
  }

  const handleSearch = async (name:any, value:any) => {
    if (name === 'start') {
      // Update the start value in filterData
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        start: value,
      }));
    }

    if (name === 'search') {
      // Update the start value in filterData
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        search: value,
      }));
    }
    // Call the list API after updating the start value
    await getListData();
  };

  const columns = useMemo(
    () => [
        {
          Header: "Name",
          Cell: (cellProps:any) => {
            return (
                  <div className="flex gap-3">
                      <a href={`/admin/contact/inquiry/${cellProps.row.original._id}`}
                        className="text-secondary"
                      >
                        {cellProps.row.original.name}
                      </a>
                  </div>
              );
          },
        },
        {
          Header: "Email",
          accessor: 'email',
        },
        {
          Header: "Date",
          accessor: 'createdAt',
          Cell: (cellProps:any) => {
            const createdAtDate = moment(cellProps.value); // Parse the date using moment
            const formattedDate = createdAtDate.format('DD-MM-YYYY hh:mm A'); // Format the date
            
            return <span>{formattedDate}</span>;
          },
        },
        {
          Header: "Action",
          Cell: (cellProps:any) => {
            return (
                <div className="flex gap-3">
                    <a href={`/admin/contact/inquiry/${cellProps.row.original._id}`}
                      className="text-secondary"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg"  className="w-4 h-4" viewBox="0 0 192 512"><path d="M20 424.2h20V279.8H20c-11 0-20-9-20-20V212c0-11 9-20 20-20h112c11 0 20 9 20 20v212.2h20c11 0 20 9 20 20V492c0 11-9 20-20 20H20c-11 0-20-9-20-20v-47.8c0-11 9-20 20-20zM96 0C56.2 0 24 32.2 24 72s32.2 72 72 72 72-32.2 72-72S135.8 0 96 0z"/></svg>
                    </a>
                </div>
            );
        },
      }
    ],
    []
  );

  return (
    <div className="container py-16 lg:pb-28 lg:pt-20">
      <Helmet>
        <title>Contact Inquiry</title>
      </Helmet>
      <div className="mb-12 sm:mb-16">
        <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">Contact Inquiry</h2>
      </div>
      <div className="mt-8 sm:mt-10 mb-8 sm:mb-10">

        <Datatable
          listData={listData}
          filterData={filterData}
          getListData={getListData}
          listCount={listCount}
          columns={columns}
          editData={editListData}
          handleSearch={handleSearch}
        />
      </div>
    </div>
  );
};

export default withRouter(Index);