import { all, fork } from "redux-saga/effects";


import appMasterSaga from "./AppMaster/saga";

export default function* rootSaga() {
  yield all([
    fork(appMasterSaga)
  ]);
}
