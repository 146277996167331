import React, { FC, useState } from "react";
import AdminLogo from "shared/Logo/AdminLogo";
import AvatarDropdown from "./AvatarDropdown";
import AdminNavigation from "shared/Navigation/AdminNavigation";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

export interface MainNav2LoggedProps {}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const inputRef = React.createRef<HTMLInputElement>();
  const [showSearchForm, setShowSearchForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const navigate = useNavigate();

  const handleFormSubmit = (e:any) => {
    e.preventDefault();

    // Construct your query string based on the input value
    if(searchQuery){
    
      let queryString = `?search=${encodeURIComponent(searchQuery)}`;
      navigate("/products" + queryString);
    }
    // else{
    //   navigate("/products");
   
    // }
  };
  const handleClose = () => {
    setShowSearchForm(false)
    setSearchQuery("");
    // Construct your query string based on the input value
    // navigate("/products");
  };
  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderSearchForm = () => {
    return (
      <form
        // onSubmit={(e) => {
        //   e.preventDefault();
        //   navigate("/page-search");
        // }}
        onSubmit={handleFormSubmit}
        className="flex-1 py-2 text-slate-900 dark:text-slate-100"
      >
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 h-full rounded">
          {renderMagnifyingGlassIcon()}
          <input
            ref={inputRef}
            type="text"
            placeholder="Type and press enter"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
            autoFocus
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button type="button" onClick={handleClose}>
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
        <input type="submit" hidden value="" />
      </form>
    );
  };

  const renderContent = () => {
    return (
      <div className="h-20 flex justify-between">

      <div className="lg:flex-1 flex items-center">
        <AdminLogo className="flex-shrink-0" />
      </div>

      <div className="flex-[2] hidden lg:flex justify-center mx-4">
        {showSearchForm ? renderSearchForm() : <AdminNavigation />}
      </div>

      <div className="flex-1 flex items-center justify-end text-slate-700 dark:text-slate-100">
        {!showSearchForm && (
          <button
            className="hidden lg:flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none items-center justify-center"
            onClick={() => setShowSearchForm(!showSearchForm)}
          >
            {renderMagnifyingGlassIcon()}
          </button>
        )}
        <AvatarDropdown />
      </div>
    </div>
    );
  };

  return (
    <div className="nc-MainNav2Logged relative z-10  bg-white dark:bg-neutral-900 drop-shadow-[0_35px_35px_rgba(0,0,0,0.55)] drop-shadow-md">
      <div className="container">{renderContent()}</div>
    </div>
  );
};

export default MainNav2Logged;
