import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

export const ADMINNAVIGATION_DEMO_2: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/admin/Dashboard",
    name: "Dashboard",
  },
  {
    id: ncNanoId(),
    href: "/admin/products",
    name: "Products",
  },
  {
    id: ncNanoId(),
    href: "/admin/customers",
    name: "Customer",
  },
  {
    id: ncNanoId(),
    href: "/admin/categories",
    name: "Categories",
  },
  {
    id: ncNanoId(),
    href: "/admin/inquiry",
    name: "Inquiry",
  },
  {
    id: ncNanoId(),
    href: "/admin/contact/inquiry",
    name: "Contact Inquiry",
  },
];
