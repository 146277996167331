const apiConstant = {
    loginUser: 'login',
    dashboard: 'dashboard',
    userCreate: 'userCreate',
    updateAdmin: 'updateAdmin',
    adminUserList: 'getAdminList',
    categoryList: 'getCategoryList',
    createCategory: 'createCategory',
    addSubCategory: 'add-subcategories-to-category',
    updateCategory: 'updateCategory',
    getCustomerDetail: 'getCustomerDetail',
    getCategoryDetail: 'getCategoryDetail',
    changeStatusCategory: 'updateCategoryStatus',
    productGroupList: 'product-group/list',
    productGroupUpdateStatus: 'product-group/updateStatus',
    productGroupUpdateFilter: 'product-group/update-product-filter-option',
    productGroupCreate: 'product-group/create',
    productGroupUpdate: 'product-group/update',
    productGroupDetail: 'product-group/detail',
    productGroupSinglePriceUpdate: 'product/updatePrice',
    productGroupBulkPriceUpdate: 'product/bulkUpdatePrice',
    productGroupBulkThreeDUpdate: 'product/bulkUpdateThreeDFile',
    productUploadImage: 'product-group/update-product-data-object-image',
    inquiryList: 'enquiry-list',
    inquiryDetail: 'enquiry-details',
    contactInquiryList: 'getContactUsInquiryList',
    contactInquiryDetail: 'getContactUsInquiryDetail',
    customerList: 'getCustomerList',
    customerDetail: 'getCustomerDetail',
    changeStatusCustomer: 'customer/updateStatus',
    verifyCustomer: 'customer/verify',
    deleteCustomer: 'customer/delete',
    presignedUrl: 'get-presigned-url',

    // Front END
    frontLogin: 'font-end/login',
    frontRegister: 'font-end/register',
    verifyOTP: 'font-end/verifyOTP',
    resendOTP: 'font-end/resendOTP',
    frontCategory: 'font-end/category',
    frontCategoryDetail: 'font-end/category-details',
    frontProductGroup: 'font-end/product-group',
    frontProductGroupDetail: 'font-end/product-details',
    frontEnquiry: 'font-end/submit-enquiry',
    frontUpdateEnquiryStatusAndCart:'font-end/update-enquiry-status-and-cart',
    frontContactEnquiry: 'font-end/create-inquiry',
    frontCreateSubscriber:'font-end/createSubscriber',
    frontCustomerDetails:'font-end/customer-details',
    frontUpdateCustomerDetails:'font-end/update-customer',
    changePassword:'font-end/change-password',


}
export default apiConstant
