import React, { useState } from 'react';
import AdminHeaderFilterSearchPage from "components/Admin/AdminHeaderFilterSearchPage";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import List from "../List";
import {
  Card,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";

const TABS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Monitored",
    value: "monitored",
  },
  {
    label: "Unmonitored",
    value: "unmonitored",
  },
];

const TABLE_HEAD = ["Id", "Image", "Name","Status","Action"];

interface DatatableProps {
    listData:any[];
    filterData:any;
    getListData:any;
    listCount:any;
    columns: Array<any>; // Replace 'any' with the specific type for your columns
    editData:any;
}

const ITEMS_PER_PAGE = 10;

const Datatable: React.FC<DatatableProps> = ({ listData,filterData,getListData,listCount,columns,editData }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    const displayedRows = columns.slice(startIndex, endIndex);

    const totalPages = Math.ceil(listCount / ITEMS_PER_PAGE);

    const nextPage = async (page:any) => {
        setCurrentPage(page);
        filterData.start = filterData.limit * (page-1);
        getListData();
    };
    const previousPage = async (page:any) => {
        setCurrentPage(page);
        filterData.start = filterData.limit * (page-1);
        getListData();
    };

    const handleTableSearch = async (event:any) => {
        const {name,value}  =event.target;
        filterData.search = value;
        filterData.start = 0;
        getListData();
    };

    return (
        <div className="h-full w-full relative">
            <div className="flex items-center justify-end mb-5">
                <div className="border flex items-center space-x-1.5 px-3 h-full rounded w-full md:w-64">
                <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                    </path>
                    <path d="M22 22L20 20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <input type="search" onChange={handleTableSearch} name='search' placeholder="Search..." className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"></input>
                </div>
            </div>
          {/*   <AdminHeaderFilterSearchPage /> */}
            <List
                listData={listData}
                filterData={filterData}
                getListData={getListData}
                listCount={listCount}
                columns={columns}>
            </List>
            <div className="flex items-center justify-between mt-5">
                <Typography placeholder="" variant="small" color="blue-gray" className="font-normal"
                 onPointerEnterCapture={() => {}}
                 onPointerLeaveCapture={() => {}}
                >
                Page {currentPage} of {totalPages}
                </Typography>
                <div className="flex gap-2">
                <Button
                    placeholder=""
                    variant="outlined"
                    size="sm"
                    onClick={() => previousPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                >
                    Previous
                </Button>
                <Button
                    placeholder=""
                    variant="outlined"
                    size="sm"
                    onClick={() => nextPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                >
                    Next
                </Button>
                </div>
            </div>
            {/* QUICKVIEW */}
        </div>
    );
};

export default Datatable;