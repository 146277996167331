import React from "react";
import AdminNavigationItem from "./AdminNavigationItem";
import { ADMINNAVIGATION_DEMO_2 } from "data/adminnavigation";

function Navigation() {
  return (
    <ul className="nc-Navigation flex items-center">
      {ADMINNAVIGATION_DEMO_2.map((item) => (
        <AdminNavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
