import React, { FC, useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import NcImage from "shared/NcImage/NcImage";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";
import imageRightJpg from "images/Injection-Accessories.jpg";
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData
} from "store/AppMaster/actions";
import api from "constants/api";
import { RootState } from 'store/reducers';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";


export interface ViewProps {
  data: any;
  result: any[]
}

interface HeaderRow {
  // Define the properties of your header row here
  [key: string]: string;
}

interface BodyRow {
  // Define the properties of your body row here
  [key: string]: string;
}

const View: FC<ViewProps> = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [isApiCall, setIsApiCall] = useState(0);
  const [groupData, setGroupData] = useState(false);
  const [customerData, setCustomerData] = useState({
    id: "",
    title: "",
    first_name: "",
    last_name: "",
    department: "",
    designation: "",
    landLine_no: "",
    email: "",
    mobile: "",
    password: "",
    company_name: "",
    company_address: "",
    additional_address: "",
    gstin: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    billing_company_name: "",
    billing_company_address: "",
    billing_additional_address: "",
    billing_city: "",
    billing_state: "",
    billing_country: "",
    billing_pin_code: "",
    delivery_company_name: "",
    delivery_company_address: "",
    delivery_additional_address: "",
    delivery_city: "",
    delivery_state: "",
    delivery_country: "",
    delivery_pin_code: "",
    industry_type:[],
    tool_room_type:[],
  });

  useEffect(() => {
    if (isApiCall == 0) {
      getGroupDetails();
      setIsApiCall(1);
    }
  }, [isApiCall]);

  const { data } = useSelector((state: RootState) => state.AppMaster);

  const getGroupDetails = () => {
    setGroupData(true);
    let filterData = {
      id: params.id
    }
    dispatch(onGetData(filterData, '', api.customerDetail));
  }

  if (data.customer != null && groupData == true) {
    setGroupData(false);
    setCustomerData((preValue) => {
      return {
        ...preValue,
        id: data.customer._id,
        title: data.customer.title,
        first_name: data.customer.first_name,
        last_name: data.customer.last_name,
        department: data.customer.department,
        designation: data.customer.designation,
        landLine_no: data.customer.landLine_no,
        email: data.customer.email,
        mobile: data.customer.mobile,
        password: data.customer.password,
        company_name:  data.customer.company_name,
        company_address: data.customer.company_address,
        additional_address: data.customer.additional_address,
        gstin: data.customer.gstin,
        city: data.customer.city,
        state: data.customer.state,
        country: data.customer.country,
        pincode: data.customer.pincode,
        billing_company_name: data.customer.billing_company_name,
        billing_company_address: data.customer.billing_company_address,
        billing_additional_address: data.customer.billing_additional_address,
        billing_city: data.customer.billing_city,
        billing_state: data.customer.billing_state,
        billing_country: data.customer.billing_country,
        billing_pin_code: data.customer.billing_pin_code,
        delivery_company_name: data.customer.delivery_company_name,
        delivery_company_address: data.customer.delivery_company_address,
        delivery_additional_address: data.customer.delivery_additional_address,
        delivery_city: data.customer.delivery_city,
        delivery_state: data.customer.delivery_state,
        delivery_country: data.customer.delivery_country,
        delivery_pin_code: data.customer.delivery_pin_code,
        industry_type: data.customer.industry_type,
        tool_room_type: data.customer.tool_room_type
      };
    });
  }

  return (
    <>
      <div className="container py-16 lg:pb-28 lg:pt-20">
        <Helmet>
          <title>Customer Details</title>
        </Helmet>
        <div className="mb-12 sm:mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">Customer Details</h2>
        </div>
        <div className="mt-8 sm:mt-10 mb-8 sm:mb-10">
          <div className="listingSectionSidebar__wrap shadow-lg border-0 p-8 rounded-2xl mb-8 sm:mb-10">
            <div className="flex items-center">
              <div className="sm:ml-6 flex flex-1 flex-col">
                <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4">
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Title :</h5>
                    <h6 className="font-semibold">{customerData && customerData.title}</h6>
                  </div>
                  <div className="flex xl:items-center items-start">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">First Name :</h5>
                    <h6 className="font-semibold break-all">{customerData && customerData.first_name}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Last Name :</h5>

                    <h6 className="font-semibold">{customerData && customerData.last_name}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Department :</h5>

                    <h6 className="font-semibold">{customerData && customerData.department}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Designation :</h5>

                    <h6 className="font-semibold">{customerData && customerData.designation}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Mobile :</h5>

                    <h6 className="font-semibold">{customerData && customerData.mobile}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">LandLine No :</h5>

                    <h6 className="font-semibold">{customerData && customerData.landLine_no}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Email ID :</h5>

                    <h6 className="font-semibold">{customerData && customerData.email}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Company Name :</h5>

                    <h6 className="font-semibold">{customerData && customerData.company_name}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Company Address :</h5>
                    <h6 className="font-semibold">{customerData && customerData.company_address}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Additional Address :</h5>
                    <h6 className="font-semibold">{customerData && customerData.additional_address}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">GSTIN :</h5>
                    <h6 className="font-semibold">{customerData && customerData.gstin}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">City :</h5>
                    <h6 className="font-semibold">{customerData && customerData.city}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">State :</h5>
                    <h6 className="font-semibold">{customerData && customerData.state}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Country :</h5>
                    <h6 className="font-semibold">{customerData && customerData.country}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Pin Code :</h5>
                    <h6 className="font-semibold">{customerData && customerData.pincode}</h6>
                  </div>
                </div>
                <div className="grid xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 grid-cols-1 gap-4 mt-3">
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Industry Type :</h5>
                    <h6 className="font-semibold">{customerData && customerData.industry_type.join(', ')}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Tool Room Type :</h5>
                    <h6 className="font-semibold">{customerData && customerData.tool_room_type.join(', ')}</h6>
                  </div>
                </div>
                <div className="mt-5 mb-2">
                  <h3 className="block text-xl sm:text-xl lg:text-xl font-semibold ">Billing Details</h3>
                </div>
                <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4">
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Company Name :</h5>

                    <h6 className="font-semibold">{customerData && customerData.billing_company_name}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Company Address :</h5>
                    <h6 className="font-semibold">{customerData && customerData.billing_company_address}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Additional Address :</h5>
                    <h6 className="font-semibold">{customerData && customerData.billing_additional_address}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">City :</h5>
                    <h6 className="font-semibold">{customerData && customerData.billing_city}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">State :</h5>
                    <h6 className="font-semibold">{customerData && customerData.billing_state}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Country :</h5>
                    <h6 className="font-semibold">{customerData && customerData.billing_country}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Pin Code :</h5>
                    <h6 className="font-semibold">{customerData && customerData.billing_pin_code}</h6>
                  </div>
                </div>
                <div className="mt-5 mb-2">
                  <h3 className="block text-xl sm:text-xl lg:text-xl font-semibold ">Delivery Details</h3>
                </div>
                <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4">
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Company Name :</h5>

                    <h6 className="font-semibold">{customerData && customerData.delivery_company_name}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Company Address :</h5>
                    <h6 className="font-semibold">{customerData && customerData.delivery_company_address}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Additional Address :</h5>
                    <h6 className="font-semibold">{customerData && customerData.delivery_additional_address}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">City :</h5>
                    <h6 className="font-semibold">{customerData && customerData.delivery_city}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">State :</h5>
                    <h6 className="font-semibold">{customerData && customerData.delivery_state}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Country :</h5>
                    <h6 className="font-semibold">{customerData && customerData.delivery_country}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Pin Code :</h5>
                    <h6 className="font-semibold">{customerData && customerData.delivery_pin_code}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>

  );
};

export default withRouter(View);
