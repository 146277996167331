import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment, useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import {
    clearData,
    getList as onGetList,
    updateData as onUpdateData,
    addNewData as onAddNewData,
} from "store/AppMaster/actions";
import api from "constants/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isImageJpgOrPng, isImageSizeValid, callMultipartFormDataApi } from "components/Common/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 5000,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000
};

export interface CreateProps {
    setIsApiCall?: any;
    customerID?: any;
    setShowSpecialCustomerModal?: any;
}

const SpecialCustomerVerify: FC<CreateProps> = ({
    customerID,
    setIsApiCall,
    setShowSpecialCustomerModal,
}) => {
    const location = useLocation();
    const dispatch = useDispatch();

    const [showTextArea, setShowTextArea] = useState(false);

    const onChangeHandler = (event: any) => {


        const { name, value } = event.target;

    }
    const handleSubmit = () => {
        if (showTextArea) {
            validation.handleSubmit();
        } else {
            const updateData = {
                status: 'Active',
                id: customerID,
              };
              dispatch(onUpdateData(updateData, '', api.verifyCustomer))
              setIsApiCall(0);
              setShowSpecialCustomerModal(false);
        }
    }


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            id: customerID,
            message: "",
            status: "Active",
        },
        validationSchema: Yup.object({
            message: Yup.string()
                .required("Please enter a message")
        }),
        onSubmit: async values => {
            const newData = {
                message: values.message,
                id: customerID,
                status: 'Active',
            };

            dispatch(onUpdateData(newData, '', api.verifyCustomer))
            setIsApiCall(0);
            setShowSpecialCustomerModal(false);
        },
    });

    return (


        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white w-96 rounded-lg shadow-xl">
                <form onSubmit={e => {
                    e.preventDefault();
                    handleSubmit()

                    return false;
                }}>
                    {/* Header */}
                    <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200">
                        <h2 className="text-lg font-semibold">Verify Customer</h2>
                        <button
                            className="text-gray-500 hover:text-gray-700 transition-all"
                            onClick={(e) => { setShowSpecialCustomerModal(false) }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                    {/* Body */}
                    <div className="p-4">
                        {/* Message Input */}

                        <div className="mb-4">
                            <label htmlFor="code" className="block text-sm font-medium text-gray-700">Is Special Customer</label>
                            <div className="flex items-center mt-2">
                                <input
                                    type="radio"
                                    id="yes"
                                    name="isSpecialCustomer"
                                    value="yes"
                                    className="mr-2"
                                    onChange={(e) => { setShowTextArea(true) }}
                                />
                                <label htmlFor="yes" className="mr-4">Yes</label>
                                <input
                                    type="radio"
                                    id="no"
                                    name="isSpecialCustomer"
                                    value="no"
                                    className="mr-2"
                                    onChange={(e) => { setShowTextArea(false) }}

                                />
                                <label htmlFor="no">No</label>
                            </div>
                        </div>
                        {showTextArea && <div className="mb-4">
                            <label htmlFor="code" className="block text-sm font-medium text-gray-700">Message</label>
                            <textarea
                                id="code"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                name="message"
                                value={validation.values.message}
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                            />
                            {validation.errors.message ? (
                                <small className="text-rose-600">{validation.errors.message}</small>
                            ) : null}
                        </div>}



                        <div className="text-center mt-3">
                            <ButtonPrimary type="submit">
                                <span>Submit</span>
                            </ButtonPrimary>
                        </div>
                    </div>
                </form>
            </div>
        </div>





    );
};

export default SpecialCustomerVerify;


