import React, { FC, useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Datatable from "./Datatable";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData
} from "store/AppMaster/actions";
import api from "constants/api";
import { RootState } from 'store/reducers';
import {
  Typography,
  Button,
  Avatar,
} from "@material-tailwind/react";
import { set, isEmpty } from "lodash";
import SpecialCustomerVerify from "./SpecialCustomerVerify";

import Swal from 'sweetalert2';

export interface AdminUserProps {
  data: any;
  result: any[]
}

const Index: FC<AdminUserProps> = (props) => {
  const dispatch = useDispatch();
  const [isApiCall, setIsApiCall] = useState(0)
  const [listCount, setListCount] = useState(0);
  const [listData, setListData] = useState([]);
  const [editListData, setEditData] = useState([]);
  const [updateListData, setUpdateListData] = useState(false);
  const [isVerify, setVerifyData] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [changeStatus, setChangeStatus] = useState('');
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [showSwl, setShowSwl] = useState(false);
  const [showEditModalQuickView, setEditShowModalQuickView] = useState(false);
  const [filterData, setFilterData] = useState({
    start: 0,
    limit: 10,
    search: '',
    sort_by: "_id",
    sort_order: "asc",
  });
  const [showSpecialCustomerModal, setShowSpecialCustomerModal] = useState(false);
  const [customerId, setCustomerId] = useState('')

  useEffect(() => {
    if (isApiCall == 0) {
      getListData();
      setIsApiCall(1);
    }
  }, [isApiCall]);

  const { data } = useSelector((state: RootState) => state.AppMaster);

  /**Change Status */
  const handleStatusChange = (status: string, id: string) => {
    setStatusUpdated(true);
    if (status == 'Active') {
      status = 'Inactive';
    } else {
      status = 'Active';
    }
    const updateData = {
      status: status,
      id: id,
    };
    dispatch(onUpdateData(updateData, '', api.changeStatusCustomer))
    setIsApiCall(0);
  }

  /**Change Status */
  const handleVerify = (status: string, id: string) => {
    setVerifyData(true);
    if (status == 'Active') {
      status = 'Inactive';
      const updateData = {
        status: status,
        id: id,
      };
      dispatch(onUpdateData(updateData, '', api.verifyCustomer))
      setIsApiCall(0);
    } else {
      status = 'Active';
      setShowSpecialCustomerModal(true);
      setCustomerId(id)
    }
  }

  const deleteCustomer = (id: string) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result:any) => {
        if (result.isConfirmed) {
          // Call the delete function if the user confirms
          setDelete(true);
          const updateData = {
            id: id
          };
          dispatch(onUpdateData(updateData, '', api.deleteCustomer))
        }
      });
  }

  const handleSearch = async (name: any, value: any) => {
    if (name === 'start') {
      // Update the start value in filterData
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        start: value,
      }));
    }

    if (name === 'search') {
      // Update the start value in filterData
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        search: value,
      }));
    }
    // Call the list API after updating the start value
    await getListData();
  };

  const getListData = async () => {
    setUpdateListData(true);
    dispatch(clearData())
    dispatch(onGetList(filterData, '', api.customerList));
  }

  if (data != null && updateListData == true) {
    if (data.length != 0 && data.result) {
      setUpdateListData(false);
      setListData(data.result);
      setListCount(data.total_count);
    }
  }

  if (data && data.verifyCustomer != null && isVerify == true) {
      setVerifyData(false);
      setListData(data.result);
      setListCount(data.total_count);
  }

  if (data && data.deleteCustomer != null && isDelete == true) {
      setDelete(false);
      getListData();
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        Cell: (cellProps: any) => {
          return (
            <div className="flex gap-3">
             <a href={`/admin/customer/${cellProps.row.original._id}`}
               className="text-secondary"
             >
                {cellProps.row.original.first_name} {cellProps.row.original.last_name}
             </a>
            </div>
          );
        }
      },
      {
        Header: "Email",
        accessor: 'email',
      },
      {
        Header: "Mobile",
        accessor: 'mobile',
      },
      {
        Header: "Status",
        Cell: (cellProps: any) => {
          return (
            <div className="d-flex gap-3">
              <Link to="#" onClick={() => {
                handleStatusChange(cellProps.row.original.status, cellProps.row.original._id);
              }}><span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset  ${cellProps.row.original.status == 'Active' ? "bg-green-50 text-green-600 ring-green-500/10" : "bg-red-50 text-red-600 ring-red-500/10"}`}
              >{cellProps.row.original.status}
                </span></Link>
            </div>
          );
        }
      },
      {
        Header: "Admin Verify",
        Cell: (cellProps: any) => {
          return (
            <>
              <div className="d-flex gap-3">
                <Link to="#" onClick={() => {
                  handleVerify(cellProps.row.original.verify_status, cellProps.row.original._id);
                }}><span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset  ${cellProps.row.original.verify_status == 'Active' ? "bg-green-50 text-green-600 ring-green-500/10" : "bg-red-50 text-red-600 ring-red-500/10"}`}
                >{cellProps.row.original.verify_status}
                  </span>
                </Link>
              </div>
            </>
          );
        }
      },
      {
        Header: "Action",
        Cell: (cellProps: any) => {
          return (
            <div className="flex gap-3">
              <a href={`/admin/customer/${cellProps.row.original._id}`}
                className="text-secondary"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 192 512"><path d="M20 424.2h20V279.8H20c-11 0-20-9-20-20V212c0-11 9-20 20-20h112c11 0 20 9 20 20v212.2h20c11 0 20 9 20 20V492c0 11-9 20-20 20H20c-11 0-20-9-20-20v-47.8c0-11 9-20 20-20zM96 0C56.2 0 24 32.2 24 72s32.2 72 72 72 72-32.2 72-72S135.8 0 96 0z" /></svg>
              </a>
               <a href="#" onClick={() => {deleteCustomer(cellProps.row.original._id)}}
                className="text-secondary"
              >
               <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 30 30">
                    <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                </svg>
              </a>
            </div>
          );
        },
      }
    ],
    []
  );

  if (!isEmpty(data) && (data.status == 'Active' || data.status == 'Inactive') && statusUpdated) {
    setStatusUpdated(false)
    setIsApiCall(0)
  }

  return (
    <div className="container py-16 lg:pb-28 lg:pt-20">
      <Helmet>
        <title>Customer | Vijaydeep</title>
      </Helmet>
      <div className="mb-12 sm:mb-16">
        <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">Customer</h2>
      </div>
      <div className="mt-8 sm:mt-10 mb-8 sm:mb-10">

        <Datatable
          listData={listData}
          filterData={filterData}
          getListData={getListData}
          listCount={listCount}
          columns={columns}
          editData={editListData}
          handleSearch={handleSearch}
        />
      </div>
      {showSpecialCustomerModal && <SpecialCustomerVerify setIsApiCall={setIsApiCall} setShowSpecialCustomerModal={setShowSpecialCustomerModal} customerID={customerId} />
      }
    </div>
  );
};

export default withRouter(Index);
