import { Dialog, Transition } from "@headlessui/react";
import React, { FC,Fragment, useEffect, useState,useMemo } from "react";
import { useLocation } from "react-router-dom";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isImageJpgOrPng,isImageSizeValid,callMultipartFormDataApi } from "components/Common/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from 'store/reducers';
import {
    clearData,
    getList as onGetList,
     updateData as onUpdateData,
    addNewData as onAddNewData,
  } from "store/AppMaster/actions";
import api from "constants/api";
export interface CreateProps {
  show: boolean;
  onCloseModalQuickView: () => void;
  getListData: () => void;
}

const Create: FC<CreateProps> = ({
  show,
  onCloseModalQuickView,
  getListData
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isApiCall,setCallApi] = useState(false);
  const [frmData,setFrmData] = useState({
        name:"",
        email:"",
        mobile:"",
        password:""
  });

  const onChangeHandler =(event:any) =>{
      const {name,value}  =event.target;
      setFrmData((preValue)=>{
          return {
              ...preValue,
              [name]:value,
          };
      });
  }

  const { data } = useSelector((state: RootState) => state.AppMaster);


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
        name: (frmData && frmData.name) || "",
        email: (frmData && frmData.email) || "",
        mobile: (frmData && frmData.mobile) || "",
        password: (frmData && frmData.password) || "",
    },
    validationSchema: Yup.object({
        name: Yup.string().required("Please Enter Name").matches(
            /^[A-Za-z ]+$/i,
            "Must be a valid Name"
        ).max(50, "Must be less than  50 characters"),
        email: Yup.string().required("Please Enter Email").matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            "Must be a valid Email"
        ),
        mobile: Yup.string().required("Please Enter Mobile").matches(
          /^[+0-9]+$/i,
          "Please Enter Valid Mobile"
        ).min(9, "Please Enter Valid Mobile").max(12, "Please Enter Valid Mobile"),
        password: Yup.string().required('Password is required')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Please ensure your password is a minimum of eight characters and includes at least one letter, one number, and one special character"
        ).min(8, 'Password must be at least 6 characters')
    }),
    onSubmit: values => {
        const newData = {
            name: values["name"],
            email: values["email"],
            mobile: values["mobile"],
            password: values["password"],
            role:"Admin",
        };
        dispatch(onAddNewData(newData, "", api.userCreate))
        setCallApi(true);
    },
  });

  if (data!=null && isApiCall==true) {
    setCallApi(false);
    onCloseModalQuickView();
    getListData();
  }

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50"
        onClose={onCloseModalQuickView}
      >
        <div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-flex xl:py-8 w-full max-w-5xl max-h-full">
              <div
                className="flex-1 flex overflow-hidden max-h-full p-8 w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white
                dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl"
              >
                <span className="absolute right-3 top-3 z-50">
                  <ButtonClose onClick={onCloseModalQuickView} />
                </span>

                <div className="flex-1  rounded-xl hiddenScrollbar">
                  <h2 className="text-2xl font-semibold hover:text-primary-6000 transition-colors mb-2.5">Add User</h2>
                  <form className="grid grid-cols-2 gap-6"
                     onSubmit={e => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                  }}
                  method="post">
                    <label className="block">
                      <Label>Name</Label>
                      <Input
                        name="name"
                        placeholder="Name"
                        type="text"
                        className="mt-1"
                        onChange={onChangeHandler}
                      />
                        {validation.errors.name ? (
                            <small className="text-rose-600">{validation.errors.name}</small>
                        ) : null}
                    </label>

                    <label className="block">
                      <Label>Email</Label>
                      <Input
                        name="email"
                        placeholder="Email"
                        type="email"
                        className="mt-1"
                        onChange={onChangeHandler}
                      />
                        {validation.errors.email ? (
                            <small className="text-rose-600">{validation.errors.email}</small>
                        ) : null}
                    </label>

                    <label className="block">
                      <Label>Mobile</Label>
                      <Input
                        name="mobile"
                        placeholder="Mobile"
                        type="text"
                        className="mt-1"
                        onChange={onChangeHandler}
                      />
                        {validation.errors.mobile ? (
                            <small className="text-rose-600">{validation.errors.mobile}</small>
                        ) : null}
                    </label>

                    <label className="block">
                      <Label>Password</Label>
                      <Input
                        name="password"
                        placeholder="Password"
                        type="password"
                        className="mt-1"
                        onChange={onChangeHandler}
                      />
                        {validation.errors.password ? (
                            <small className="text-rose-600">{validation.errors.password}</small>
                        ) : null}
                    </label>

                    <div className="text-right mt-4">
                    <ButtonPrimary type="submit">Submit</ButtonPrimary>
                   </div>
                  </form>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Create;
