import withRouter from "components/Common/withRouter";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutCustomer } from "store/AppMaster/actions";

const CustomerLogout: FC<any> = props => {
   const dispatch = useDispatch();
   const [isApiCall,setIsApiCall] =useState(0);
   const history = useNavigate();
   useEffect(() => {
    if(isApiCall==0){
       dispatch(logoutCustomer(history,''));
       setIsApiCall(1);
    }
  }, [isApiCall]);


   return <></>;
};

export default withRouter(CustomerLogout);

