import { set } from 'lodash';
import React, { createContext, useContext, useState, useEffect } from 'react';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);
    const [cartCount, setCartCount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0)
    const [showTotalPrice, setShowTotalPrice] = useState(true)
    const [totalDiscount, setTotalDiscount] = useState(0);


    useEffect(() => {
        const savedCart = localStorage.getItem('cart');
        if (savedCart) {
            setCart(JSON.parse(savedCart));

        }
        setCartCount(savedCart ? JSON.parse(savedCart).length : 0);
    }, []);

    const updateCart = () => {
        const savedCart = localStorage.getItem('cart');
        if (savedCart) {
            setCart(JSON.parse(savedCart));

        }
        setCartCount(savedCart ? JSON.parse(savedCart).length : 0);
    };

    const addToCart = (product, row) => {
        // console.log('Product', product);
        // console.log('Row', row);

        let type
        if (row.economic_code) {
            type = 'pending'
            setShowTotalPrice(true)
        } else {
            type = 'standard'
        }

        const existingCartString = localStorage.getItem('cart');
        const existingCart = existingCartString ? JSON.parse(existingCartString) : [];

        const existingProductIndex = existingCart.findIndex((item) => item.id === product.id && item.code === row.A);
        if (existingProductIndex !== -1) {
            existingCart[existingProductIndex].quantity += 1;
        } else {
            existingCart.push({ id: product.id, image: product.image, code: row.A, quantity: 1, row, slug: product.slug, type: type, econ_quantity: 1 });
        }

        // Calculate total price
        let tempTotalPrice = existingCart.reduce((acc, item) => {
            let price = 0;
            let econprice = 0;
            let totalPrice = 0;
            if (item.type === 'standard') {
                price = item.row.price || 0;
            } else if (item.type === 'economic') {
                econprice = item.row.economic_price || 0;
            }else if (item.type === 'pending') {
                price = item.row.price || 0;
                econprice = item.row.economic_price || 0;
            }
            totalPrice = (price * item.quantity) + (econprice * item.econ_quantity);
            return acc + totalPrice;
        }, 0);
        setTotalPrice(tempTotalPrice);
        localStorage.setItem('Total Price', tempTotalPrice);

        // calculate discount
        let tempTotalDiscount = existingCart.reduce((acc, item) => {
            let discountPercentage = 0;
            let itemPrice = 0
            let discountEconPer = 0;
            let itemEconPrice = 0
            if (item.type === 'standard') {
                discountPercentage = item.row.discount || 0;
                itemPrice = item.row.price || 0;
            } else if (item.type === 'economic') {
                discountEconPer = item.row.economic_discount || 0;
                itemEconPrice = item.row.economic_price || 0;
            }else if (item.type === 'pending') {
                discountPercentage = item.row.discount || 0;
                discountEconPer = item.row.economic_discount || 0;

                itemPrice = item.row.price || 0;
                itemEconPrice = item.row.economic_price || 0;
            }

            // Ensure discountPercentage is not negative
            discountPercentage = Math.max(0, discountPercentage);
            discountEconPer = Math.max(0, discountEconPer);

            // Calculate discount as a percentage of item price
            let discount = ((itemPrice * discountPercentage) / 100) * item.quantity;
            let discountEcon = ((itemEconPrice * discountEconPer) / 100) * item.econ_quantity;

            // Add discount to the accumulator
            return acc + discount + discountEcon;
        }, 0);



        if (tempTotalDiscount > 0) {
            setTotalDiscount(tempTotalDiscount)
            localStorage.setItem('Total Discount', tempTotalDiscount);

        } else {
            setTotalDiscount(0)
            localStorage.setItem('Total Discount', 0);

        }


        // console.log(existingCart);
        localStorage.setItem('cart', JSON.stringify(existingCart));
        setCart(existingCart)
        setCartCount(existingCart ? existingCart.length : 0);

    }

    const removeFromCart = (event, productId, row, code,type) => {
        event.preventDefault();
        const existingCartString = localStorage.getItem('cart');
        if (!existingCartString) {

            return;
        }
        // console.log(row)
        const existingCart = JSON.parse(existingCartString);
        // console.log(existingCart);


        // const updatedCart = existingCart.filter((item: any) => item.id !== productId && item.code !== code);
        const updatedCart = existingCart.filter((item) => {
            if (item.id === productId && item.type !== "pending") {
                const condition = item.code !== code;
                return condition
            } else {
                return true
            }
            // const condition = item.id !== productId && item.code !== code;

        });

        updatedCart.map(item => {
            if (item.id === productId && item.code === code && item.type === "pending") {
                item.type = type==="standard" ? "economic" : "standard";
                if(type==="standard"){
                    item.quantity = 0;
                }else{
                    item.econ_quantity = 0;
                }
            }
            return item;
        });

        localStorage.setItem('cart', JSON.stringify(updatedCart));

        setCart(updatedCart)
        setCartCount(updatedCart ? updatedCart.length : 0);

        // Calculate total price
        let tempTotalPrice = updatedCart.reduce((acc, item) => {
            let price = 0;
            let econprice = 0;
            let totalPrice = 0;
            if (item.type === 'standard') {
                price = item.row.price || 0;
            } else if (item.type === 'economic') {
                econprice = item.row.economic_price || 0;
            }else if (item.type === 'pending') {
                price = item.row.price || 0;
                econprice = item.row.economic_price || 0;
            }
            totalPrice = (price * item.quantity) + (econprice * item.econ_quantity);
            return acc + totalPrice;
        }, 0);
        setTotalPrice(tempTotalPrice);
        localStorage.setItem('Total Price', tempTotalPrice);


        // calculate discount
        let tempTotalDiscount = existingCart.reduce((acc, item) => {
            let discountPercentage = 0;
            let itemPrice = 0
            let discountEconPer = 0;
            let itemEconPrice = 0
            if (item.type === 'standard') {
                discountPercentage = item.row.discount || 0;
                itemPrice = item.row.price || 0;
            } else if (item.type === 'economic') {
                discountEconPer = item.row.economic_discount || 0;
                itemEconPrice = item.row.economic_price || 0;
            }else if (item.type === 'pending') {
                discountPercentage = item.row.discount || 0;
                discountEconPer = item.row.economic_discount || 0;

                itemPrice = item.row.price || 0;
                itemEconPrice = item.row.economic_price || 0;
            }

           // Ensure discountPercentage is not negative
           discountPercentage = Math.max(0, discountPercentage);
           discountEconPer = Math.max(0, discountEconPer);

           // Calculate discount as a percentage of item price
           let discount = ((itemPrice * discountPercentage) / 100) * item.quantity;
           let discountEcon = ((itemEconPrice * discountEconPer) / 100) * item.econ_quantity;

           // Add discount to the accumulator
           return acc + discount + discountEcon;
        }, 0);



        if (tempTotalDiscount > 0) {
            setTotalDiscount(tempTotalDiscount)
            localStorage.setItem('Total Discount', tempTotalDiscount);

        } else {
            setTotalDiscount(0)
            localStorage.setItem('Total Discount', 0);

        }
    };

    const increaseQuantity = (event, productId, row, code,type) => {
        const existingCartString = localStorage.getItem('cart');
        if (!existingCartString) {
            return;
        }

        const existingCart = JSON.parse(existingCartString);

        const updatedCart = existingCart.map((item) => {
            if (item.id === productId && item.code === code && (item.type==="standard" || (item.type ==="pending" && type === "standard"))) {
                return { ...item, quantity: item.quantity + 1 };
            }else if (item.id === productId && item.code === code && (item.type==="economic" || (item.type ==="pending" && type === "economic"))) {
                return { ...item, econ_quantity: item.econ_quantity + 1 };
            } else {
                return item;
            }
        });

        localStorage.setItem('cart', JSON.stringify(updatedCart));

        setCart(updatedCart);
        setCartCount(updatedCart ? updatedCart.length : 0);

        // Calculate total price
        let tempTotalPrice = updatedCart.reduce((acc, item) => {
            let price = 0;
            let econprice = 0;
            let totalPrice = 0;
            if (item.type === 'standard') {
                price = item.row.price || 0;
            } else if (item.type === 'economic') {
                econprice = item.row.economic_price || 0;
            }else if (item.type === 'pending') {
                price = item.row.price || 0;
                econprice = item.row.economic_price || 0;
            }
            totalPrice = (price * item.quantity) + (econprice * item.econ_quantity);
            return acc + totalPrice;
        }, 0);

        setTotalPrice(tempTotalPrice);
        localStorage.setItem('Total Price', tempTotalPrice);

        let tempTotalDiscount = updatedCart.reduce((acc, item) => {
            let discountPercentage = 0;
            let itemPrice = 0
            let discountEconPer = 0;
            let itemEconPrice = 0
            if (item.type === 'standard') {
                discountPercentage = item.row.discount || 0;
                itemPrice = item.row.price || 0;
            } else if (item.type === 'economic') {
                discountEconPer = item.row.economic_discount || 0;
                itemEconPrice = item.row.economic_price || 0;
            }else if (item.type === 'pending') {
                discountPercentage = item.row.discount || 0;
                discountEconPer = item.row.economic_discount || 0;

                itemPrice = item.row.price || 0;
                itemEconPrice = item.row.economic_price || 0;
            }

           // Ensure discountPercentage is not negative
           discountPercentage = Math.max(0, discountPercentage);
           discountEconPer = Math.max(0, discountEconPer);

           // Calculate discount as a percentage of item price
           let discount = ((itemPrice * discountPercentage) / 100) * item.quantity;
           let discountEcon = ((itemEconPrice * discountEconPer) / 100) * item.econ_quantity;

           // Add discount to the accumulator
           return acc + discount + discountEcon;
        }, 0);



        if (tempTotalDiscount > 0) {
            setTotalDiscount(tempTotalDiscount)
            localStorage.setItem('Total Discount', tempTotalDiscount);

        } else {
            setTotalDiscount(0)
            localStorage.setItem('Total Discount', 0);

        }
    };

    const decreaseQuantity = (event, productId, row, code,type) => {
        const existingCartString = localStorage.getItem('cart');
        if (!existingCartString) {
            return;
        }

        const existingCart = JSON.parse(existingCartString);

        const updatedCart = existingCart
            .map((item) => {
                if (item.id === productId && item.code === code && (item.type==="standard" || (item.type ==="pending" && type === "standard"))) {
                    return { ...item, quantity: Math.max(item.quantity - 1, 0) };
                }else if (item.id === productId && item.code === code && (item.type==="economic" || (item.type ==="pending" && type === "economic"))) {
                    return { ...item, econ_quantity: Math.max(item.econ_quantity - 1, 0) };
                } else {
                    return item;
                }
            })
            .filter((item) => item.quantity > 0 || item.econ_quantity > 0);

            updatedCart.map(item => {
                if (item.id === productId && item.code === code && item.type === "pending" && type === "standard" && item.quantity === 0) {
                    item.type = "economic";
                }else  if (item.id === productId && item.code === code && item.type === "pending" && type === "economic" && item.econ_quantity === 0) {
                    item.type = "standard";
                }
                return item;
            });

        localStorage.setItem('cart', JSON.stringify(updatedCart));

        setCart(updatedCart);
        setCartCount(updatedCart ? updatedCart.length : 0);

        // Calculate total price
        let tempTotalPrice = updatedCart.reduce((acc, item) => {
            let price = 0;
            let econprice = 0;
            let totalPrice = 0;
            if (item.type === 'standard') {
                price = item.row.price || 0;
            } else if (item.type === 'economic') {
                econprice = item.row.economic_price || 0;
            }else if (item.type === 'pending') {
                price = item.row.price || 0;
                econprice = item.row.economic_price || 0;
            }
            totalPrice = (price * item.quantity) + (econprice * item.econ_quantity);
            return acc + totalPrice;
        }, 0);
        setTotalPrice(tempTotalPrice);
        localStorage.setItem('Total Price', tempTotalPrice);

        // calculate discount
        let tempTotalDiscount = updatedCart.reduce((acc, item) => {
            let discountPercentage = 0;
            let itemPrice = 0
            let discountEconPer = 0;
            let itemEconPrice = 0
            if (item.type === 'standard') {
                discountPercentage = item.row.discount || 0;
                itemPrice = item.row.price || 0;
            } else if (item.type === 'economic') {
                discountEconPer = item.row.economic_discount || 0;
                itemEconPrice = item.row.economic_price || 0;
            }else if (item.type === 'pending') {
                discountPercentage = item.row.discount || 0;
                discountEconPer = item.row.economic_discount || 0;

                itemPrice = item.row.price || 0;
                itemEconPrice = item.row.economic_price || 0;
            }

           // Ensure discountPercentage is not negative
           discountPercentage = Math.max(0, discountPercentage);
           discountEconPer = Math.max(0, discountEconPer);

           // Calculate discount as a percentage of item price
           let discount = ((itemPrice * discountPercentage) / 100) * item.quantity;
           let discountEcon = ((itemEconPrice * discountEconPer) / 100) * item.econ_quantity;

           // Add discount to the accumulator
           return acc + discount + discountEcon;
        }, 0);



        if (tempTotalDiscount > 0) {
            setTotalDiscount(tempTotalDiscount)
            localStorage.setItem('Total Discount', tempTotalDiscount);

        } else {
            setTotalDiscount(0)
            localStorage.setItem('Total Discount', 0);

        }
    };

    const handleProductTypeSelection = (productType, productId, code) => {
        const existingCartString = localStorage.getItem('cart');
        const existingCart = existingCartString ? JSON.parse(existingCartString) : [];

        existingCart.map(item => {
            if (item.id === productId && item.code === code) {
                item.type = productType;
            }
            return item;
        });
        // Check if any item in existingCart has type 'pending'
        const hasPendingItem = existingCart.some(item => item.type === 'pending');

        // Set setShowTotal based on the result
        setShowTotalPrice(true);

        localStorage.setItem('cart', JSON.stringify(existingCart));
        setCart(existingCart)

        // Calculate total price
        if (hasPendingItem) {
            let tempTotalPrice = existingCart.reduce((acc, item) => {
                let price = 0;
                if (item.type === 'pending') {
                    price = item.row.price || 0;
                } else if (item.type === 'economic') {
                    price = item.row.economic_price || 0;
                }
                return acc + (price * item.quantity);
            }, 0);
            setTotalPrice(tempTotalPrice);
            localStorage.setItem('Total Price', tempTotalPrice);
            // calculate discount
            let tempTotalDiscount = existingCart.reduce((acc, item) => {
                let discountPercentage = 0;
                let itemPrice = 0
                if (item.type === 'pending') {
                    discountPercentage = item.row.discount || 0;
                    itemPrice = item.row.price || 0;
                } else if (item.type === 'economic') {
                    discountPercentage = item.row.economic_discount || 0;
                    itemPrice = item.row.economic_price || 0;
                }
                // Ensure discountPercentage is not negative
                discountPercentage = Math.max(0, discountPercentage);

                // Calculate discount as a percentage of item price
                let discount = ((itemPrice * discountPercentage) / 100) * item.quantity;

                // Add discount to the accumulator
                return acc + discount;
            }, 0);



            if (tempTotalDiscount > 0) {
                setTotalDiscount(tempTotalDiscount)
                localStorage.setItem('Total Discount', tempTotalDiscount);

            } else {
                setTotalDiscount(0)
                localStorage.setItem('Total Discount', 0);

            }
        }


    }

    const value = {
        cart,
        updateCart,
        cartCount,
        addToCart,
        totalPrice,
        removeFromCart,
        increaseQuantity,
        decreaseQuantity,
        setCart,
        setTotalPrice,
        handleProductTypeSelection,
        showTotalPrice,
        totalDiscount,
    };

    return (
        <CartContext.Provider value={value}>
            {children}
        </CartContext.Provider>
    );
};
