import { Popover, Transition } from "@headlessui/react";
import React, { Fragment, useState, useEffect } from "react";
import { useCart } from '../../context/CartContext';
import Prices from "components/Prices";
import { Product, PRODUCTS } from "data/data";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export default function CartDropdown() {
  const [cartData, setCartData] = useState([]);
  const [customerData, setAuthData] = useState([]);
  const [tprice, setTotalPrice] = useState(0);
  // const [cartCount, setCartCount] = useState(0);
  const { cartCount, cart, totalPrice, removeFromCart, increaseQuantity, decreaseQuantity } = useCart();

  // Function to handle cart data and cart length
  const handleCart = () => {
    const cartList = localStorage.getItem('cart');
    setCartData(cartList ? JSON.parse(cartList) : []);
    // setCartCount(cartList ? JSON.parse(cartList).length : 0);
  }

  useEffect(() => {
    const AuthCustomerDetails = localStorage.getItem('AuthCustomerDetails');
    const cartTotal = localStorage.getItem('Total Price');
    setAuthData(AuthCustomerDetails ? JSON.parse(AuthCustomerDetails) : [])
    setTotalPrice(cartTotal? JSON.parse(cartTotal) : 0);
    handleCart()
  }, [totalPrice])
  // const removeFromCart = (event: React.MouseEvent<HTMLAnchorElement>, productId: string, row: object, code: string) => {
  //   event.preventDefault();
  //   const existingCartString = localStorage.getItem('cart');
  //   if (!existingCartString) {
  //     return;
  //   }
  //   // console.log(row)
  //   const existingCart = JSON.parse(existingCartString);
  //   console.log(existingCart);

  //   // const updatedCart = existingCart.filter((item: any) => item.id !== productId && item.code !== code);
  //   const updatedCart = existingCart.filter((item: any) => {
  //     if (item.id === productId) {
  //       const condition = item.code !== code;
  //       return condition
  //     } else {
  //       return true
  //     }
  //     // const condition = item.id !== productId && item.code !== code;

  //   });

  //   // console.log(updatedCart);

  //   localStorage.setItem('cart', JSON.stringify(updatedCart));

  //   const cartList: any = localStorage.getItem("cart")
  //   setCartData((cartList) ? JSON.parse(cartList) : []);
  //   setCartCount(cartList ? JSON.parse(cartList).length : 0);
  // };

  const renderProduct = (item: any, index: number, close: () => void) => {
    const { code, quantity, image, id, row, slug } = item;

    return (
      <div key={index} className="flex py-5 last:pb-0">
        <div className="relative h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={image}
            alt={code}
            className="h-full w-full object-contain object-center"
          />
          <Link
            onClick={close}
            className="absolute inset-0"
            to={`/${slug}`}
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium ">
                  <Link onClick={close} to={"/product-detail"}>
                    {code}
                  </Link>
                </h3>
                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                </p>
              </div>
              <div>
                <h3 className="text-base font-medium ">
                  Price : ₹ {row.price ? row.price * quantity : '0'}
                </h3>

              </div>
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400">{`Qty ${quantity}`}</p>

            <p>
              <span className="cursor-pointer" onClick={(e) => increaseQuantity(e, id, row, code)}><AddCircleOutlineIcon /></span>
              <span>|</span>
              <span className="cursor-pointer" onClick={(e) => decreaseQuantity(e, id, row, code)}><RemoveCircleOutlineIcon /></span>
            </p>
            <div className="flex">
              <Link
                to=""
                onClick={(e) => removeFromCart(e, id, row, code)}
                className="font-medium text-primary-6000 dark:text-primary-500"
              >
                Remove
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`
                ${open ? "" : "text-opacity-90"}
                 group w-10 h-10 sm:w-12 sm:h-12 hover:bg-slate-100 dark:hover:bg-slate-800 rounded-full inline-flex items-center justify-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative`}
          >
            <div className="w-3.5 h-3.5 flex items-center justify-center bg-primary-500 absolute top-1.5 right-1.5 rounded-full text-[10px] leading-none text-white font-medium">
              <span className="mt-[1px]">{cartCount}</span>
            </div>
            <svg
              className="w-6 h-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 8H21"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <Link className="block md:hidden absolute inset-0" to={"/cart"} />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="hidden md:block absolute z-10 w-screen max-w-xs sm:max-w-md px-4 mt-3.5 -right-28 sm:right-0 sm:px-0">
              <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10">
                <div className="relative bg-white dark:bg-neutral-800">
                  <div className="max-h-[60vh] p-5 overflow-y-auto hiddenScrollbar">
                    <h3 className="text-xl font-semibold">Shopping cart</h3>
                    <div className="divide-y divide-slate-100 dark:divide-slate-700">
                      {cart && cart.map(
                        (item: any, index: any) => renderProduct(item, index, close)
                      )}
                    </div>
                  </div>
                  <div className="bg-slate-100 dark:bg-slate-900 p-5">
                    <div className="flex justify-between items-center mt-5">
                      <h3 className="text-base font-medium">Total Price</h3>
                      <h3 className="text-base font-medium text-right">₹ {tprice}</h3>
                    </div>


                    <div className="flex space-x-2 mt-5">
                      {/* <ButtonSecondary
                        href="/cart"
                        className="flex-1 border border-slate-200 dark:border-slate-700"
                        onClick={close}
                      >
                        View cart
                      </ButtonSecondary> */}
                      {customerData && customerData.length==undefined ? (
                          <ButtonPrimary
                          href="/checkout"
                          onClick={close}
                          className="flex-1"
                        >
                          Check out
                        </ButtonPrimary>
                      ):(
                        <ButtonPrimary
                          href="/login"
                          onClick={close}
                          className="flex-1"
                        >
                          Check out
                        </ButtonPrimary>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
