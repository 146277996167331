
import NcImage from "shared/NcImage/NcImage";
import imageRightJpg from "images/Injection-Accessories.jpg";
import imageRightJpg2 from "images/Mould-Alignment.jpg";
import imageRightJpg3 from "images/Three-Plate-Mould-Accessories.jpg";
import imageRightJpg4 from "images/Slides-Accessories.jpg";
import imageRightJpg5 from "images/Ejection-Accessories.jpg";
import imageRightJpg6 from "images/Undercut-Release-System.jpg";
import imageRightJpg7 from "images/Die-Spring.jpg";
import imageRightJpg8 from "images/Cooling-Accessories.jpg";
import imageRightJpg9 from "images/DATE.jpg";
import imageRightJpg10 from "images/General-Mould-Accessories.jpg";

import React, { FC, useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData
} from "store/AppMaster/actions";
import api from "constants/api";
import { RootState } from 'store/reducers';




export interface CategoryProps {
  className?: string;
  data?: string;
  getListData?: any;
  filterData?: any;
  setShowProduct? :any;
}

const Category: FC<CategoryProps> = ({
  className,
  data,
  getListData,
  filterData,
  setShowProduct
}) => {

  const [categoryData, setCategoryData] = useState([]);
  const location = useLocation();
  const path = location.pathname;
  const moduleName = path.split('/').pop();

  if (categoryData.length == 0) {
    setTimeout(() => {
      let listData: any = localStorage.getItem("categoryData")
      const categorySessionData = JSON.parse(listData);
      const mainCategory = categorySessionData.filter((item: any) => item.slug==moduleName);
      if(mainCategory.length>0 && mainCategory[0].subCategory && mainCategory[0].subCategory.length>0){
        setCategoryData(mainCategory[0].subCategory)
      }
    }, 1000);
  }

  // state and function for changing views based on page route
  const applyFilte =(categoryId: string)=>{
    // const commaSeparatedString = categoriesState.join(',');
    if(location.pathname !== '/') {
      setShowProduct(true)

      filterData.category =categoryId;
      getListData();
    }

    // alert(categoriesState)

    // const normalString = categoriesState
    // const commaSeparatedString =normalString.join(',')
    // setFilterData({...filterData,category:commaSeparatedString})


    // getNewListData(commaSeparatedString);
  }



  return (
    <div className={`nc-Card13 relative grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-8 xl:mt-8`}>
      {categoryData != null && categoryData.map((item: any, index: number) => (
        <div
          key={item.id}
          className=""
        >
          <Link
            to={`/${moduleName+"/"+item.slug}` }
          >

            <NcImage
              className="rounded-2xl"
              src={process.env.REACT_APP_CLOUD_FRONT_URL+'/'+item.image_data.pathname}

            />
          </Link>
          <Link
            to={`/${moduleName+"/"+item.slug}` }
          >
            <div className="space-y-1 px-2.5 pt-5 pb-2.5">
              <h2 className="md:text-base text-sm font-semibold text-neutral-300 hover:text-secondary-900">{item.name}</h2>
              <span className="block text-secondary-900 hover:text-neutral-900 text-sm leading-6" >
                Read More
              </span>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default withRouter(Category);
