import rightImg from "images/hero-right1.png";
import missionImg from "images/mision.png";
import visionImg from "images/vision.png";
import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "./SectionHero";


export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About Us | Vijaydeep</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 Welcome to Vijaydeep"
          btnText=""
          subHeading="Started with an idea of providing standard tools, components & accessories for the tool room industry, Vijaydeep has been in the reckoning for over a decade. With more than 50 standard products available and many more to come, we have catered to every client’s requirements. Our expertise spans across the Global arena which enables us to provide even customized products. With a deep understanding of the global markets and the constantly upgrading technologies, we have always been a step ahead. Total Customer Satisfaction has always been our primary motto and we have always strived to achieve the same with one and all."
        />

        {/* <SectionFounder /> */}

        {/* our-mssion section start */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center md:text-left">
        <div className="flex flex-col">
           <div className="w-full lg:w-96 m-auto">
           <img className="w-60 m-auto" src={visionImg} alt="" />
           </div>
          </div>
          <div className="flex flex-col ">
            <h2 className="text-2xl lg:text-3xl !leading-tight font-semibold text-neutral-900 mb-3">
              OUR VISION
            </h2>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              With an aim to become a big name in the tool industry and proliferate our clientele to double the present status, we want to go full throttle and exceed expectations so as our client get the maximum out of our products and services.
            </span>
          </div>
        </div>
        {/* our-mssion section end */}

         {/* our-mssion section start */}
         <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 lg:flex-row space-y-0 lg:space-y-0 lg:space-x-10 items-center relative text-center md:text-left">
          <div className="flex flex-col order-last md:order-first mt-10 md:mt-0">
            <h2 className="text-2xl lg:text-3xl !leading-tight font-semibold text-neutral-900 mb-3">
            OUR MISSION
            </h2>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
            We have vouched to ourselves of being fair and ethical at all times. From teamwork to global congruence at all levels; we assess our progress through the satisfaction of our clients by building strong partnerships.
            </span>
          </div>
          <div className="flex flex-col mt-0">
            <img className="w-60 m-auto" src={missionImg} alt="" />
          </div>
        </div>
        {/* our-mssion section end */}

      </div>
    </div>
  );
};

export default PageAbout;
