import React, { FC, useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import NcImage from "shared/NcImage/NcImage";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";
import imageRightJpg from "images/Injection-Accessories.jpg";
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData
} from "store/AppMaster/actions";
import api from "constants/api";
import { RootState } from 'store/reducers';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import ListFollowUpModal from "./ListFollowUpModal";


export interface ViewProps {
  data: any;
  result: any[]
}

interface HeaderRow {
  // Define the properties of your header row here
  [key: string]: string;
}

interface BodyRow {
  // Define the properties of your body row here
  [key: string]: string;
}

const View: FC<ViewProps> = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [isApiCall, setIsApiCall] = useState(0);
  const [groupData, setGroupData] = useState(false);
  const [inqueryData, setInquiryData] = useState({
    id: "",
    name: "",
    email: "",
    mobile: "",
    designation: "",
    company: "",
    address: "",
    state: "",
    country: "",
    pincode: "",
    subject: "",
    message: "",
    cart_items: [],
    status: "",
    createdAt: "",
    total_value: ""
  });
  const [showEnqyuiryListModal, setShowEnqyuiryListModal] = useState(false);

  useEffect(() => {
    if (isApiCall == 0) {
      getGroupDetails();
      setIsApiCall(1);
    }
  }, [isApiCall]);

  const { data } = useSelector((state: RootState) => state.AppMaster);

  const getGroupDetails = () => {
    setGroupData(true);
    let filterData = {
      id: params.id
    }
    dispatch(onGetData(filterData, '', api.inquiryDetail));
  }

  if (data.enquiry != null && groupData == true) {
    setGroupData(false);
    setInquiryData((preValue) => {
      return {
        ...preValue,
        id: data.enquiry._id,
        name: data.enquiry.name,
        email: data.enquiry.email,
        mobile: data.enquiry.mobile,
        designation: data.enquiry.designation,
        company: data.enquiry.company,
        address: data.enquiry.address,
        state: data.enquiry.state,
        country: data.enquiry.country,
        pincode: data.enquiry.pincode,
        subject: data.enquiry.subject,
        message: data.enquiry.message,
        cart_items: data.enquiry.cart_items,
        status: data.enquiry.status,
        createdAt: data.enquiry.createdAt,
        follow_up: data.enquiry.follow_up,
        total_value: data.enquiry.total_value,
      };
    });
  }

  return (
    <>
      <div className="container py-16 lg:pb-28 lg:pt-20">
        <Helmet>
          <title>Inquiry Details</title>
        </Helmet>
        <div className="mb-12 sm:mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">Inquiry Details</h2>
        </div>
        <div className="mt-8 sm:mt-10 mb-8 sm:mb-10">
          <div className="listingSectionSidebar__wrap shadow-lg border-0 p-8 rounded-2xl mb-8 sm:mb-10">
            <div className="flex items-center">
              <div className="sm:ml-6 flex flex-1 flex-col">
                <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Name :</h5>
                    <h6 className="font-semibold">{inqueryData && inqueryData.name}</h6>
                  </div>
                  <div className="flex xl:items-center items-start">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Email :</h5>
                    <h6 className="font-semibold break-all">{inqueryData && inqueryData.email}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Mobile :</h5>

                    <h6 className="font-semibold">{inqueryData && inqueryData.mobile}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Designation :</h5>

                    <h6 className="font-semibold">{inqueryData && inqueryData.designation}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Company :</h5>

                    <h6 className="font-semibold">{inqueryData && inqueryData.company}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Address :</h5>

                    <h6 className="font-semibold">{inqueryData && inqueryData.address}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">State :</h5>

                    <h6 className="font-semibold">{inqueryData && inqueryData.state}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Country :</h5>

                    <h6 className="font-semibold">{inqueryData && inqueryData.country}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Pin-code :</h5>

                    <h6 className="font-semibold">{inqueryData && inqueryData.pincode}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Company :</h5>
                    <h6 className="font-semibold">{inqueryData && inqueryData.company}</h6>
                  </div>
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Total Price :</h5>
                    <h6 className="font-semibold">{inqueryData && inqueryData.total_value}</h6>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4 mt-3">
                  <div className="flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Subject :</h5>
                    <h6 className="font-semibold">{inqueryData && inqueryData.subject}</h6>
                  </div>
                  <div className="md:flex md:items-start lg:items-center items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Message :</h5>
                    <h6 className="font-semibold mt-2 md:mt-0">{inqueryData && inqueryData.message}</h6>
                  </div>
                  <button className="bg-black text-white mr-2 p-1 w-[15%] rounded-lg" onClick={(e) => {
                    e.preventDefault();
                    setShowEnqyuiryListModal(true)
                  }}>View Follow Ups</button>
                </div>
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-4">
            {inqueryData && inqueryData.cart_items && inqueryData.cart_items.map((item: any, index: any) => (
              <div className="nc-ProductCard rounded-xl shadow-lg border-0 space-y-0">
                <NcImage
                  containerClassName=""
                  src={item.image}
                />
                <div className="border-t-4 border-[#2a3491]"></div>
                <div className="p-5">
                  <div className="flex items-center gap-4 ">
                    <h2>
                      Title
                    </h2>
                    <h3 className={`nc-ProductCard__title text-base font-semibold transition-colors hover:text-secondary-900`}>{item.code}</h3>
                  </div>
                  <div className="flex items-center gap-4 mt-2">
                    <h2>
                      Quantity
                    </h2>
                    <h3 className={`nc-ProductCard__title text-base font-semibold transition-colors hover:text-secondary-900`}>{item.quantity}</h3>
                  </div>
                  <div className="flex items-center gap-4 mt-2">
                    <h2>
                      Price
                    </h2>
                    <h3 className={`nc-ProductCard__title text-base font-semibold transition-colors hover:text-secondary-900`}>₹ {item.row.price}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showEnqyuiryListModal && <ListFollowUpModal setShowEnqyuiryListModal={setShowEnqyuiryListModal} inqueryData={inqueryData} />
      }

    </>

  );
};

export default withRouter(View);
