import React from "react";
import NavigationItem from "./NavigationItem";
//import { NAVIGATION_DEMO_2 } from "data/navigation";
import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

function Navigation() {
  let listData:any = localStorage.getItem("categoryData")
  let transformedData:any = [];
  const categoryData = JSON.parse(listData);

  if (categoryData != null) {
    const mainCategory = categoryData.filter((item: any) => item.subCategory.length>0);
    transformedData = mainCategory.map((item: any, index: number) => {
      // Start with basic structure
      let transformedItem = {
        id: ncNanoId(),
        href:"/"+item.slug,
        name: item.name,
        type: "megaMenu"
      }as {
        id: string;
        href: string;
        name: any;
        type:string;
        children?: any[]; // Optional children property
      };

      // Add additional properties conditionally
      if (item.subCategory) {
        transformedItem["children"] = item.subCategory.map((subItem: any) => ({
          id: ncNanoId(),
          href: "/"+item.slug+"/"+subItem.slug,
          name: subItem.name,
        }));
      }
      return transformedItem;
    });
  }

  const MEAIN_TEMPLATES: NavItemType[] = transformedData;
  const NAVIGATION_DEMO_2: NavItemType[] = [
    {
      id: ncNanoId(),
      href: "/",
      name: "Home",
    },
    {
      id: ncNanoId(),
      href: "/about",
      name: "About Us",
    },
    {
      id: ncNanoId(),
      href: "/category",
      name: "Products",
      type: "dropdown",
      children: MEAIN_TEMPLATES,
    },
    {
      id: ncNanoId(),
      href: "/contact",
      name: "Contact Us",
    }
  ];
  return (
    <ul className="nc-Navigation flex items-center">
      {NAVIGATION_DEMO_2.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
