import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";


let listData:any = localStorage.getItem("categoryData")
let transformedData:any = [];
const categoryData = JSON.parse(listData);
if(categoryData!=null){
  transformedData = categoryData.map((item:any, index: number) => ({
    id: ncNanoId(),
    href:"/category/"+item.slug,
    name: item.name,
  }));
}

export const MEGAMENU_TEMPLATES: NavItemType[] = transformedData;
export const MEAIN_TEMPLATES: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/products",
    name: "Mould Accessories",
    type: "dropdown",
    children: MEGAMENU_TEMPLATES,
  },
  {
    id: ncNanoId(),
    href: "/press-tool",
    name: "Press Tool Acccessories",
  }
];

export const NAVIGATION_DEMO_2: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "About Us",
  },
  {
    id: ncNanoId(),
    href: "/category",
    name: "Products",
    type: "dropdown",
    children: MEAIN_TEMPLATES,
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact Us",
  }
];
