import { Dialog, Transition } from "@headlessui/react";
import React, { useState, useEffect, FC, Fragment } from "react";
import { useLocation } from "react-router-dom";
import SkewLoader from "react-spinners/SkewLoader";

const LoaderSpinner = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const [color, setColor] = useState("#2a3491");

  return (
    <div>
      {loading && (
        <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
          <div className="absolute z-50">
            <SkewLoader color={color} loading={loading} />
          </div>
        </div>
      )}
    </div>
  );
};

export default LoaderSpinner;
